<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="操作状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
        <el-button type="primary" @click="donwLoadXmls" :loading="donwLoadXmlsLoading" size="small">下载XML</el-button>
        <el-upload
          class="upload-demo"
          :headers="upLoadXmlsheaders"
          :show-file-list="showFileList"
          multiple="true"
          name="files[]"
          :on-success="onSuccessUpLoadXmls"
          :on-error="onErrorUpLoadXmls"
          :before-upload="beforeUpload"
          :disabled="upLoadXmlsBtndisabled"
          :action="upLoadXmlsUrl">
          <el-button size="small" type="primary">上传XML</el-button>
        </el-upload>
      </div>
    </div>
    <div class="tableData">
      <el-table border stripe ref="multipleTableRef" :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column type="selection" :selectable="selectable" width="35" />
        <el-table-column prop="id" label="ID" width="50" align="left"/>
        <el-table-column prop="title" label="抬头" align="left" width="320" show-overflow-tooltip />
        <el-table-column prop="tax" label="税号" align="left" width="180" show-overflow-tooltip />
        <el-table-column prop="amount" label="金额" width="80"/>
        <el-table-column prop="invoice_no" label="发票号码" width="100"/>
        <el-table-column prop="user_id" label="用户ID" width="70"/>
        <el-table-column prop="client" label="来源" width="80"/>
        <el-table-column prop="quantity" label="商品数" width="80"/>
        <el-table-column prop="email" label="邮箱" show-overflow-tooltip/>
        <el-table-column show-overflow-tooltip width="100" prop="created_date" label="创建日期"/>
        <el-table-column prop="tax_status" label="税务状态" width="100"/>
        <el-table-column prop="status_txt" label="操作状态" width="100">
          <template #default="scope">
            <span v-if="scope.row.status=='created'" class="f_color_blue">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='submitted'" class="f_color_red">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='approved'" class="f_color_orange">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='issued'" class="f_color_green">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='sent'" class="f_color_violet">{{scope.row.status_txt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" v-if="scope.row.status==='submitted'" @click="tableEdit(scope.row)" plain>取消</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogFormVisible" title="取消备注">
      <el-form>
        <el-form-item label="备注" label-width="200">
          <el-input v-model="marks" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogCacelBtnLoading" @click="saveItemCancel">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{detailData.id}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{detailData.user_id}}</el-descriptions-item>

        <el-descriptions-item label="抬头">{{detailData.title}}</el-descriptions-item>
        <el-descriptions-item label="手机号">{{detailData.phone}}</el-descriptions-item>

        <el-descriptions-item label="购买方税号">{{detailData.tax}}</el-descriptions-item>
        <el-descriptions-item label="邮箱">{{detailData.email}}</el-descriptions-item>

        <el-descriptions-item label="发票号码">{{detailData.invoice_no}}</el-descriptions-item>
        <el-descriptions-item label="税务状态">{{detailData.tax_status}}</el-descriptions-item>

        <el-descriptions-item label="发票代码">{{detailData.invoice_code}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{detailData.addr_phone}}</el-descriptions-item>

        <el-descriptions-item label="支付ID">{{detailData.payment_id}}</el-descriptions-item>
        <el-descriptions-item label="公司电话">{{detailData.telephone}}</el-descriptions-item>

        <el-descriptions-item label="发票类型">{{detailData.type}}</el-descriptions-item>
        <el-descriptions-item label="地址">{{detailData.address}}</el-descriptions-item>

        <el-descriptions-item label="商品数">{{detailData.quantity}}</el-descriptions-item>
        <el-descriptions-item label="银行名">{{detailData.bank_name}}</el-descriptions-item>

        <el-descriptions-item label="金额">{{detailData.amount}}</el-descriptions-item>
        <el-descriptions-item label="操作状态">{{detailData.status_txt}}</el-descriptions-item>
        <el-descriptions-item label="来源">{{detailData.client}}</el-descriptions-item>
        <el-descriptions-item label="银行账号">{{detailData.bank_account}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{detailData.created_time}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{detailData.marks}}</el-descriptions-item>
        <el-descriptions-item label="负数发票表ID">{{detailData.minus_invoice_id}}</el-descriptions-item>
        <el-descriptions-item label="发出时间">{{detailData.sent_time}}</el-descriptions-item>
        <el-descriptions-item label="流水号">{{detailData.serial_no}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import FileSaver from 'file-saver'
import iconv from 'iconv-lite';
export default {
  name: 'InvoiceView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'created',
          label: '已创建',
        },
        {
          value: 'submitted',
          label: '已提交',
        },
        {
          value: 'approved',
          label: '已开具',
        },
        {
          value: 'sent',
          label: '已发送',
        },
        {
          value: 'cancelled',
          label: '已取消',
        }
      ],
      conditionTypes: [
        {
          value: 'invoice_no',
          label: '发票号码',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'phone',
          label: '手机号',
        },
        {
          value: 'payment_id',
          label: '支付ID',
        }
      ],
      filterData: {status: '', conditionType: 'invoice_no', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      donwLoadXmlsLoading: false,
      previewVideoFile: '',
      upLoadXmlsUrl: `/api/${this.$apis.Invoice.uploadXmls}`,
      upLoadXmlsheaders: {Authorization: `Bearer ${window.localStorage.getItem('token')}`},
      multipleSelectionValue: [],
      upLoadXmlsBtndisabled: false,
      showFileList: false,
      total: 0,
      dialogFormVisible: false,
      marks: '',
      dialogCacelBtnLoading: false,
      dialogDetailVisible: false,
      detailData: {
        id: ''
      },
    }
  },
  methods: {
    list() {
      const url = this.$apis.Invoice.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'invoice_no', conditionValue: ''}
    },
    handleSelectionChange(val) {
      this.multipleSelectionValue = val
    },
    getSelectionIds() {
      return this.multipleSelectionValue.map((v) => v.id)
    },
    // 下载
    donwLoadXmls() {
      const _this = this
      const url = this.$apis.Invoice.getXmls
      const ids = _this.getSelectionIds()
      if (ids.length === 0) {
        return _this.$message.error('请选择要下载的行')
      }
      _this.donwLoadXmlsLoading = true
      let params = { ids: ids.join() }
      this.$httpPost(url, params).then(resp => {
        for (const key of Object.keys(resp)) {
          const fileBuffer = iconv.encode(resp[key].replace('encoding="UTF-8"', 'encoding="GBK"'), 'gbk')
          const blob = new Blob([fileBuffer], {type: "text/xml;charset=GBK"})
          FileSaver.saveAs(blob, `${key}.xml`)
        }
        _this.donwLoadXmlsLoading = false
        this.$refs.multipleTableRef.clearSelection()
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '下载失败~')
        _this.donwLoadXmlsLoading = false
      })
    },
    onSuccessUpLoadXmls(res) {
      this.upLoadXmlsBtndisabled = false
      this.$hideLoading()
      const {data, ret, msg} = res
      if (ret === 0) {
        const {fail, success, sqlState} = data
        this.$message({
          showClose: true,
          message: `
          成功数: ${success.length}, 失败数: ${fail.length};
          ${success.length ? 'success: ' + success.join() + ';' : ''}
          ${fail.length ? 'fail: ' + fail.join() + ';' : ''}
          保存情况: ${JSON.stringify(sqlState)}`,
          type: 'warning',
          duration: 0
        })
        this.list()
      } else {
        this.$message({
          showClose: true,
          message: msg,
          type: 'error',
          duration: 0
        })
      }
    },
    onErrorUpLoadXmls(err) {
      this.$message({
        showClose: true,
        message: JSON.stringify(err),
        type: 'error',
        duration: 0
      })
    },
    tableEdit(row) {
      this.marks = ''
      this.tableEditId = row.id
      this.dialogFormVisible = true
    },
    tableDetail(row) {
      const url = this.$apis.Invoice.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.detailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    saveItemCancel() {
      const url = this.$apis.Invoice.cancel
      let params = { id: this.tableEditId }
      const {marks} = this
      if (! marks) {
        this.$message.error('请输入备注')
      }
      Object.assign(params, {marks})
      this.dialogCacelBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogFormVisible = false
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '取消发票失败~')
      }).finally(() => {
        this.dialogCacelBtnLoading = false
      })
    },
    selectable(row) {
      return row.status === 'submitted'
    },
    beforeUpload() {
      this.upLoadXmlsBtndisabled = true
      this.$showLoading()
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>