<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option label="全部" value="" />
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="横竖屏">
            <el-select v-model="filterData.orientation" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in orientationTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="90px"/>
        <el-table-column prop="name" label="名称" width="190px"/>
        <el-table-column prop="userId" label="用户ID" width="120px"/>
        <el-table-column prop="count" label="子窗口数" width="90px"/>
        <el-table-column prop="parentId" label="继承ID" width="90px"/>
        <el-table-column prop="orientationTxt" label="横竖屏" width="70px">
          <template #default="scope">
            <span v-if="scope.row.orientation=='landscape'" class="f_color_green">{{scope.row.orientationTxt}}</span>
            <span v-else-if="scope.row.orientation=='portrait'" class="f_color_orange">{{scope.row.orientationTxt}}</span>
            <span v-else class="f_color_blue">{{scope.row.orientationTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceCount" label="设备数" width="70px">
          <template #default="scope">
            <el-button size="small" type="warning" :loading="!!scope.row.tableShowDevicesBtnLoading" @click="tableShowDevices(scope.row)" v-if="scope.row.deviceCount!=0" class="f_color_white">{{scope.row.deviceCount}}</el-button>
            <span v-else>{{scope.row.deviceCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="statusTxt" label="状态" width="60px">
          <template #default="scope">
            <span v-if="scope.row.status=='deleted'" class="f_color_red">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='disabled'" class="f_color_blue">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" label="创建时间" width="160px"/>
        <el-table-column prop="updateTime" label="修改时间" width="160px"/>
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>查看</el-button>
            <el-button size="small" type="primary" @click="tableEdit(scope.row)" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" :title="dialogDetailTitle">
      <el-form label-width="10px">
        <el-form-item label="">
          <el-input v-model="dialogDetailData" readonly="true" rows="20" type="textarea" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="400px">
      <el-form :model="dialogEditData" ref="ruleFormEdit" :rules="editRules" label-position="right" label-width="120px">
        <el-form-item label="状态">
          <el-select v-model="dialogEditDataStatus" class="m-2" placeholder="Select" size="small">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave()">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogShowDevicesVisible" :title="dialogShowDevicesTitle" width="700px">
      <el-table ref="multipleTableRef" border stripe :data="tableDevicesData" style="width: 100%" empty-text="暂无数据~" v-loading="tableShowDevicesLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="60px"/>
        <el-table-column prop="name" label="名称" show-overflow-tooltip/>
        <el-table-column prop="dev_num" label="编号" width="100px"/>
        <el-table-column prop="icon" label="图标" width="100px">
          <template #default="scope">
            <img :src="scope.row.icon" width="60" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="90px">
          <template #default="scope">
            <span v-if="scope.row.status=='downloading'" class="f_color_orange">{{scope.row.status}}</span>
            <span v-else-if="scope.row.status=='downloaded'" class="f_color_green">{{scope.row.status}}</span>
            <span v-else-if="scope.row.status=='离线'" class="f_color_red">{{scope.row.status}}</span>
            <span v-else class="f_color_darkgray">--</span>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogShowDevicesVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'UserwindowsView',
  data: function () {
    return {
      statusOptions: [
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'disabled',
          label: '禁用',
        },
        {
          value: 'deleted',
          label: '删除',
        },
        {
          value: 'abandoned',
          label: '遗弃',
        }
      ],
      orientationTypes: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'portrait',
          label: '竖屏',
        },
        {
          value: 'landscape',
          label: '横屏',
        }
      ],
      conditionTypes: [
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'parent_platform_windows_id',
          label: '继承ID',
        },
        {
          value: 'name',
          label: '名称',
        }
      ],
      filterData: {status: '', orientation: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailTitle: '',
      dialogDetailData: {
        id: '0'
      },
      dialogEditVisible: false,
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogEditData: {},
      dialogEditDataStatus: '',
      dialogShowDevicesVisible: false,
      dialogShowDevicesTitle: '',
      tableDevicesData: [],
    }
  },
  methods: {
    list() {
      const url = this.$apis.Userwindow.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', orientation: '', conditionType: 'name', conditionValue: ''}
    },
    tableDetail(row) {
      const url = this.$apis.Userwindow.detail
      const params = {id: row.id}
      this.dialogDetailTitle = `详情[${row.id}]`
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = JSON.stringify(res, null, 4)
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableEdit(row) {
      this.dialogEditTitle = `编辑ID:${row.id}`
      this.dialogEditData = row
      this.dialogEditVisible = true
      this.dialogEditDataStatus = row.status
    },
    actionEditSave() {
      const url = this.$apis.Userwindow.edit
      const {id} = this.dialogEditData
      this.dialogEditSubmitBtnLoading = true
      this.$httpPost(url, {id, status: this.dialogEditDataStatus}).then(() => {
        this.list()
        this.$message.success('操作成功')
        this.dialogEditVisible = false
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditSubmitBtnLoading = false
      })
    },
    tableShowDevices(row) {
      const url = this.$apis.Userwindow.getDevices
      const {id} = row
      row.tableShowDevicesBtnLoading = true
      this.dialogShowDevicesTitle = `设备列表[${id}]`
      this.$httpPost(url, {id}).then((res) => {
        this.tableDevicesData = res
        this.dialogShowDevicesVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableShowDevicesBtnLoading = false
      })
    },
  },
  created() {
    this.list()
  }
}
</script>