import axios from 'axios'
import router from "@/router"

axios.defaults.baseURL = '/api/'

axios.interceptors.request.use(function (config) {
    // config.headers.Content
    if (window.localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${window.localStorage.getItem('token')}`
    }
    return config
})


// 添加响应拦截器s
axios.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const {ret, msg} = response.data;
    if (ret !== 0) {
        if (ret === 401) {
            alert('登录已过期')
            router.push('/login')
        }
        return Promise.reject({ret, msg})
    }
    return Promise.resolve(response.data)
}, function (error) {
    return Promise.reject(error)
})


function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, {params: params})
        .then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    })
}

function post(url, data = {}, config = {}) {
    return new Promise(((resolve, reject) => {
        axios.post(url, data, config).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    }))
}

function patch(url, data = {}, config = {}) {
    return new Promise(((resolve, reject) => {
        axios.patch(url, data, config).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    }))
}

function del(url, params = {},) {
    return new Promise(((resolve, reject) => {
        axios.delete(url, {params: params}).then(response => {
            resolve(response.data)
        }).catch(error => {
            reject(error)
        })
    }))
}

export default {
    get,
    post,
    patch,
    del,
}