<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
        <el-button type="primary" @click="toolsAdd" size="small">新增</el-button>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="80px"/>
        <el-table-column prop="account" label="账号" width="120px"/>
        <el-table-column prop="phone" label="手机号" width="120px"/>
        <el-table-column prop="nickName" label="昵称" width="120px"/>
        <el-table-column prop="fanId" label="fanID" width="120px"/>
        <el-table-column prop="statusTxt" label="状态" width="60px">
          <template #default="scope">
            <span v-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="170">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableEdit(scope.row)" plain>编辑</el-button>
            <el-popconfirm v-if="scope.row.status=='normal'" title="确定禁用该用户吗？" @confirm="tableDisabled(scope.row)">
              <template #reference>
                <el-button size="small" type="warning" :loading="scope.row.tableDisabledBtnLoading" plain>禁用</el-button>
              </template>
            </el-popconfirm>
            
            <el-button size="small" type="warning" v-else-if="scope.row.status=='disabled'" :loading="scope.row.tableEnabledBtnLoading" @click="tableEnabled(scope.row)" plain>解禁</el-button>
            <el-button size="small" type="primary" @click="tableActions(scope.row)" plain>更多..</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogAddVisible" title="新增(初始密码为：123456)" width="400px">
      <el-form :model="dialogAddData" ref="ruleFormAdd" :rules="addRules" label-position="right" label-width="120px">
        <el-form-item label="账号" prop="account">
          <el-input v-model="dialogAddData.account" autocomplete="off" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="dialogAddData.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="dialogAddData.nickName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="fanID" prop="fanId">
          <el-input v-model="dialogAddData.fanId" autocomplete="off" type="number" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAddVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogAddSubmitBtnLoading" @click="handleAddSave('ruleFormAdd')">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="400px">
      <el-form :model="dialogEditData" ref="ruleFormEdit" :rules="addRules" label-position="right" label-width="120px">
        <el-form-item label="账号" prop="account">
          <el-input v-model="dialogEditData.account" autocomplete="off" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="dialogEditData.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="dialogEditData.nickName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="fanID" prop="fanId">
          <el-input v-model="dialogEditData.fanId" autocomplete="off" type="number" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="handleEditSave('ruleFormEdit')">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogActionsVisible" :title="dialogAtionsTitle" width="300px">
      <el-form-item label="操作类型">
        <el-select v-model="actionData.type" class="m-2" placeholder="Select" size="small">
          <el-option
            v-for="item in actionsTypes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogActionsVisible = false">关闭</el-button>
          <el-popconfirm v-if="actionData.type==3" title="确定重置为初始密码吗？" @confirm="handleActionSave">
            <template #reference>
              <el-button type="primary" :loading="dialogActionSubmitBtnLoading">提交</el-button>
            </template>
          </el-popconfirm>
          <el-popconfirm v-else-if="actionData.type==1" title="确定删除吗？" @confirm="handleActionSave">
            <template #reference>
              <el-button type="primary" :loading="dialogActionSubmitBtnLoading">提交</el-button>
            </template>
          </el-popconfirm>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'ManagerView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'disabled',
          label: '禁用',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'phone',
          label: '手机号',
        },
        {
          value: 'account',
          label: '账号',
        },
        {
          value: 'fan_id',
          label: 'fanID',
        },
        {
          value: 'nick_name',
          label: '昵称',
        }
      ],
      actionsTypes: [
        {
          value: '1',
          label: '删除',
        },
        {
          value: '3',
          label: '重置密码',
        }
      ],
      filterData: {status: '', conditionType: 'id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogAddVisible: false,
      dialogAddSubmitBtnLoading: false,
      dialogAddData: {},
      dialogAddDataDefault: {
        account: '', phone: '', fanId: '0', nickName: '',
      },
      addRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^[1-9]\d{10}$/, message: '手机号有误', trigger: 'blur' }
        ],
        nickName: [
          { required: true, message: '请输入昵称', trigger: 'blur' }
        ],
        fanId: [
          { pattern: /^\d*$/, message: 'fanID有误', trigger: 'blur' }
        ]
      },
      dialogEditVisible: false,
      dialogEditId: 1,
      dialogEditTitle: '',
      dialogEditData: {},
      dialogEditSubmitBtnLoading: false,
      dialogAtionsTitle: '',
      actionData: {type: '1'},
      dialogActionsVisible: false,
      dialogActionSubmitBtnLoading: false,
    }
  },
  methods: {
    list() {
      const url = this.$apis.Manager.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'id', conditionValue: ''}
    },
    toolsAdd() {
      this.dialogAddData = Object.assign({}, this.dialogAddDataDefault)
      this.dialogAddVisible = true
    },
    handleAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Manager.add
          const {dialogAddData} = this
          this.dialogAddSubmitBtnLoading = true
          this.$httpPost(url, dialogAddData).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogAddVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogAddSubmitBtnLoading = false
          })
        }
      })
    },
    tableEdit(row) {
      this.dialogEditId = row.id
      this.dialogEditTitle = `编辑ID:${row.id}`
      Object.assign(this.dialogEditData, row)
      this.dialogEditVisible = true
    },
    handleEditSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Manager.edit
          let params = { id: this.dialogEditId }
          const {dialogEditData} = this
          Object.assign(params, dialogEditData)
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, params).then(() => {
            this.$message.success('操作成功')
            this.dialogEditVisible = false
            this.list()
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    tableDisabled(row) {
      const url = this.$apis.Manager.setStatus
      const params = { id: row.id, status: 'disabled' }
      row.tableDisabledBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableDisabledBtnLoading = false
      })
    },
    tableEnabled(row) {
      const url = this.$apis.Manager.setStatus
      const params = { id: row.id, status: 'normal' }
      row.tableEnabledBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableEnabledBtnLoading = false
      })
    },
    tableActions(row) {
      this.dialogEditId = row.id
      this.dialogAtionsTitle = `操作ID:${row.id}`
      this.dialogActionsVisible = true
    },
    handleActionSave() {
      const {type} = this.actionData
      const params = { id: this.dialogEditId }
      let url = ''
      if (type == 3) { // 重置密码
        url = this.$apis.Manager.resetPassword
      } else if (type == 1) { // 删除
        url = this.$apis.Manager.setStatus
        Object.assign(params, {status: 'deleted'})
      }
      this.dialogActionSubmitBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogActionsVisible = false
        if (type == 1) {this.list()}
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogActionSubmitBtnLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>