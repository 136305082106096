<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option label="全部" value="" />
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="90px"/>
        <el-table-column prop="user_id" label="用户ID" width="120px"/>
        <el-table-column prop="phone" label="手机号" width="120px"/>
        <el-table-column prop="addDate" label="提交日期" width="120px"/>
        <el-table-column prop="platform" label="客户端" width="120px"/>
        <el-table-column prop="words" label="反馈内容" show-overflow-tooltip/>
        <el-table-column prop="notes" label="备注" show-overflow-tooltip/>
        <el-table-column prop="statusTxt" label="状态" width="80px">
          <template #default="scope">
            <span v-if="scope.row.status=='removed'" class="f_color_red">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='posted'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>查看</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" :title="dialogDetailTitle">
      <el-form label-width="120px">
        <el-form-item label="用户ID">{{dialogDetailData.user_id}}</el-form-item>
        <el-form-item label="用户手机号">{{dialogDetailData.phone}}</el-form-item>
        <el-form-item label="反馈内容">{{dialogDetailData.words}}</el-form-item>
        <el-form-item label="备注">{{dialogDetailData.notes}}</el-form-item>
        <el-form-item label="提交时间">{{dialogDetailData.add_time}}</el-form-item>
        <el-form-item label="图片地址">{{dialogDetailData.picture_url}}</el-form-item>
        <el-form-item label="语音地址">{{dialogDetailData.voice_url}}</el-form-item>
        <el-form-item label="客户端">{{dialogDetailData.platform}}</el-form-item>
        <el-form-item label="OEM">{{dialogDetailData.oem}}</el-form-item>
        <el-form-item label="状态">
          <el-select v-model="dialogDetailData.status" class="m-2" disabled placeholder="Select" size="small">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="600px">
      <el-form label-width="60px">
        <el-form-item label="备注">
          <el-input v-model="dialogEditData.notes" autocomplete="off" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="dialogEditData.status" class="m-2" placeholder="Select" size="small">
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" type="primary" :loading="dialogEditSaveBtnLoading" @click="handleSave">提交</el-button>
          <el-button size="small" @click="dialogEditVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'FeedbackView',
  data: function () {
    return {
      statusOptions: [
        {
          value: 'done',
          label: '已处理',
        },
        {
          value: 'posted',
          label: '未处理',
        },
        {
          value: 'removed',
          label: '已删除',
        },
      ],
      filterData: {status: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogEditSaveBtnLoading: false,
      dialogDetailTitle: '',
      dialogDetailData: {
        id: '0'
      },
      dialogEditId: 0,
      dialogEditVisible: false,
      dialogEditTitle: '',
      dialogEditData: {},
    }
  },
  methods: {
    list() {
      const url = this.$apis.Feedback.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    tableEdit(row) {
      const {notes, id, status} = row
      this.dialogEditId = id
      this.dialogEditVisible = true
      this.dialogEditTitle = `编辑[${id}]`
      this.dialogEditData = {notes, status}
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: ''}
    },
    tableDetail(row) {
      this.dialogDetailTitle = `详情[${row.id}]`
      this.dialogDetailData = {...row}
      this.dialogDetailVisible = true
    },
    handleSave() {
      const url = this.$apis.Feedback.edit
      const {status, notes} = this.dialogEditData
      const params = {id: this.dialogEditId, status, notes}
      this.dialogEditSaveBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('保存成功~')
        this.dialogEditVisible = false
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.dialogEditSaveBtnLoading = false
      })
    }
  },
  created() {
    this.list()
  }
}
</script>