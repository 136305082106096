<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="ID" label="设备ID" width="80px"/>
        <el-table-column prop="Name" label="名称" show-overflow-tooltip/>
        <el-table-column prop="admins" label="管理员数" width="100px">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableAdminsList(scope.row)" class="f_color_white">{{scope.row.admins}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="appVers" label="版本" width="80px"/>
        <el-table-column prop="mediaCount" label="媒体数" width="80px">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableDeviceMediaDetail(scope.row)" v-if="scope.row.mediaCount!=0" class="f_color_white">{{scope.row.mediaCount}}</el-button>
            <span v-else>{{scope.row.mediaCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="manufacturer" label="生产商" width="120px"/>
        <el-table-column prop="storageSize" label="存储容量(MB)" width="120px"/>
        <el-table-column prop="initDate" label="启用日期" width="100px"/>
        <el-table-column prop="userDate" label="激活日期" width="100px"/>
        <el-table-column prop="endDate" label="服务期" width="100px"/>
        <el-table-column prop="lastReportedTime" label="最后报告时间" width="110px"/>
        <el-table-column prop="playlistMediaCount" label="view" width="60px">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableDevicePlayListMedia(scope.row, 'view')" v-if="scope.row.playlistMediaCount!=0" class="f_color_white">{{scope.row.playlistMediaCount}}</el-button>
            <span v-else>{{scope.row.playlistMediaCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="playlistAudioCount" label="audio" width="70px">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableDevicePlayListMedia(scope.row, 'audio')" v-if="scope.row.playlistAudioCount!=0" class="f_color_white">{{scope.row.playlistAudioCount}}</el-button>
            <span v-else>{{scope.row.playlistAudioCount}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deviceStatusTxt" label="状态" width="60px">
          <template #default="scope">
            <span v-if="scope.row.deviceStatus=='online'" class="f_color_green">{{scope.row.deviceStatusTxt}}</span>
            <span v-else-if="scope.row.deviceStatus=='offline'" class="f_color_red">{{scope.row.deviceStatusTxt}}</span>
            <span v-else-if="scope.row.deviceStatus=='busy'" class="f_color_orange">{{scope.row.deviceStatusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.deviceStatusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="120">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>配置</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="950px">
      <el-tabs v-model="detailEditTabs" @tab-change="handleEditTabChange">
        <el-tab-pane label="设备配置" name="tabs1">
          <el-form label-width="200px">
            <el-form-item label="角度">
              <el-input v-model="dialogEditData.Angle" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Sock服务">
              <el-input v-model="dialogEditData.SockService" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="Picture停留时间">
              <el-input v-model="dialogEditData.PictureTime" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="HTML停留时间">
              <el-input v-model="dialogEditData.HTMLTime" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="视频强制全屏">
              <el-input v-model="dialogEditData.VideoStretch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="图片强制全屏">
              <el-input v-model="dialogEditData.PicStretch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Picture转场效果">
              <el-input v-model="dialogEditData.Effect" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="Mp3模式">
              <el-input v-model="dialogEditData.AudioMode" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="音频默认间隔时间">
              <el-input v-model="dialogEditData.AudioInterval" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="开机自启动">
              <el-input v-model="dialogEditData.AutoStartup" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="隐藏导航栏">
              <el-input v-model="dialogEditData.HideNav" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="WebView的字体大小">
              <el-input v-model="dialogEditData.FontSize" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="WebView的字体缩放">
              <el-select v-model="dialogEditData.TextSize" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in dialogEditTextSizeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
              </el-select>
            </el-form-item>
            <el-form-item label="竖屏软件实现">
              <el-input v-model="dialogEditData.FakePortrait" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="自动清理存储">
              <el-input v-model="dialogEditData.AutoCleanUp" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="启动后延迟(ms)">
              <el-input v-model="dialogEditData.BootDelay" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="空列表时显示媒体ID">
              <el-input v-model="dialogEditData.DefaultMediaID" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="设备下载进度报告">
              <el-input v-model="dialogEditData.DlProgressRpt" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="强制判断是否前台运行">
              <el-input v-model="dialogEditData.ForceFore" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="媒体生命周期">
              <el-input v-model="dialogEditData.LifeTimeEn" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="不允许退出">
              <el-input v-model="dialogEditData.NoExit" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="NTP开启">
              <el-input v-model="dialogEditData.NtpEn" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="重置安全级别">
              <el-select v-model="dialogEditData.ResetLevel" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditResetLevelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="媒体播放时段">
              <el-input v-model="dialogEditData.SchedulePlayEn" type="number" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="允许静默安装">
              <el-input v-model="dialogEditData.SilentInstall" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="TV强制全屏">
              <el-input v-model="dialogEditData.TVStretch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="VPlayer">
              <el-input v-model="dialogEditData.VPlayer" type="number" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="仅下载转码后的音视频">
              <el-input v-model="dialogEditData.mp4_only" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="不支持WebApp控制">
              <el-input v-model="dialogEditData.no_webapp" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="只下载原始文件">
              <el-input v-model="dialogEditData.origin_file_only" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-row class="mb-4 dialogEditBtns">
              <el-button size="small" type="primary" :loading="dialogEditSubmitBtnLoading" @click="handleEditConfigSave">提交</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSubmitAndCloseBtnLoading" @click="handleEditConfigSaveAndClose">提交并关闭</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="触屏配置" name="tabs2">
          <el-form label-width="200px">
            <el-form-item label="触屏">
              <el-input v-model="dialogEditTouchData.TouchEnable" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="视频播放控制">
              <el-input v-model="dialogEditTouchData.MediaControl" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="滑动切换节目">
              <el-input v-model="dialogEditTouchData.FingerSwitch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="切换后停留时间">
              <el-input v-model="dialogEditTouchData.FingerTime" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="轻触跳转值机模式">
              <el-input v-model="dialogEditTouchData.DutyMode" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="值机媒体ID">
              <el-input v-model="dialogEditTouchData.DutyMedia" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="无操作等待时间(ms)">
              <el-input v-model="dialogEditTouchData.DutyTime" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-row class="mb-4 dialogEditBtns">
              <el-button size="small" type="primary" :loading="dialogEditTouchSubmitBtnLoading" @click="handleEditTouchConfigSave">提交</el-button>
              <el-button size="small" type="primary" :loading="dialogEditTouchSubmitAndCloseBtnLoading" @click="handleEditTouchConfigSaveAndClose">提交并关闭</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="分屏配置" name="tabs3">
          <el-form label-width="200px">
            <el-form-item label="播放监控">
              <el-input v-model="dialogEditWindowsData.PlayWatcher" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏模式">
              <el-input v-model="dialogEditWindowsData.WindowsMode" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏:图片时间">
              <el-input v-model="dialogEditWindowsData.WinPicTime" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏:图片转场">
              <el-input v-model="dialogEditWindowsData.WinPicEffect" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏:图片拉伸全屏">
              <el-input v-model="dialogEditWindowsData.WinPicStretch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏:视频拉伸全屏">
              <el-input v-model="dialogEditWindowsData.WinVideoStretch" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏模板来源">
              <el-select v-model="dialogEditWindowsData.WinTemplateFrom" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditWinTemplateFromOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="官方分屏配置ID">
              <el-input v-model="dialogEditWindowsData.WinJulyfireCfgID" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户分屏配置ID">
              <el-input v-model="dialogEditWindowsData.WinUserCfgID" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="分屏设置锁定">
              <el-input v-model="dialogEditWindowsData.WinLocked" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-row class="mb-4 dialogEditBtns">
              <el-button size="small" type="primary" :loading="dialogEditWindowsSubmitBtnLoading" @click="handleEditWindowsConfigSave">提交</el-button>
              <el-button size="small" type="primary" :loading="dialogEditWindowsSubmitAndCloseBtnLoading" @click="handleEditWindowsConfigSaveAndClose">提交并关闭</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="字幕配置" name="tabs4">
          <el-form label-width="200px">
            <el-form-item label="字幕连播开关">
              <el-input v-model="dialogEditSubtitleData.SubtitleMode" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="字幕的默认速度">
              <el-input v-model="dialogEditSubtitleData.SbtSpeed" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="字幕的默认显示位置">
              <el-select v-model="dialogEditSubtitleData.SbtLocation" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditSbtLocationOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="字幕的默认字体大小">
              <el-input v-model="dialogEditSubtitleData.SbtSize" type="number" min="0" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="IconEn">
              <el-input v-model="dialogEditSubtitleData.SbtIconEn" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="IconID">
              <el-input v-model="dialogEditSubtitleData.SbtIconID" autocomplete="off" />
            </el-form-item>
            <el-form-item label="IconGap">
              <el-input v-model="dialogEditSubtitleData.SbtGap" autocomplete="off" />
            </el-form-item>
            <el-form-item label="背景可用">
              <el-input v-model="dialogEditSubtitleData.SbtBgEn" type="number" min="0" max="1" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="背景颜色">
              <el-input v-model="dialogEditSubtitleData.SbtBgColor" type="number" step="1" autocomplete="off" />
            </el-form-item>
            <el-form-item label="背景Alpha">
              <el-input v-model="dialogEditSubtitleData.SbtBgAlpha" type="number" step="1" autocomplete="off" />
            </el-form-item>
            <el-row class="mb-4 dialogEditBtns">
              <el-button size="small" type="primary" :loading="dialogEditSubtitleSubmitBtnLoading" @click="handleEditSubtitleConfigSave">提交</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSubtitleSubmitAndCloseBtnLoading" @click="handleEditSubtitleConfigSaveAndClose">提交并关闭</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="功能操作" name="tabs5">
          <el-form label-width="100px">
            <el-form-item label="Passive命令">
              <el-button size="small" type="primary" :loading="dialogEditScanJobBtnLoading" @click="handleActionScanJob">重新扫描任务</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendReportBtnLoading" @click="handleActionSendReport">发送Report命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendSysInfoBtnLoading" @click="handleActionSendSysInfo">发送SystemInfo命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendNextBtnLoading" @click="handleActionSendNext">发送Next命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendPreviousBtnLoading" @click="handleActionSendPrevious">发送Previous命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendSockInfoBtnLoading" @click="handleActionSendSockInfo">发送SockInfo命令</el-button>
            </el-form-item>
            <el-form-item label="检测命令">
              <el-button size="small" type="primary" :loading="dialogEditOnlineTestBtnLoading" @click="handleActionOnlineTest">在线检测命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendRandomBtnLoading" @click="handleActionSendRandom">发送随机数命令</el-button>
            </el-form-item>
            <el-form-item label="清理命令">
              <el-button size="small" type="primary" :loading="dialogEditCleanPlaylistBtnLoading" @click="handleActionCleanPlaylist">清理播放列表缓存</el-button>
              <el-button size="small" type="primary" :loading="dialogEditCleanDeviceBtnLoading" @click="handleActionCleanDevice">清理设备端无效文件</el-button>
              <el-button size="small" type="primary" :loading="dialogEditAbortDownloadBtnLoading" @click="handleActionAbortDownload">终止所有下载</el-button>
              <el-button size="small" type="primary" :loading="dialogEditUpgradeDeviceBtnLoading" @click="handleActionUpgradeDevice">发送升级命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditSendRestartBtnLoading" @click="handleActionSendRestart">发送APP重启命令</el-button>
              <el-button size="small" type="primary" :loading="dialogEditClearErrorCacheBtnLoading" @click="handleActionClearErrorCache">清理Error缓存命令</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="安全操作" name="tabs6">
          <el-form label-width="200px">
            <el-form-item label="安全码">
              <el-input v-model="dialogEditSafeActionData.verify_code" autocomplete="off" />
            </el-form-item>
            <el-form-item label="原因">
              <el-input v-model="dialogEditSafeActionData.reason" autocomplete="off" />
            </el-form-item>
            <el-form-item label="">
              <el-button size="small" type="primary" :loading="dialogEditRebornBtnLoading" @click="handleActionReborn">设备解绑与重置</el-button>
              <el-button size="small" type="primary" :loading="dialogEditResetBtnLoading" @click="handleActionReset">仅重置设备</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="服务信息" name="tabs7">
          <el-form label-width="200px">
            <el-form-item label="服务有效期">
              <el-input v-model="dialogEditServiceDate.time" autocomplete="off" />
            </el-form-item>
            <el-form-item label="修改原因（必填）">
              <el-input v-model="dialogEditServiceDate.reason" autocomplete="off" />
            </el-form-item>
            <el-form-item label="">
              <el-popconfirm width="260" :title="dialogEditServiceDatePopconfirmTitle" @confirm="handleActionServiceDate()">
                <template #reference>
                  <el-button size="small" type="primary" :loading="dialogEditServiceDateBtnLoading">修改后保存</el-button>
                </template>
              </el-popconfirm>
            </el-form-item>
            <el-form-item label="迁移到的设备ID">
              <el-input v-model="dialogEditTransferServiceData.targetDeviceId" autocomplete="off" />
            </el-form-item>
            <el-form-item label="userID">
              <el-input v-model="dialogEditTransferServiceData.userId" autocomplete="off" />
            </el-form-item>
            <el-form-item label="迁移原因（必填）">
              <el-input v-model="dialogEditTransferServiceData.reason" autocomplete="off" />
            </el-form-item>
            <el-form-item label="">
              <el-popconfirm width="160" title="确认发起迁移吗？" @confirm="handleActionTransferService()">
                <template #reference>
                  <el-button size="small" type="primary" :loading="dialogEditTransferServiceBtnLoading">修改后保存</el-button>
                </template>
              </el-popconfirm>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Debug信息" name="tabs8">
          <el-form>
            <el-form-item>
              <el-button size="small" type="primary" :loading="dialogEditSendDebugBtnLoading" @click="handleActionSendDebug">发送命令</el-button>
              <el-select v-model="dialogEditDebugInfoData.command" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditDebugOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-button size="small" type="primary" :loading="dialogEditGetDebugBtnLoading" @click="handleActionGetDebug">刷新</el-button>
            </el-form-item>
            <el-form-item label="Debug数据" style="margin-bottom: 0;">
              {{dialogEditDebugInfoResData.time}}
            </el-form-item>
            <el-form-item label="">
              <el-input rows="15" v-model="dialogEditDebugInfoResData.json" type="textarea" />
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Redis数据" name="tabs9">
          <el-form>
            <el-form-item>
              <el-select v-model="dialogEditRedisDataType" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditRedisDataOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-button size="small" type="primary" :loading="dialogEditGetRedisDataBtnLoading" @click="handleActionGetRedisData">获取</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-input rows="15" v-model="dialogEditRedisDataRes" type="textarea" />
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogEditVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog class="detailDialog" v-model="dialogDetailVisible" :title="dialogDetailTitle">
      <el-tabs v-model="detailTabs" @tab-change="handleTabChange">
        <el-tab-pane label="设备信息" name="tabs1">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="名称">{{dialogDetailDataTab1.name}}</el-descriptions-item>
            <el-descriptions-item label="编号">{{dialogDetailDataTab1.device_num}}</el-descriptions-item>
            <el-descriptions-item label="AndroidID">{{dialogDetailDataTab1.android_id}}</el-descriptions-item>
            <el-descriptions-item label="APP版本">{{dialogDetailDataTab1.app_version}}</el-descriptions-item>
            <el-descriptions-item label="APP版本Code">{{dialogDetailDataTab1.AppVersCode}}</el-descriptions-item>
            <el-descriptions-item label="OS版本">{{dialogDetailDataTab1.os_release}}</el-descriptions-item>
            <el-descriptions-item label="硬件版本">{{dialogDetailDataTab1.hardware}}</el-descriptions-item>
            <el-descriptions-item label="硬件序列号">{{dialogDetailDataTab1.serial_num}}</el-descriptions-item>
            <el-descriptions-item label="CPU">{{dialogDetailDataTab1.cpu}}</el-descriptions-item>
            <el-descriptions-item label="CPUSerial">{{dialogDetailDataTab1.cpu_serial}}</el-descriptions-item>
            <el-descriptions-item label="网络连接">{{dialogDetailDataTab1.connectivity}}</el-descriptions-item>
            <el-descriptions-item label="设备型号">{{dialogDetailDataTab1.device_type}}</el-descriptions-item>
            <el-descriptions-item label="语言">{{dialogDetailDataTab1.language}}</el-descriptions-item>
            <el-descriptions-item label="MAC">{{dialogDetailDataTab1.mac}}</el-descriptions-item>
            <el-descriptions-item label="OEMCode">{{dialogDetailDataTab1.oem_code}}</el-descriptions-item>
            <el-descriptions-item label="生产商">{{dialogDetailDataTab1.manufacturer}}</el-descriptions-item>
            <el-descriptions-item label="model">{{dialogDetailDataTab1.model}}</el-descriptions-item>
            <el-descriptions-item label="OSVers">{{dialogDetailDataTab1.os_version}}</el-descriptions-item>
            <el-descriptions-item label="分辨率">{{dialogDetailDataTab1.resolution_x+" x "+dialogDetailDataTab1.resolution_y}}</el-descriptions-item>
            <el-descriptions-item label="品牌">{{dialogDetailDataTab1.brand}}</el-descriptions-item>
            <el-descriptions-item label="self_boot">{{dialogDetailDataTab1.self_boot}}</el-descriptions-item>
            <el-descriptions-item label="内存大小">{{dialogDetailDataTab1.ram_size}}</el-descriptions-item>
            <el-descriptions-item label="启用日期">{{dialogDetailDataTab1.init_date}}</el-descriptions-item>
            <el-descriptions-item label="激活日期">{{dialogDetailDataTab1.activated_date}}</el-descriptions-item>
            <el-descriptions-item label="服务期截止">{{dialogDetailDataTab1.service_end_date}}</el-descriptions-item>
            <el-descriptions-item label="试用期截止">{{dialogDetailDataTab1.trial_end_date}}</el-descriptions-item>
            <el-descriptions-item label="用户电话">{{dialogDetailDataTab1.activated_user_phone}}</el-descriptions-item>
            <el-descriptions-item label="临时服务期">{{dialogDetailDataTab1.one_day_service}}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="设备实时详情" name="tabs2">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="状态">{{dialogDetailDataTab2.status}}</el-descriptions-item>
            <el-descriptions-item label="储存剩余">{{dialogDetailDataTab2.storage_left_size}}</el-descriptions-item>
            <el-descriptions-item label="音量">{{dialogDetailDataTab2.volume}}</el-descriptions-item>
            <el-descriptions-item label="亮度">{{dialogDetailDataTab2.brightness}}</el-descriptions-item>
            <el-descriptions-item label="idle">{{dialogDetailDataTab2.idle}}</el-descriptions-item>
            <el-descriptions-item label="standby">{{dialogDetailDataTab2.isstandby}}</el-descriptions-item>
            <el-descriptions-item label="角度">{{dialogDetailDataTab2.rotationAngle}}</el-descriptions-item>
            <el-descriptions-item label="方向">{{dialogDetailDataTab2.orientation}}</el-descriptions-item>
            <el-descriptions-item label="sock版本">{{dialogDetailDataTab2.sockvers}}</el-descriptions-item>
            <el-descriptions-item label="apk版本">{{dialogDetailDataTab2.version}}</el-descriptions-item>
            <el-descriptions-item label="IP">{{dialogDetailDataTab2.ip}}</el-descriptions-item>
            <el-descriptions-item label="subIP">{{dialogDetailDataTab2.sub_ip}}</el-descriptions-item>
            <el-descriptions-item label="OutOfStorage">{{dialogDetailDataTab2.outofstorage}}</el-descriptions-item>
            <el-descriptions-item label="最后开机时间">{{dialogDetailDataTab2.open_time}}</el-descriptions-item>
            <el-descriptions-item label="最后Report时间">{{dialogDetailDataTab2.report_time}}</el-descriptions-item>
            <el-descriptions-item label="最后System时间">{{dialogDetailDataTab2.system_time}}</el-descriptions-item>
            <el-descriptions-item label="最后活动时间">{{dialogDetailDataTab2.active_time}}</el-descriptions-item>
            <el-descriptions-item label="WS活动时间">{{dialogDetailDataTab2.ws_time}}</el-descriptions-item>
            <el-descriptions-item label="dentry日志">{{dialogDetailDataTab2.dentryLog}}</el-descriptions-item>
            <el-descriptions-item label="禁止error上报">{{dialogDetailDataTab2.denyErrorReport}}</el-descriptions-item>
            <el-descriptions-item label="当前错误上报数量">{{dialogDetailDataTab2.errorCount}}</el-descriptions-item>
            <el-descriptions-item label="允许最大分辨率">{{dialogDetailDataTab2.deviceResMode}}</el-descriptions-item>
            <el-descriptions-item label="仅MP4">{{dialogDetailDataTab2.mp4Only}}</el-descriptions-item>
            <el-descriptions-item label="媒体LifeTime">{{dialogDetailDataTab2.lifeTimeEnable}}</el-descriptions-item>
            <el-descriptions-item label="运行模式">{{dialogDetailDataTab2.run_mode}}</el-descriptions-item>
            <el-descriptions-item label="参数simplified">{{dialogDetailDataTab2.simplified}}</el-descriptions-item>
          </el-descriptions>
          <el-row class="mb-4 dialogEditBtns">
            <el-button size="small" type="primary" @click="handleLoadDialogDetailDataTab2">更新</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="任务列表" name="tabs3">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="任务状态">{{dialogDetailDataTab3.status}}</el-descriptions-item>
            <el-descriptions-item label="下载任务数">{{dialogDetailDataTab3.download_jobs}}</el-descriptions-item>
            <el-descriptions-item label="列表任务数">{{dialogDetailDataTab3.playlist_jobs}}</el-descriptions-item>
            <el-descriptions-item label="删除任务数">{{dialogDetailDataTab3.delete_jobs}}</el-descriptions-item>
            <el-descriptions-item label="window任务数">{{dialogDetailDataTab3.winlist_jobs}}</el-descriptions-item>
            <el-descriptions-item label="passive任务">{{dialogDetailDataTab3.passive_jobs}}</el-descriptions-item>
          </el-descriptions>
          <el-row class="mb-4 dialogEditBtns">
            <el-button size="small" type="primary" @click="handleLoadDialogDetailDataTab3">更新</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Runtime配置" name="tabs4">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="运行模式">{{dialogDetailDataTab4.runMode}}</el-descriptions-item>
            <el-descriptions-item label="仅MP4(mp4Only)">{{dialogDetailDataTab4.mp4Only}}</el-descriptions-item>
            <el-descriptions-item label="视频旋转角度(videoRotationAngle)">{{dialogDetailDataTab4.videoRotationAngle}}</el-descriptions-item>
            <el-descriptions-item label="视频最大分辨率(videoDeviceResMode)">{{dialogDetailDataTab4.videoDeviceResMode}}</el-descriptions-item>
            <el-descriptions-item label="图片旋转角度(imageRotationAngle)">{{dialogDetailDataTab4.imageRotationAngle}}</el-descriptions-item>
            <el-descriptions-item label="图片最大分辨率(imageDeviceResMode)">{{dialogDetailDataTab4.imageDeviceResMode}}</el-descriptions-item>
            <el-descriptions-item label="lifeTimeEnable">{{dialogDetailDataTab4.lifeTimeEnable}}</el-descriptions-item>
            <el-descriptions-item label="参数简化(simplified)">{{dialogDetailDataTab4.simplified}}</el-descriptions-item>
            <el-descriptions-item label="记录Log(dentryLogEnable)">{{dialogDetailDataTab4.dentryLogEnable}}</el-descriptions-item>
            <el-descriptions-item label="禁止错误上报(denyErrorReport)">{{dialogDetailDataTab4.denyErrorReport}}</el-descriptions-item>
            <el-descriptions-item label="通信token(token)">{{dialogDetailDataTab4.passiveToken}}</el-descriptions-item>
            <el-descriptions-item label="WS Token(socketToken)">{{dialogDetailDataTab4.socketToken}}</el-descriptions-item>
            <el-descriptions-item label="是否待机(standby)">{{dialogDetailDataTab4.standby}}</el-descriptions-item>
          </el-descriptions>
          <el-row class="mb-4 dialogEditBtns">
            <el-button size="small" type="primary" @click="handleLoadDialogDetailDataTab4">更新</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Storage信息" name="tabs5">
          <el-descriptions v-if="dialogDetailDataTab5IsHide" colon="false" border>
            <el-descriptions-item label="提示" width="200px" label-align="right">{{dialogDetailDataTab5IsHideMsg}}</el-descriptions-item>
          </el-descriptions>
          <div v-for="item in dialogDetailDataTab5" :key="item.name">
            <el-descriptions title="" column=2 colon="true" border>
              <el-descriptions-item label="Name">{{item.name}}</el-descriptions-item>
              <el-descriptions-item label="Removable">{{item.removable}}</el-descriptions-item>
              <el-descriptions-item label="文件格式">{{item.file_system}}</el-descriptions-item>
              <el-descriptions-item label="剩余大小KB">{{item.left}}</el-descriptions-item>
              <el-descriptions-item label="Path">{{item.path}}</el-descriptions-item>
              <el-descriptions-item label="Property">{{item.property}}</el-descriptions-item>
              <el-descriptions-item label="Read/Write">{{item.rw}}</el-descriptions-item>
              <el-descriptions-item label="更新时间">{{item.time}}</el-descriptions-item>
              <el-descriptions-item label="总大小KB">{{item.total}}</el-descriptions-item>
              <el-descriptions-item label="Type">{{item.type}}</el-descriptions-item>
              <el-descriptions-item label="UUID">{{item.uuid}}</el-descriptions-item>
            </el-descriptions>
          </div>
          <el-row class="mb-4 dialogEditBtns">
            <el-button size="small" type="primary" @click="handleLoadDialogDetailDataTab5">更新</el-button>
          </el-row>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDeviceMediaVisible" :title="dialogDeviceMediaTitle" width="900px">
      <el-table ref="multipleTableRef" border stripe :data="tableMediasData" style="width: 100%" empty-text="暂无数据~" v-loading="tableDeviceMediaLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="60px"/>
        <el-table-column prop="media_id" label="媒体ID" width="100px"/>
        <el-table-column prop="file_id" label="文件ID" width="100px"/>
        <el-table-column prop="Title" label="名称" show-overflow-tooltip/>
        <el-table-column prop="mediaType" label="类型" width="90px">
          <template #default="scope">
            <span v-if="scope.row.Type=='picture' || scope.row.Type=='image'" class="f_color_blue">图片</span>
            <span v-else-if="scope.row.type=='video'" class="f_color_violet">视频</span>
            <span v-else-if="scope.row.type=='audio'" class="f_color_orange">音频</span>
            <span v-else-if="scope.row.type=='html'" class="f_color_green">HTML</span>
            <span v-else-if="scope.row.type=='flash'" class="f_color_red">FLASH</span>
            <span v-else class="f_color_darkgray">未知</span>
          </template>
        </el-table-column>
        <el-table-column prop="resolution" label="分辨率" width="90px"/>
        <el-table-column prop="size" label="大小(MB)" width="90px"/>
        <el-table-column prop="status" label="状态" width="90px">
          <template #default="scope">
            <span v-if="scope.row.status=='downloading'" class="f_color_orange">下载中</span>
            <span v-else-if="scope.row.status=='downloaded'" class="f_color_green">完成</span>
            <span v-else-if="scope.row.status=='predownload'" class="f_color_red">未下载</span>
            <span v-else class="f_color_darkgray">--</span>
          </template>
        </el-table-column>
        <el-table-column prop="del" label="del" width="60px"/>
        <el-table-column prop="start_time" label="下载时间" width="90px"/>
        <el-table-column prop="progress" label="进度(%)" width="90px"/>
        <el-table-column prop="pend" label="故障码" width="80px"/>
        <el-table-column prop="times" label="下载次数" width="80px"/>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="primary" :loading="!!scope.row.tableRedownloadBtnLoading" @click="tableRedownload(scope.row)" plain>重下</el-button>
            <el-button size="small" type="primary" :loading="!!scope.row.tablePreviewBtnLoading" @click="tablePreview(scope.row)" plain>预览</el-button>
            <el-popconfirm title="确定删除该媒体吗？" @confirm="tableMediadel(scope.row)">
              <template #reference>
                <el-button size="small" type="danger" :loading="!!scope.row.tableMediadelBtnLoading" plain>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="deviceMediaTotal>0"
        @current-change="handleDeviceMediaPageChange"
        :current-page="filterDeviceMediaData.page"
        :page-size="filterDeviceMediaData.pageSize"
        layout="total, prev, pager, next"
        :total="deviceMediaTotal">
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogDeviceMediaVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDevicePlayMediaVisible" :title="dialogDevicePlayMediaTitle" width="900px">
      <el-table border stripe :data="tablePlayMediasData" style="width: 100%" empty-text="暂无数据~" v-loading="tableDevicePlayMediaLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="100px"/>
        <el-table-column prop="media_id" label="媒体ID" width="100px"/>
        <el-table-column prop="sequence" label="序号" width="60px"/>
        <el-table-column prop="user_id" label="用户ID" width="100px"/>
        <el-table-column prop="title" label="显示名"/>
        <el-table-column prop="size" label="大小(M)" width="120px"/>
        <el-table-column prop="createdDate" label="添加日期" width="100px"/>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="primary" :loading="!!scope.row.tableRedownloadBtnLoading" @click="tableRedownload(scope.row, dialogPlayListMediaType)" plain>重下</el-button>
            <el-button size="small" type="warning"
              :loading="!!scope.row.tablePreviewBtnLoading"
              @click="tablePreview(scope.row)" plain>预览</el-button>
            <el-popconfirm title="确定删除该媒体吗？" @confirm="tableMediadel(scope.row, dialogPlayListMediaType)">
              <template #reference>
                <el-button size="small" type="danger" :loading="!!scope.row.tableMediadelBtnLoading" plain>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="playTotal>0"
        @current-change="handlePlayPageChange"
        :current-page="filterPlayData.page"
        :page-size="filterPlayData.pageSize"
        layout="total, prev, pager, next"
        :total="playTotal">
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogDevicePlayMediaVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogAdminsListVisible" :title="dialogAdminsListTitle" width="700px">
      <el-table ref="multipleTableRef" border stripe :data="tableAdminsListData" style="width: 100%" empty-text="暂无数据~" v-loading="tableAdminsListLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="60px"/>
        <el-table-column prop="user_id" label="用户ID" width="80px"/>
        <el-table-column prop="phoneNum" label="手机号" width="115px"/>
        <el-table-column prop="nickName" label="用户名" width="100px" show-overflow-tooltip/>
        <el-table-column prop="super" label="是否超管" width="60px"/>
        <el-table-column prop="addDate" label="创建日期" width="100px"/>
        <el-table-column fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-popconfirm v-if="scope.row.super==0" title="确定设为超级管理员吗？" @confirm="tableAdminsSetSuper(scope.row)">
              <template #reference>
                <el-button size="small" type="warning" :loading="scope.row.tableAdminsSetSuperBtnLoading" plain>设为超管</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm v-if="scope.row.super==0" title="确定删除该管理员吗？" @confirm="tableAdminsRemove(scope.row)">
              <template #reference>
                <el-button size="small" type="danger" :loading="scope.row.tableAdminsRemoveBtnLoading" plain>删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" type="primary" @click="toolsAddAdmin">新增</el-button>
          <el-button size="small" @click="dialogAdminsListVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogAddAdminVisible" :title="dialogAddAdminTitle" width="400px">
      <el-form label-width="100px">
        <el-form-item label="手机号">
          <el-input v-model="addAdminPhone" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" type="primary" :loading="dialogAddAdminSubmitBtnLoading" @click="handleAddAdminSave">提交</el-button>
          <el-button size="small" @click="dialogAddAdminVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogPreviewVisible" :title="dialogPreviewTitle" @closed="dialogPreviewClose">
      <audio v-if="dialogPreviewData.type==='audio'"
        id="audioPlayer" controls="controls"
        :src="dialogPreviewData.url"
        autoplay="autoplay" style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Audio player not available.
      </audio>
      <video v-else-if="dialogPreviewData.type==='video'"
        id="player" controls="controls"
        autoplay="autoplay"
        :src="dialogPreviewData.url"
        style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Video player not available.
      </video>
      <el-image v-else-if="dialogPreviewData.type==='picture'"
        :src="dialogPreviewData.url" fit="fill" 
        :width="dialogPreviewData.widhtInPixel"
        style="max-width:100%"
        alt="无法加载" />
      <iframe v-else-if="dialogPreviewData.type==='html'"
        :src="dialogPreviewData.url" style="width: 100%;height: 500px"
        frameborder="0"></iframe>
      <embed v-else id="flash" :src="dialogPreviewData.url" />
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogPreviewVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'DeviceView',
  data: function () {
    return {
      conditionTypes: [
        {
          value: 'Id',
          label: '设备ID',
        },
        {
          value: 'phone',
          label: '手机号',
        },
        {
          value: 'Name',
          label: '名称',
        },
        {
          value: 'userId',
          label: '用户ID',
        }
      ],
      filterData: {conditionType: 'userId', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailId: 0,
      dialogDeviceMediaVisible: false,
      dialogDeviceMediaTitle: '',
      tableDeviceMediaLoading: false,
      tableMediasData: [],
      dialogDevicePlayMediaVisible: false,
      dialogDevicePlayMediaTitle: '',
      tableDevicePlayMediaLoading: false,
      tablePlayMediasData: [],
      dialogEditVisible: false,
      dialogEditId: 0,
      detailEditTabs: 'tabs1',
      dialogEditData: {},
      dialogEditTouchData: {},
      dialogEditWindowsData: {},
      dialogEditSubtitleData: {},
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogEditSubmitAndCloseBtnLoading: false,
      dialogEditTouchSubmitBtnLoading: false,
      dialogEditTouchSubmitAndCloseBtnLoading: false,
      dialogEditWindowsSubmitBtnLoading: false,
      dialogEditWindowsSubmitAndCloseBtnLoading: false,
      dialogEditSubtitleSubmitBtnLoading: false,
      dialogEditSubtitleSubmitAndCloseBtnLoading: false,
      dialogEditScanJobBtnLoading: false,
      dialogEditSendReportBtnLoading: false,
      dialogEditSendSysInfoBtnLoading: false,
      dialogEditSendNextBtnLoading: false,
      dialogEditSendPreviousBtnLoading: false,
      dialogEditSendSockInfoBtnLoading: false,
      dialogEditOnlineTestBtnLoading: false,
      dialogEditSendRandomBtnLoading: false,
      dialogEditCleanPlaylistBtnLoading: false,
      dialogEditCleanDeviceBtnLoading: false,
      dialogEditAbortDownloadBtnLoading: false,
      dialogEditUpgradeDeviceBtnLoading: false,
      dialogEditSendRestartBtnLoading: false,
      dialogEditClearErrorCacheBtnLoading: false,
      dialogEditGetDebugBtnLoading: false,
      dialogEditRebornBtnLoading: false,
      dialogEditSendDebugBtnLoading: false,
      dialogEditResetBtnLoading: false,
      dialogEditServiceDateBtnLoading: false,
      dialogEditGetRedisDataBtnLoading: false,
      dialogEditServiceDatePopconfirmTitle: '确认修改 服务有效期 吗?',
      dialogEditTransferServiceBtnLoading: false,
      dialogEditServiceDate: {},
      dialogEditTransferServiceData: {},
      dialogEditDebugInfoResData: {time: '', json: ''},
      dialogEditDebugInfoData: {command: 'Report'},
      dialogEditRedisDataType: 'RedisDeviceCommunicate',
      dialogEditRedisDataOptions: [
        {
          value: 'RedisDeviceCommunicate',
          label: 'RedisDeviceCommunicate',
        },
        {
          value: 'RedisDeviceRuntimeConfig',
          label: 'RedisDeviceRuntimeConfig',
        },
        {
          value: 'RedisDeviceRuntimeStatus',
          label: 'RedisDeviceRuntimeStatus',
        },
        {
          value: 'RedisDeviceDebugInfo',
          label: 'RedisDeviceDebugInfo',
        },
        {
          value: 'RedisDevicePassiveTask',
          label: 'RedisDevicePassiveTask',
        },
        {
          value: 'RedisDevicePlaylistOnline',
          label: 'RedisDevicePlaylistOnline',
        },
        {
          value: 'RedisDevicePlaylistWindow',
          label: 'RedisDevicePlaylistWindow',
        },
        {
          value: 'RedisDeviceStorage',
          label: 'RedisDeviceStorage',
        },
        {
          value: 'RedisDeviceAccessCount',
          label: 'RedisDeviceAccessCount',
        },
        {
          value: 'RedisDeviceOnvif',
          label: 'RedisDeviceOnvif',
        },
        {
          value: 'RedisDeviceOnvifProfile',
          label: 'RedisDeviceOnvifProfile',
        },
        {
          value: 'RedisDevice',
          label: 'RedisDevice',
        },
        {
          value: 'RedisDeviceManageConfig',
          label: 'RedisDeviceManageConfig',
        },
        {
          value: 'RedisDevicePlaybackConfig',
          label: 'RedisDevicePlaybackConfig',
        },
      ],
      dialogEditSbtLocationOptions: [
        {
          value: 'top',
          label: '顶部',
        },
        {
          value: 'center',
          label: '居中',
        },
        {
          value: 'bottom',
          label: '底部',
        },
      ],
      dialogEditTextSizeOptions: [
        {
          value: 'normal',
          label: 'normal',
        },
        {
          value: 'smaller',
          label: 'smaller',
        },
        {
          value: 'smallest',
          label: 'smallest',
        },
        {
          value: 'larger',
          label: 'larger',
        },
        {
          value: 'largest',
          label: 'largest',
        },
      ],
      dialogEditResetLevelOptions: [
        {
          value: 0,
          label: '不允许',
        },
        {
          value: 1,
          label: '弹框yes',
        },
        {
          value: 10,
          label: '弹框二维码',
        },
      ],
      dialogEditWinTemplateFromOptions: [
        {
          value: 'julyfire',
          label: 'julyfire',
        },
        {
          value: 'user',
          label: 'user',
        },
      ],
      dialogEditDebugOptions: [
        {
          value: 'Report',
          label: '上报配置',
        },
        {
          value: 'SpData',
          label: 'SP数据',
        },
        {
          value: 'RuntimeStatus',
          label: 'Runtime数据',
        },
        {
          value: 'FileList',
          label: '文件列表',
        },
        {
          value: 'DownloadList',
          label: '下载中数据',
        },
        {
          value: 'StorageStat',
          label: '存储遍历',
        },
        {
          value: 'SqliteData',
          label: 'Sqlite数据',
        },
        {
          value: 'ZipData',
          label: 'ZIP数据',
        },
        {
          value: 'SelfTest',
          label: '自我测试',
        },
      ],
      dialogDetailVisible: false,
      dialogDetailTitle: '',
      dialogDetailDataTab1: {},
      dialogDetailDataTab2: {},
      dialogDetailDataTab3: {},
      dialogDetailDataTab4: {},
      dialogDetailDataTab5: {},
      dialogDetailDataTab5IsHide: true,
      dialogDetailDataTab5IsHideMsg: '无数据',
      dialogEditSafeActionData: {},
      dialogEditRedisDataRes: '--',
      detailTabs: 'tabs1',
      dialogAtionsTitle: '',
      actionData: {type: '1'},
      dialogPreviewVisible: false,
      dialogPreviewTitle: '',
      dialogPreviewData: {},
      dialogAdminsListTitle: '',
      dialogAdminsListVisible: false,
      tableAdminsListData: [],
      tableAdminsListLoading: false,
      dialogAddAdminTitle: '',
      dialogAddAdminVisible: false,
      addAdminPhone: '',
      dialogAddAdminSubmitBtnLoading: false,
      playTotal: 0,
      filterPlayData: {page: 1, pageSize: 10},
      deviceMediaTotal: 0,
      filterDeviceMediaData: {page: 1, pageSize: 10},
    }
  },
  methods: {
    list() {
      const url = this.$apis.Device.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {conditionType: 'Id', conditionValue: ''}
    },
    tableDetail(row) {
      this.dialogDetailId = row.ID
      this.dialogDetailTitle = `详情[${this.dialogDetailId}]`
      const url = this.$apis.Device.getDeviceInfo
      const params = {deviceId: this.dialogDetailId}
      row.tableDetailBtnLoading = true
      this.detailTabs = 'tabs1'
      this.dialogDetailDataTab2 = {}
      this.dialogDetailDataTab3 = {}
      this.dialogDetailDataTab4 = {}
      this.dialogDetailDataTab5 = {}
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailDataTab1 = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableDeviceMediaDetail(row) {
      this.dialogDetailData = row
      this.dialogDeviceMediaTitle = `设备媒体列表[${row.ID}]`
      this.dialogDeviceMediaVisible = true
      this.filterDeviceMediaData.page = 1
      this.loadDeviceMediaList()
    },
    loadDeviceMediaList() {
      const {ID: deviceId} = this.dialogDetailData
      const params = {deviceId, ...this.filterDeviceMediaData}
      this.tableDeviceMediaLoading = true
      const url = this.$apis.Device.getmediacache
      this.$httpPost(url, params).then((res) => {
        this.tableMediasData = res.rows
        this.deviceMediaTotal = res.count
        this.dialogDeviceMediaVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.tableDeviceMediaLoading = false
      })
    },
    tableDevicePlayListMedia(row, mediaType) {
      this.dialogPlayDetailData = row
      this.dialogDetailData = row
      this.dialogDevicePlayMediaTitle = `${mediaType}列表[${row.ID}]`
      this.dialogDevicePlayMediaVisible = true
      this.dialogPlayListMediaType = mediaType
      this.filterPlayData.page = 1
      this.loadDevicePlayList()
    },
    loadDevicePlayList() {
      const {ID: deviceId} = this.dialogPlayDetailData
      const params = {deviceId, mediaType: this.dialogPlayListMediaType, ...this.filterPlayData}
      this.tableDevicePlayMediaLoading = true
      const url = this.$apis.Device.getPlayListMedia
      this.$httpPost(url, params).then((res) => {
        this.tablePlayMediasData = res.rows
        this.playTotal = res.count
        this.dialogDevicePlayMediaVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.tableDevicePlayMediaLoading = false
      })
    },
    tableAdminsList(row) {
      this.dialogDetailData = row
      this.dialogAdminsListTitle = `管理员列表[${row.ID}]`
      this.dialogAdminsListVisible = true
      this.loadAdminsList()
    },
    loadAdminsList() {
      const {ID: id} = this.dialogDetailData
      const params = {id}
      this.tableAdminsListLoading = true
      const url = this.$apis.Device.getAdmins
      this.$httpPost(url, params).then((res) => {
        this.tableAdminsListData = res
        this.dialogAdminsListVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.tableAdminsListLoading = false
      })
    },
    toolsAddAdmin() {
      this.dialogAddAdminTitle = `新增管理员[${this.dialogDetailData.ID}]`
      this.dialogAddAdminVisible = true
      this.addAdminPhone = ''
    },
    handleAddAdminSave() {
      const {ID: deviceId} = this.dialogDetailData
      const params = {deviceId, phone: this.addAdminPhone}
      this.dialogAddAdminSubmitBtnLoading = true
      const url = this.$apis.Device.addAdmin
      this.$httpPost(url, params).then(() => {
        this.$message.success('新增成功~')
        this.dialogAddAdminVisible = false
        this.loadAdminsList()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '保存失败~')
      }).finally(() => {
        this.dialogAddAdminSubmitBtnLoading = false
      })
    },
    tableAdminsSetSuper(row) {
      const {id} = row
      row.tableAdminsSetSuperBtnLoading = true
      const url = this.$apis.Device.setSuper
      this.$httpPost(url, {id}).then(() => {
        this.$message.success('操作成功~')
        this.loadAdminsList()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '保存失败~')
      }).finally(() => {
        this.tableAdminsSetSuperBtnLoading = false
      })
    },
    tableAdminsRemove(row) {
      const {id} = row
      row.tableAdminsRemoveBtnLoading = true
      const url = this.$apis.Device.removeAdmin
      this.$httpPost(url, {id}).then(() => {
        this.$message.success('操作成功~')
        this.loadAdminsList()
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '保存失败~')
      }).finally(() => {
        this.tableAdminsRemoveBtnLoading = false
      })
    },
    tableRedownload(row, type = 'media') {
      const url = this.$apis.Device.recachemedia
      const {ID: deviceId} = this.dialogDetailData
      const {media_id: mediaId} = row
      const params = {deviceId, mediaId}
      row.tableRedownloadBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功~')
        type === 'media' ? this.loadDeviceMediaList() : this.loadDevicePlayList()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableRedownloadBtnLoading = false
      })
    },
    tableMediadel(row, type = 'media') {
      const url = this.$apis.Device.uncachemedia
      const {ID: deviceId} = this.dialogDetailData
      const {media_id: mediaId} = row
      const params = {deviceId, mediaId}
      row.tableMediadelBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        console.log(res)
        type === 'media' ? this.loadDeviceMediaList() : this.loadDevicePlayList()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableMediadelBtnLoading = false
      })
    },
    tablePreview(row) {
      this.dialogPreviewTitle = `预览ID:${row.id}`
      const {media_id: mediaId} = row
      const url = this.$apis.Media.preview
      const params = {id: mediaId}
      row.tablePreviewBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogPreviewData = res
        this.dialogPreviewVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tablePreviewBtnLoading = false
      })
    },
    dialogPreviewClose() {
      const {url, type} = this.dialogPreviewData
      if (url) {
        if ('audio' === type) {
          document.getElementById('audioPlayer').pause()
        }
        if ('video' === type) {
          document.getElementById('player').pause()
        }
      }
    },
    tableEdit(row) {
      this.dialogEditId = row.ID
      this.dialogEditServiceDate.time = row.serviceEndDate
      this.dialogEditTitle = `DeviceID[${row.ID}]`
      this.dialogEditDebugInfoResData = {time: '', json: ''}
      const url = this.$apis.Device.getConfig
      const params = {deviceId: this.dialogEditId}
      row.tableEditBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogEditData = this.getDialogEditData(res)
        this.dialogEditTouchData = this.getDialogEditTouchData(res)
        this.dialogEditWindowsData = this.getDialogEditWindowsData(res)
        this.dialogEditSubtitleData = this.getDialogEditSubtitleData(res)
        this.dialogEditVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableEditBtnLoading = false
      })
    },
    handleEditConfigSave() {
      this.handleEditConfigSaveAction('dialogEditSubmitBtnLoading')
    },
    handleEditConfigSaveAndClose() {
      this.handleEditConfigSaveAction('dialogEditSubmitAndCloseBtnLoading', true)
    },
    handleEditConfigSaveAction(loadingBtn, closeDialog = false) {
      const _this = this
      this[loadingBtn] = true
      const url = this.$apis.Device.setConfig
      const params = {deviceId: this.dialogEditId, editData: this.dialogEditData}
      this.querySave(url, params, function() {
        _this[loadingBtn] = false
        if (closeDialog) {
          _this.dialogEditVisible = false
        }
      })
    },
    handleEditTouchConfigSave() {
      this.handleEditTouchConfigSaveAction('dialogEditTouchSubmitBtnLoading')
    },
    handleEditTouchConfigSaveAndClose() {
      this.handleEditTouchConfigSaveAction('dialogEditTouchSubmitAndCloseBtnLoading', true)
    },
    handleEditTouchConfigSaveAction(loadingBtn, closeDialog = false) {
      const _this = this
      this[loadingBtn] = true
      const url = this.$apis.Device.setTouchConfig
      const params = {deviceId: this.dialogEditId, editData: this.dialogEditTouchData}
      this.querySave(url, params, function() {
        _this[loadingBtn] = false
        if (closeDialog) {
          _this.dialogEditVisible = false
        }
      })
    },
    handleEditWindowsConfigSave() {
      this.handleEditWindowsConfigSaveAction('dialogEditWindowsSubmitBtnLoading')
    },
    handleEditWindowsConfigSaveAndClose() {
      this.handleEditWindowsConfigSaveAction('dialogEditWindowsSubmitAndCloseBtnLoading', true)
    },
    handleEditWindowsConfigSaveAction(loadingBtn, closeDialog = false) {
      const _this = this
      this[loadingBtn] = true
      const url = this.$apis.Device.setWindowsConfig
      const params = {deviceId: this.dialogEditId, editData: this.dialogEditWindowsData}
      this.querySave(url, params, function() {
        _this[loadingBtn] = false
        if (closeDialog) {
          _this.dialogEditVisible = false
        }
      })
    },
    handleEditSubtitleConfigSave() {
      this.handleEditSubtitleConfigSaveAction('dialogEditSubtitleSubmitBtnLoading')
    },
    handleEditSubtitleConfigSaveAndClose() {
      this.handleEditSubtitleConfigSaveAction('dialogEditSubtitleSubmitAndCloseBtnLoading', true)
    },
    handleEditSubtitleConfigSaveAction(loadingBtn, closeDialog = false) {
      const _this = this
      this[loadingBtn] = true
      const url = this.$apis.Device.setSubtitleConfig
      const params = {deviceId: this.dialogEditId, editData: this.dialogEditSubtitleData}
      this.querySave(url, params, function() {
        _this[loadingBtn] = false
        if (closeDialog) {
          _this.dialogEditVisible = false
        }
      })
    },
    querySave(url, data, callBack) {
      this.$httpPost(url, data).then(() => {
        this.$message.success('提交成功~')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        if (typeof(callBack) === 'function') {
          callBack()
        }
      })
    },
    handleActionCommon(command, loadingBtn, otherParams = {}) {
      const _this = this
      _this[loadingBtn] = true
      const url = this.$apis.Device.deviceCommand
      const params = {deviceId: this.dialogEditId, command, ...otherParams}
      this.querySave(url, params, () => {
        _this[loadingBtn] = false
        if (['Reborn', 'Reset'].includes(command)) {
          Object.assign(this.dialogEditSafeActionData, {verify_code: '', reason: ''});
        }
      })
    },
    handleActionScanJob() {
      this.handleActionCommon('ScanJob', 'dialogEditScanJobBtnLoading')
    },
    handleActionSendReport() {
      this.handleActionCommon('SendReport', 'dialogEditSendReportBtnLoading')
    },
    handleActionSendSysInfo() {
      this.handleActionCommon('SendSysInfo', 'dialogEditSendSysInfoBtnLoading')
    },
    handleActionSendNext() {
      this.handleActionCommon('SendNext', 'dialogEditSendNextBtnLoading')
    },
    handleActionSendPrevious() {
      this.handleActionCommon('SendPrevious', 'dialogEditSendPreviousBtnLoading')
    },
    handleActionSendSockInfo() {
      this.handleActionCommon('SendSockInfo', 'dialogEditSendSockInfoBtnLoading')
    },
    handleActionOnlineTest() {
      this.handleActionCommon('OnlineTest', 'dialogEditOnlineTestBtnLoading')
    },
    handleActionSendRandom() {
      this.handleActionCommon('SendRandom', 'dialogEditSendRandomBtnLoading')
    },
    handleActionCleanPlaylist() {
      this.handleActionCommon('CleanPlaylist', 'dialogEditCleanPlaylistBtnLoading')
    },
    handleActionCleanDevice() {
      this.handleActionCommon('CleanDevice', 'dialogEditCleanDeviceBtnLoading')
    },
    handleActionAbortDownload() {
      this.handleActionCommon('AbortDownload', 'dialogEditAbortDownloadBtnLoading')
    },
    handleActionUpgradeDevice() {
      this.handleActionCommon('UpgradeDevice', 'dialogEditUpgradeDeviceBtnLoading')
    },
    handleActionSendRestart() {
      this.handleActionCommon('SendRestart', 'dialogEditSendRestartBtnLoading')
    },
    handleActionClearErrorCache() {
      this.handleActionCommon('ClearErrorCache', 'dialogEditClearErrorCacheBtnLoading')
    },
    handleActionSendDebug() {
      this.handleActionCommon(this.dialogEditDebugInfoData.command, 'dialogEditSendDebugBtnLoading')
    },
    handleActionReborn() {
      if (this.validateSafeAction() === false) return
      this.handleActionCommon('Reborn', 'dialogEditRebornBtnLoading', this.dialogEditSafeActionData)
    },
    handleActionReset() {
      if (this.validateSafeAction() === false) return
      this.handleActionCommon('Reset', 'dialogEditResetBtnLoading', this.dialogEditSafeActionData)
    },
    validateSafeAction() {
      if (! this.dialogEditSafeActionData.verify_code) {
        this.$message.error('请输入安全码~')
        return false
      }
      if (! this.dialogEditSafeActionData.reason) {
        this.$message.error('请输入原因~')
        return false
      }
      return true
    },
    handleActionServiceDate() {
      const url = this.$apis.Device.extendServiceDate
      const {time, reason} = this.dialogEditServiceDate
      if (!time || !reason) {
        this.$message.error('服务有效期 和 修改原因 都不能为空')
        return
      }
      const data = {deviceId: this.dialogEditId, time, reason}
      this.dialogEditServiceDateBtnLoading = true
      this.$httpPost(url, data).then(() => {
        this.$message.success('保存成功~')
        Object.assign(this.dialogEditServiceDate, {reason: ''})
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditServiceDateBtnLoading = false
      })
    },
    handleActionTransferService() {
      const url = this.$apis.Device.transferService
      const {targetDeviceId, userId, reason} = this.dialogEditTransferServiceData
      if (!targetDeviceId || !userId || !reason) {
        this.$message.error('迁移到的设备ID、userID 和 迁移原因 都不能为空')
        return
      }
      const data = {deviceId: this.dialogEditId, targetDeviceId, userId, reason}
      this.dialogEditTransferServiceBtnLoading = true
      this.$httpPost(url, data).then(() => {
        this.$message.success('迁移成功~')
        Object.assign(this.dialogEditTransferServiceData, {reason: ''})
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditTransferServiceBtnLoading = false
      })
    },
    handleActionGetDebug() {
      const url = this.$apis.Device.getDebugInfo
      const {command} = this.dialogEditDebugInfoData;
      const data = {deviceId: this.dialogEditId, command, type: command}
      this.dialogEditGetDebugBtnLoading = true
      this.$httpPost(url, data).then((res) => {
        this.$message.success('刷新成功~')
        this.dialogEditDebugInfoResData.time = res.time ? new Date(parseInt(res.time) * 1000).toLocaleString() : 'unknown'
        this.dialogEditDebugInfoResData.json = res.data ? this.formatJson(res.data) : '-'
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditGetDebugBtnLoading = false
      })
    },
    handleActionGetRedisData() {
      this.dialogEditRedisDataRes = '加载中...'
      const url = this.$apis.Device.getRedisData
      const data = {deviceId: this.dialogEditId, type: this.dialogEditRedisDataType}
      this.dialogEditGetRedisDataBtnLoading = true
      this.$httpPost(url, data).then((res) => {
        this.$message.success('获取成功~')
        if (res) {
          try {
            this.dialogEditRedisDataRes = JSON.stringify(res, null, 4)
          } catch(e) {
            this.dialogEditRedisDataRes = res 
          }
        } else {
          this.dialogEditRedisDataRes = '无数据'
        }
      }).catch((e) => {
        this.dialogEditRedisDataRes = '获取失败~'
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.dialogEditGetRedisDataBtnLoading = false
      })
    },
    formatJson(json, options) {
      let reg = null,
          formatted = '',
          pad = 0,
          PADDING = '    ';
      options = options || {};
      options.newlineAfterColonIfBeforeBraceOrBracket = (options.newlineAfterColonIfBeforeBraceOrBracket === true) ? true : false;
      options.spaceAfterColon = (options.spaceAfterColon === false) ? false : true;
      if (typeof json !== 'string') {
          json = JSON.stringify(json);
      } else {
          json = JSON.parse(json);
          json = JSON.stringify(json);
      }
      reg = /([{}])/g;
      json = json.replace(reg, '\r\n$1\r\n');
      reg = /([[\]])/g;
      json = json.replace(reg, '\r\n$1\r\n');
      reg = /(,)/g;
      json = json.replace(reg, '$1\r\n');
      reg = /(\r\n\r\n)/g;
      json = json.replace(reg, '\r\n');
      reg = /\r\n,/g;
      json = json.replace(reg, ',');
      if (!options.newlineAfterColonIfBeforeBraceOrBracket) {
          reg = /:\r\n\{/g;
          json = json.replace(reg, ':{');
          reg = /:\r\n\[/g;
          json = json.replace(reg, ':[');
      }
      if (options.spaceAfterColon) {
          reg = /:/g;
          json = json.replace(reg, ':');
      }
      (json.split('\r\n')).forEach(function(node) {
          var i = 0,
              indent = 0,
              padding = '';

          if (node.match(/\{$/) || node.match(/\[$/)) {
              indent = 1;
          } else if (node.match(/\}/) || node.match(/\]/)) {
              if (pad !== 0) {
                  pad -= 1;
              }
          } else {
              indent = 0;
          }
          for (i = 0; i < pad; i++) {
              padding += PADDING;
          }
          formatted += padding + node + '\r\n';
          pad += indent;
      });
      return formatted;
    },
    getDialogEditData(res) {
      const columns = ['Angle', 'SockService', 'PictureTime', 'HTMLTime', 'VideoStretch',
      'PicStretch', 'Effect', 'AudioMode', 'AudioInterval', 'AutoStartup', 'HideNav', 'FontSize',
      'TextSize', 'FakePortrait', 'AutoCleanUp', 'BootDelay', 'DefaultMediaID', 'DlProgressRpt',
      'ForceFore', 'LifeTimeEn', 'NoExit', 'NtpEn', 'ResetLevel', 'SchedulePlayEn', 'SilentInstall',
      'TVStretch', 'VPlayer', 'mp4_only', 'no_webapp', 'origin_file_only']
      return _.pick(res, columns)
    },
    getDialogEditTouchData(res) {
      const columns = ['TouchEnable', 'MediaControl', 'FingerSwitch',
      'FingerTime', 'DutyMode', 'DutyMedia', 'DutyTime']
      return _.pick(res, columns)
    },
    getDialogEditWindowsData(res) {
      const columns = ['PlayWatcher', 'WindowsMode', 'WinPicTime',
      'WinPicEffect', 'WinPicStretch', 'WinVideoStretch', 'WinTemplateFrom',
      'WinJulyfireCfgID', 'WinUserCfgID', 'WinLocked']
      return _.pick(res, columns)
    },
    getDialogEditSubtitleData(res) {
      const columns = ['SubtitleMode', 'SbtSpeed', 'SbtLocation',
      'SbtSize', 'SbtIconEn', 'SbtIconID', 'SbtGap',
      'SbtBgEn', 'SbtBgColor', 'SbtBgAlpha']
      return _.pick(res, columns)
    },
    handleTabChange(tabPaneName) {
      const {
        getDeviceInfo: tabs1,
        getDeviceStatus: tabs2,
        jobStatus: tabs3,
        getRuntimeConfig: tabs4,
        storageList: tabs5,
      } = this.$apis.Device
      const urlMap = {tabs1, tabs2, tabs3, tabs4, tabs5}
      const tabsNum = tabPaneName.slice(4)
      const tabsKey = `dialogDetailDataTab${tabsNum}`
      if (JSON.stringify(this[tabsKey]) !== '{}') {
        return false
      }
      const url = urlMap[tabPaneName]
      const params = {deviceId: this.dialogDetailId}
      this.detailTabs = tabPaneName
      this.$showTargetLoading('.detailDialog')
      this.dialogDetailDataTab5IsHide = true
      this.dialogDetailDataTab5IsHideMsg = '加载中...'
      this.$httpPost(url, params).then((res) => {
        this[tabsKey] = res
        if (!res || res.length === 0) {
          this.dialogDetailDataTab5IsHideMsg = '无数据'
        } else {
          this.dialogDetailDataTab5IsHide = false
        }
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.$hideTargetLoading()
      })
    },
    handleLoadDialogDetailDataTab2() {
      this.dialogDetailDataTab2 = {}
      this.handleTabChange('tabs2')
    },
    handleLoadDialogDetailDataTab3() {
      this.dialogDetailDataTab3 = {}
      this.handleTabChange('tabs3')
    },
    handleLoadDialogDetailDataTab4() {
      this.dialogDetailDataTab4 = {}
      this.handleTabChange('tabs4')
    },
    handleLoadDialogDetailDataTab5() {
      this.dialogDetailDataTab5 = {}
      this.handleTabChange('tabs5')
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    handlePlayPageChange(val) {
      this.filterPlayData.page = val
      this.loadDevicePlayList()
    },
    handleDeviceMediaPageChange(val) {
      this.filterDeviceMediaData.page = val
      this.loadDeviceMediaList()
    }
  },
  created() {
    this.list()
  }
}
</script>
<style scoped>
.dialogEditBtns {
  float: right;
}
</style>