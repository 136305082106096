import {createRouter, createWebHistory} from 'vue-router'

import login from '@/views/Login';
import Home from '@/views/Home';
import Base from '@/views/Base';
import Invoice from '@/views/Invoice';
import Payment from '@/views/Payment';
import Devicetoken from '@/views/Devicetoken';
import Wxpayment from '@/views/Wxpayment';
import Media from '@/views/Media';
import Platformmedia from '@/views/Platformmedia';
import File from '@/views/File';
import Devicepaid from '@/views/Devicepaid';
import Transcode from '@/views/Transcode';
import Devmedia from '@/views/Devmedia';
import Logs from '@/views/Logs';
import User from '@/views/User';
import Manager from '@/views/Manager';
import Task from '@/views/Task';
import Device from '@/views/Device';
import Userdevice from '@/views/Userdevice';
import Deviceplaylistmedia from '@/views/Deviceplaylistmedia';
import Deviceplaylistaudio from '@/views/Deviceplaylistaudio';
import Deviceplaylistsubtitle from '@/views/Deviceplaylistsubtitle';
import Userwindows from '@/views/Userwindows';
import Platformwindows from '@/views/Platformwindows';
import Feedback from '@/views/Feedback';
import Wechat from '@/views/Wechat';
import Userlog from '@/views/Userlog';

const children = [
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {requiresAuth: true, activeMenu: '/home'}
    },
    {
        path: '/invoice',
        name: 'invoice',
        component: Invoice,
        meta: {requiresAuth: true, activeMenu: '/invoice'}
    },
    {
        path: '/payment',
        name: 'payment',
        component: Payment,
        meta: {requiresAuth: true, activeMenu: '/payment'}
    },
    {
        path: '/wxpayment',
        name: 'wxpayment',
        component: Wxpayment,
        meta: {requiresAuth: true, activeMenu: '/wxpayment'}
    },
    {
        path: '/devicetoken',
        name: 'devicetoken',
        component: Devicetoken,
        meta: {requiresAuth: true, activeMenu: '/devicetoken'}
    },
    {
        path: '/media',
        name: 'media',
        component: Media,
        meta: {requiresAuth: true, activeMenu: '/media'}
    },
    {
        path: '/platformmedia',
        name: 'platformmedia',
        component: Platformmedia,
        meta: {requiresAuth: true, activeMenu: '/platformmedia'}
    },
    {
        path: '/file',
        name: 'file',
        component: File,
        meta: {requiresAuth: true, activeMenu: '/file'}
    },
    {
        path: '/devicepaid',
        name: 'devicepaid',
        component: Devicepaid,
        meta: {requiresAuth: true, activeMenu: '/devicepaid'}
    },
    {
        path: '/transcode',
        name: 'transcode',
        component: Transcode,
        meta: {requiresAuth: true, activeMenu: '/transcode'}
    },
    {
        path: '/devmedia',
        name: 'devmedia',
        component: Devmedia,
        meta: {requiresAuth: true, activeMenu: '/devmedia'}
    },
    {
        path: '/logs',
        name: 'logs',
        component: Logs,
        meta: {requiresAuth: true, activeMenu: '/logs'}
    },
    {
        path: '/user',
        name: 'user',
        component: User,
        meta: {requiresAuth: true, activeMenu: '/user'}
    },
    {
        path: '/manager',
        name: 'manager',
        component: Manager,
        meta: {requiresAuth: true, activeMenu: '/manager'}
    },
    {
        path: '/task',
        name: 'task',
        component: Task,
        meta: {requiresAuth: true, activeMenu: '/task'}
    },
    {
        path: '/device',
        name: 'device',
        component: Device,
        meta: {requiresAuth: true, activeMenu: '/device'}
    },
    {
        path: '/userdevice',
        name: 'userdevice',
        component: Userdevice,
        meta: {requiresAuth: true, activeMenu: '/userdevice'}
    },
    {
        path: '/deviceplaylistmedia',
        name: 'deviceplaylistmedia',
        component: Deviceplaylistmedia,
        meta: {requiresAuth: true, activeMenu: '/deviceplaylistmedia'}
    },
    {
        path: '/deviceplaylistsubtitle',
        name: 'deviceplaylistsubtitle',
        component: Deviceplaylistsubtitle,
        meta: {requiresAuth: true, activeMenu: '/deviceplaylistsubtitle'}
    },
    {
        path: '/deviceplaylistaudio',
        name: 'deviceplaylistaudio',
        component: Deviceplaylistaudio,
        meta: {requiresAuth: true, activeMenu: '/deviceplaylistaudio'}
    },
    {
        path: '/userwindows',
        name: 'userwindows',
        component: Userwindows,
        meta: {requiresAuth: true, activeMenu: '/userwindows'}
    },
    {
        path: '/platformwindows',
        name: 'platformwindows',
        component: Platformwindows,
        meta: {requiresAuth: true, activeMenu: '/platformwindows'}
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: Feedback,
        meta: {requiresAuth: true, activeMenu: '/feedback'}
    },
    {
        path: '/wechat',
        name: 'wechat',
        component: Wechat,
        meta: {requiresAuth: true, activeMenu: '/wechat'}
    },
    {
        path: '/userlog',
        name: 'userlog',
        component: Userlog,
        meta: {requiresAuth: true, activeMenu: '/userlog'}
    },
]

const routers = [
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {requiresAuth: false, showAsideAndHeader: true}
    },
    {
        path: '/',
        component: Base,
        meta: {requiresAuth: true, showAsideAndHeader: true},
        children: children
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes: routers
})

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({path: '/home'})
    }
    if (to.meta.requiresAuth === false) {
        next()
    } else {
        if (window.localStorage.getItem('token')) {
            next()
        } else {
            next({path: '/login'})
        }
    }
})

export default router