<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.state" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="80" />
        <el-table-column prop="userId" label="用户ID" width="80" />
        <el-table-column prop="mediaId" label="媒体ID" width="80" />
        <el-table-column prop="percent" label="进度(%)" width="60" />
        <el-table-column prop="stateTxt" label="状态" width="80" >
          <template #default="scope">
            <span v-if="scope.row.state=='TranscodeSuccess'" class="f_color_green">{{scope.row.stateTxt}}</span>
            <span v-else class="f_color_violet">{{scope.row.stateTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fileId" label="File表ID" width="80" />
        <el-table-column show-overflow-tooltip prop="jobid" label="JobID" />
        <el-table-column show-overflow-tooltip prop="startTime" label="开始时间" width="160" />
        <el-table-column show-overflow-tooltip prop="endTime" label="结束时间" width="160" />
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>查询</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.userId}}</el-descriptions-item>
        <el-descriptions-item label="媒体ID">{{dialogDetailData.mediaId}}</el-descriptions-item>
        <el-descriptions-item label="进度(%)">{{dialogDetailData.percent}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.stateTxt}}</el-descriptions-item>
        <el-descriptions-item label="JobID">{{dialogDetailData.jobid}}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{dialogDetailData.startTime}}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{dialogDetailData.endTime}}</el-descriptions-item>
        <el-descriptions-item label="管道  ID">{{dialogDetailData.pipelineid}}</el-descriptions-item>
        <el-descriptions-item label="目标格式的容器">{{dialogDetailData.container}}</el-descriptions-item>
        <el-descriptions-item label="错误代码">{{dialogDetailData.code}}</el-descriptions-item>
        <el-descriptions-item label="错误信息">{{dialogDetailData.message}}</el-descriptions-item>
        <el-descriptions-item label="源bucket">{{dialogDetailData.inputBucket}}</el-descriptions-item>
        <el-descriptions-item label="源文件">{{dialogDetailData.inputKey}}</el-descriptions-item>
        <el-descriptions-item label="目的bucket">{{dialogDetailData.outputBucket}}</el-descriptions-item>
        <el-descriptions-item label="目的文件">{{dialogDetailData.outputKey}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'TranscodeView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'TranscodeSuccess',
          label: '已完成',
        },
        {
          value: 'Transcoding',
          label: '转码中',
        }
      ],
      conditionTypes: [
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'media_id ',
          label: '媒体ID',
        },
        {
          value: 'file_id ',
          label: 'File表ID',
        },
        {
          value: 'pipelineid ',
          label: 'JobID',
        }
      ],
      filterData: {state: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      }
    }
  },
  methods: {
    list() {
      const url = this.$apis.Transcode.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'user_id', conditionValue: ''}
    },
    tableDetail(row) {
      const url = this.$apis.Transcode.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableEdit(row) {
      row.tableEditBtnLoading = true
      const url = this.$apis.Transcode.queryStatus
      const params = {fileId: row.fileId}
      this.$httpPost(url, params).then((res) => {
        if (res && res.res) {
          this.$message.success('查看请求成功~')
          this.list()
        } else {
          this.$message.error('查看结果失败~')
        }
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '请求失败~')
      }).finally(() => {
        row.tableEditBtnLoading = false
      })

      this.marks = ''
      this.tableEditId = row.id
      
      this.dialogFormVisible = true
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>
