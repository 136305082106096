<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="类型">
            <el-select v-model="filterData.type" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
          <el-upload
            :headers="upLoadHeaders"
            class="upload-file"
            :action="uploadUrl"
            :show-file-list="false"
            :on-change="changeUpload"
            :on-error="onErrorUpLoad"
            :before-upload="beforeUpload"
            :disabled="upLoadBtndisabled"
          >
            <el-button type="primary" size="small">上传</el-button>
          </el-upload>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData" @sort-change="handleTableSort"
      sortable="custom" :default-sort="tableDefaultSort"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" sortable :sort-orders="['ascending', 'descending']"/>
        <el-table-column show-overflow-tooltip prop="title" label="标题"/>
        <el-table-column prop="typeTxt" label="类型">
          <template #default="scope">
            <span v-if="scope.row.type=='image'" class="f_color_blue">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='video'" class="f_color_violet">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='audio'" class="f_color_orange">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='html'" class="f_color_green">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='text'" class="f_color_red">{{scope.row.typeTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.typeTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="size" label="大小(M)" />
        <el-table-column label="分辨率">
          <template #default="scope">
            {{scope.row.widthInPixel}} x {{scope.row.heightInPixel}}
          </template>
        </el-table-column>
        <el-table-column prop="durationTxt" label="播放时长(分:秒)"/>
        <el-table-column prop="adminId" label="用户ID"/>">
        <el-table-column prop="statusTxt" label="状态">
          <template #default="scope">
            <span v-if="scope.row.status=='created'" class="f_color_orange">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" width="100" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="200">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning"
              v-if="scope.row.status=='normal'"
              :loading="!!scope.row.tablePreviewBtnLoading"
              @click="tablePreview(scope.row)" plain>预览</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>编辑</el-button>
            <el-button v-if="scope.row.status=='normal'" size="small" type="danger" :loading="!!scope.row.tableStatusDisabledBtnLoading" @click="tableStatusDisabled(scope.row)" plain>禁用</el-button>
            <el-button v-else-if="scope.row.status=='disabled'" size="small" type="warning" :loading="!!scope.row.tableStatusNormalBtnLoading" @click="tableStatusNormal(scope.row)" plain>解禁</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="标题">{{dialogDetailData.title}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{dialogDetailData.typeTxt}}</el-descriptions-item>
        <el-descriptions-item label="大小(M)">{{dialogDetailData.size}}</el-descriptions-item>
        <!-- <el-descriptions-item label="缩略图">
          <img :src="dialogDetailData.thumbnail" />
        </el-descriptions-item> -->
        <el-descriptions-item label="分辨率">{{dialogDetailData.widthInPixel}} x {{dialogDetailData.heightInPixel}}</el-descriptions-item>
        <el-descriptions-item label="播放时长(分:秒)">{{dialogDetailData.durationTxt}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.adminId}}</el-descriptions-item>
        <el-descriptions-item label="来源">{{dialogDetailData.clientTypeTxt}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.statusTxt}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.createdTime}}</el-descriptions-item>
        <el-descriptions-item label="说明">{{dialogDetailData.description}}</el-descriptions-item>
        <el-descriptions-item label="桶ID">{{dialogDetailData.fileInfo.BucketID}}</el-descriptions-item>
        <el-descriptions-item label="Key">{{dialogDetailData.fileInfo.Key}}</el-descriptions-item>
        <el-descriptions-item label="MD5码">{{dialogDetailData.fileInfo.md5}}</el-descriptions-item>
        <el-descriptions-item label="音视频类型">{{dialogDetailData.fileInfo.avType}}</el-descriptions-item>
        <el-descriptions-item label="音视频编码规格">{{dialogDetailData.fileInfo.codec}}</el-descriptions-item>
        <el-descriptions-item label="视频容器类型">{{dialogDetailData.fileInfo.container}}</el-descriptions-item>
        <el-descriptions-item label="音视频码率">{{dialogDetailData.fileInfo.bitRateInBps}}</el-descriptions-item>
        <el-descriptions-item label="视频帧率">{{dialogDetailData.fileInfo.frameRate}}</el-descriptions-item>
        <el-descriptions-item label="音频声道数">{{dialogDetailData.fileInfo.channels}}</el-descriptions-item>
        <el-descriptions-item label="音频采用率">{{dialogDetailData.fileInfo.sampleRateInHz}}</el-descriptions-item>
        <el-descriptions-item label="AdminID">{{dialogDetailData.adminId}}</el-descriptions-item>
        <el-descriptions-item label="OemID">{{dialogDetailData.oemId}}</el-descriptions-item>
        <el-descriptions-item label="GroupID">{{dialogDetailData.groupId}}</el-descriptions-item>
        <el-descriptions-item label="文件链接">
          <el-button size="small" type="info" @click="handleCopy(dialogDetailData.mediaUrl)" plain>获取url</el-button>
          <el-button size="small" type="info" :loading="dialogDetailDownloadBtnLoading" @click="handleDownload(dialogDetailData.mediaUrl, dialogDetailData.Key)" plain>下载</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle">
      <el-form :model="dialogEditData" ref="ruleFormEdit" :rules="editRules" label-position="right" label-width="120px">
        <el-form-item label="标题" prop="title">
          <el-input v-model="dialogEditData.title" autocomplete="off" />
        </el-form-item>
        <el-form-item v-if="['html', 'image'].includes(dialogEditData.type)" label="播放时长(秒)">
          <el-input type="number" v-model="dialogEditData.duration" autocomplete="off" />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dialogEditData.description" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave('ruleFormEdit')">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogPreviewVisible" title="预览" @closed="dialogPreviewClose">
      <audio v-if="dialogPreviewData.type==='audio'"
        id="audioPlayer" controls="controls"
        :src="dialogPreviewData.url"
        autoplay="autoplay" style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Audio player not available.
      </audio>
      <video v-else-if="dialogPreviewData.type==='video'"
        id="player" controls="controls"
        autoplay="autoplay"
        :src="dialogPreviewData.url"
        style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Video player not available.
      </video>
      <el-image v-else-if="dialogPreviewData.type==='picture'"
        :src="dialogPreviewData.url" fit="fill" 
        :width="dialogPreviewData.widhtInPixel"
        style="max-width:100%"
        alt="无法加载" />
      <iframe v-else-if="dialogPreviewData.type==='html'"
        :src="dialogPreviewData.url" style="width: 100%;height: 500px"
        frameborder="0"></iframe>
      <embed v-else id="flash" :src="dialogPreviewData.url" style="max-width: 100%;" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogPreviewVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
  <a style="display:none" ref="downloadAlabel" :href="downloadFileLink" :download="downloadFileName" target="_blank" />
</template>

<script>
import FileSaver from 'file-saver'
import axios from 'axios'
export default {
  name: 'PlatformmediaView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'created',
          label: '创建',
        },
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'disabled',
          label: '禁用',
        }
      ],
      typeOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'none',
          label: '未知',
        },
        {
          value: 'image',
          label: '图片',
        },
        {
          value: 'video',
          label: '视频',
        },
        {
          value: 'audio',
          label: '音频',
        },
        {
          value: 'html',
          label: 'HTML',
        },
        {
          value: 'text',
          label: '文本',
        },
        {
          value: 'zip',
          label: 'ZIP',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'admin_id',
          label: 'AdminID',
        },
        {
          value: 'title',
          label: '标题',
        }
      ],
      uploadUrl: `/`,
      upLoadBtndisabled: false,
      upLoadHeaders: {Authorization: `Bearer ${window.localStorage.getItem('token')}`},
      filterData: {type: '', status: '', conditionType: 'admin_id', conditionValue: ''},
      tableData: [],
      tableDefaultSort: {prop:'id',order: 'ascending'},
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogDetailDownloadBtnLoading: false,
      downloadFileLink: '',
      downloadFileName: '',
      dialogEditVisible: false,
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogEditData: {},
      editRules: {
        title: [
          { required: true, message: '请输显示名', trigger: 'blur' }
        ]
      },
      dialogPreviewVisible: false,
      dialogPreviewData: {}
    }
  },
  methods: {
    list() {
      const url = this.$apis.Platformmedia.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'admin_id', conditionValue: ''}
    },
    beforeUpload() {
      return false
    },
    changeUpload(file) {
      this.upLoadBtndisabled = true
      this.$showLoading()
      const {name, size} = file
      const url = this.$apis.Platformmedia.add
      this.$httpPost(url, {file: name, size}).then(resp => {
        const {id, accessid, callback, host, key, policy, signature} = resp
        const formData = new FormData() ;
        formData.append('name', name) ;
        formData.append('key',key) ;
        formData.append('policy', policy) ;
        formData.append('OSSAccessKeyId', accessid) ;
        formData.append('success_action_status', 201) ;
        formData.append('callback', callback) ;
        formData.append('signature', signature) ;
        formData.append('file', file.raw) ;
        let config = {
            headers:{'Content-Type':'multipart/form-data'}
        };
        axios({
          url : host ,
          method : 'post' ,
          data : formData ,
          config : config ,
          onUploadProgress : progressEvent => {
            if(progressEvent.lengthComputable) {
              var percentage = Math.ceil(progressEvent.loaded / progressEvent.total * 100)
              file.percentage = percentage
            }
          }
        }).then(response => {
          const {key, size, etag, mimeType, height, width, format} = response;
          const url = this.$apis.Platformmedia.editFile;
          const params = {id, key, size, etag, mimeType, height, width, format};
          this.$httpPost(url, params).then(() => {
            this.list()
            this.upLoadBtndisabled = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '保存文件信息失败')
          }).finally(() => {
            this.$hideLoading()
          })
        }).catch(err => {
          console.log(err)
          this.$hideLoading()
        })
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.$hideLoading()
      }).finally(() => {
        //this.$hideLoading()
      })
    },
    onErrorUpLoad(err) {
      this.$message({
        showClose: true,
        message: JSON.stringify(err),
        type: 'error',
        duration: 0
      })
    },
    tableEdit(row) {
      this.dialogEditTitle = `编辑ID:${row.id}`
      const url = this.$apis.Platformmedia.detail
      const params = {id: row.id}
      row.tableEditBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogEditVisible = true
        this.dialogEditData = res
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableEditBtnLoading = false
      })
    },
    tablePreview(row) {
      this.dialogPreviewTitle = `预览ID:${row.id}`
      const url = this.$apis.Platformmedia.preview
      const params = {id: row.id}
      row.tablePreviewBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogPreviewData = res
        this.dialogPreviewVisible = true
        console.log(res)
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tablePreviewBtnLoading = false
      })
    },
    dialogPreviewClose() {
      const {url, type} = this.dialogPreviewData
      if (url) {
        if ('audio' === type) {
          document.getElementById('audioPlayer').pause()
        }
        if ('video' === type) {
          document.getElementById('player').pause()
        }
      }
    },
    tableStatusDisabled(row) {
      this.dialogEditData = row;
      this.$confirm('确认将状态改为禁用？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '放弃'
      }).then(() => {
        this.actionDisabledSave()
      }).catch((e) => {console.log(e)})
    },
    tableDetail(row) {
      const url = this.$apis.Platformmedia.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableQuery(row) {
      const url = this.$apis.Platformmedia.queryStatus;
      const {id} = row
      const params = {id};
      row.tableQueryBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('查询成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '查询失败~')
      }).finally(() => {
        row.tableQueryBtnLoading = false
      })
    },
    actionDisabledSave() {
      const row = this.dialogEditData
      row.tableStatusDisabledBtnLoading = true
      const url = this.$apis.Platformmedia.setStatus
      const {id} = row
      this.$httpPost(url, {id, status: 'disabled'}).then(() => {
        this.list()
        this.$message.success('禁用成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableStatusDisabledBtnLoading = false
      })
    },
    tableStatusNormal(row) {
      row.tableStatusNormalBtnLoading = true
      const url = this.$apis.Platformmedia.setStatus
      const {id} = row
      this.$httpPost(url, {id, status: 'normal'}).then(() => {
        this.list()
        this.$message.success('解禁成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableStatusNormalBtnLoading = false
      })
    },
    actionEditSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Platformmedia.edit
          const {id, title, description, duration, type} = this.dialogEditData
          const params = {id, title, description}
          if (['html', 'image'].includes(type)) {
            Object.assign(params, {duration})
          }
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, {oper: 'edit', ...params}).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogEditVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    handleTableSort(obj) {
      const {prop, order} = obj
      Object.assign(this.filterData, {prop, order})
      this.list()
    },
    handleCopy(text) {
      const _this = this
      this.$copyText(text).then(
        function () {
          _this.$notify({
            title: "链接复制成功",
            type: "success",
          })
        },
        function () {
          _this.$notify({
            title: "链接复制失败",
            type: "error",
          })
        }
      );
    },
    handleDownload(link, fileName) {
      this.dialogDetailDownloadBtnLoading = true
      FileSaver.saveAs(link, fileName)
      // this.downloadFileName = fileName
      // this.downloadFileLink = link
      // this.$refs.downloadAlabel.dispatchEvent(new MouseEvent('click'));   
      this.dialogDetailDownloadBtnLoading = false
      // const image = new Image()
      // image.setAttribute("crossOringin", "anonymous")
      // image.onload = function() {
      //   var canvas = document.createElement("canvas")
      //   canvas.width = image.width
      //   canvas.height = image.height
      //   var context = canvas.getContext("2d")
      //   context.drawImage(image, 0, 0, image.width, image.height)
      //   var url = canvas.toDataURL()
      //   var a = document.createElement("a")
      //   var event = new MouseEvent("click")
      //   a.download = fileName
      //   a.href = url
      //   a.dispatchEvent(event)
      // }
      // image.src = link
    }
  },
  created() {
    Object.assign(this.filterData, this.tableDefaultSort)
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
.el-button--small {
  padding-left: 5px;
  padding-right: 5px;
}
.el-button+.el-button {
  margin-left: 5px;
}
</style>