<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="可开发票状态">
            <el-select v-model="filterData.invoicedStatus" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in invoicedStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
        <el-button type="primary" @click="toolsAdd" :loading="addBtnLoading" size="small">新增</el-button>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="user_id" label="用户ID" />
        <el-table-column prop="amount" label="金额" />
        <el-table-column prop="product_id" label="产品编号"/>
        <el-table-column prop="quantity" label="购买数量"/>
        <el-table-column prop="price" label="单价"/>
        <el-table-column prop="trade_platform" label="支付平台"/>
        <el-table-column prop="notes" label="备注" show-overflow-tooltip/>
        <el-table-column prop="status_txt" label="状态"/>
        <el-table-column prop="invoiced_status_txt" label="可开发票状态">
          <template #default="scope">
            <span v-if="scope.row.invoiced_status=='none'" class="f_color_red">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='partial'" class="f_color_orange">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='invoicing'" class="f_color_orange">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='invoiced'" class="f_color_green">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='overdue'" class="f_color_violet">{{scope.row.invoiced_status_txt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.invoiced_status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="paid_time" label="支付时间" width="180"/>
        <el-table-column show-overflow-tooltip prop="created_date" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" @click="tableEdit(scope.row)" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle">
      <el-form>
        <el-form-item label="备注" label-width="200">
          <el-input v-model="notes" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.user_id}}</el-descriptions-item>
        <el-descriptions-item label="金额">{{dialogDetailData.amount}}</el-descriptions-item>
        <el-descriptions-item label="产品编号">{{dialogDetailData.product_id}}</el-descriptions-item>
        <el-descriptions-item label="购买数量">{{dialogDetailData.quantity}}</el-descriptions-item>
        <el-descriptions-item label="单价">{{dialogDetailData.price}}</el-descriptions-item>
        <el-descriptions-item label="支付平台">{{dialogDetailData.trade_platform}}</el-descriptions-item>
        <el-descriptions-item label="支付子ID">{{dialogDetailData.sub_payment_id}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.status_txt}}</el-descriptions-item>
        <el-descriptions-item label="可开发票状态">{{dialogDetailData.invoiced_status_txt}}</el-descriptions-item>
        <el-descriptions-item label="支付时间">{{dialogDetailData.paid_time}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.created_time}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{dialogDetailData.notes}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogAddVisible" title="新增">
      <el-form :model="dialogAddData" ref="ruleFormAdd" :rules="addRules" label-position="right" label-width="80px">
        <el-form-item label="用户ID" prop="userId">
          <el-input v-model="dialogAddData.userId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="产品编号" prop="productId">
          <el-input v-model="dialogAddData.productId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="购买数量" prop="quantity">
          <el-input type="number" min=1 v-model="dialogAddData.quantity" autocomplete="off" @change="autoAmount" />
        </el-form-item>
        <el-form-item label="单价" prop="price">
          <el-input v-model="dialogAddData.price" autocomplete="off" @change="autoAmount" />
        </el-form-item>
        <el-form-item label="金额">
          <el-input v-model="dialogAddData.amount" autocomplete="off" readonly />
        </el-form-item>
        <el-form-item label="支付平台" prop="tradePlatform">
          <el-select v-model="dialogAddData.tradePlatform" class="m-2" placeholder="Select" size="small">
            <el-option label="wepay" value="wepay"/>
            <el-option label="alipay" value="alipay"/>
            <el-option label="bank" value="bank"/>
            <el-option label="cash" value="cash"/>
            <el-option label="others" value="others"/>
          </el-select>
        </el-form-item>
        <el-form-item label="支付时间" prop="paidTime">
          <el-date-picker
            v-model="dialogAddData.paidTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="dialogAddData.status" class="m-2" placeholder="Select" size="small">
            <el-option label="-" value="none"/>
            <el-option label="已完成" value="done"/>
            <el-option label="已取消" value="cancelled"/>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dialogAddData.notes" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAddVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogAddSubmitBtnLoading" @click="actionAddSave('ruleFormAdd')">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'PaymentView',
  data: function () {
    return {
      invoicedStatusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'none',
          label: '待开具',
        },
        {
          value: 'partial',
          label: '部分开具中',
        },
        {
          value: 'invoicing',
          label: '开具中',
        },
        {
          value: 'invoiced',
          label: '已开具',
        },
        {
          value: 'overdue',
          label: '已过期',
        },
        {
          value: 'deny',
          label: '已拒绝',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'phone',
          label: '手机号',
        },
        {
          value: 'product_id',
          label: '产品编号',
        },
        {
          value: 'trade_platform',
          label: '支付平台',
        }
      ],
      filterData: {invoicedStatus: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      addBtnLoading: false,
      total: 0,
      dialogEditVisible: false,
      dialogEditId: 0,
      dialogEditTitle: '编辑ID:0',
      notes: '',
      dialogEditSubmitBtnLoading: false,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogAddVisible: false,
      dialogAddSubmitBtnLoading: false,
      dialogAddData: {},
      dialogAddDataDefault: {
        userId: '', amount: '', productId: '',
        quantity: '', price: '', tradePlatform: 'bank',
        status: 'done', paidTime: '',
        notes: ''
      },
      addRules: {
        userId: [
          { required: true, message: '请输入用户ID', trigger: 'blur' },
          { min: 1, message: '用户ID有误', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: '请输入产品编号', trigger: 'blur' },
          { min: 1, message: '产品编号有误', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '请输入购买数量', trigger: 'blur' },
          { min: 1, message: '购买数量有误', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '请输入单价', trigger: 'blur' },
          { min: 0.01, message: '单价有误', trigger: 'blur' }
        ],
        paidTime: [
          { required: true, message: '请选择支付时间', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    list() {
      const url = this.$apis.Payment.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {invoicedStatus: '', conditionType: 'user_id', conditionValue: ''}
    },
    // 新增
    toolsAdd() {
      this.dialogAddData = Object.assign({}, this.dialogAddDataDefault)
      this.dialogAddVisible = true
    },
    tableEdit(row) {
      this.notes = row.notes
      this.dialogEditId = row.id
      this.dialogEditTitle = `编辑ID:${row.id}`
      this.dialogEditVisible = true
    },
    tableDetail(row) {
      const url = this.$apis.Payment.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    actionEditSave() {
      const url = this.$apis.Payment.edit
      let params = { id: this.dialogEditId }
      const {notes} = this
      Object.assign(params, {notes})
      this.dialogEditSubmitBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogEditVisible = false
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditSubmitBtnLoading = false
      })
    },
    autoAmount() {
      const {price, quantity} = this.dialogAddData
      this.dialogAddData.amount = (price && quantity) ? Math.round(price * quantity * 100) / 100 : ''
    },
    actionAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Payment.add
          const {dialogAddData} = this
          this.dialogAddSubmitBtnLoading = true
          this.$httpPost(url, dialogAddData).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogAddVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogAddSubmitBtnLoading = false
          })
        }
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>