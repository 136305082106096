<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
        <el-button type="primary" @click="toolsAdd" :loading="addBtnLoading" size="small">新增</el-button>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="user_id" label="用户ID" />
        <el-table-column prop="payment_id" label="支付ID" />
        <el-table-column prop="product_txt" label="产品名"/>
        <el-table-column prop="total" label="点数总量"/>
        <el-table-column prop="quantity" label="剩余点数"/>
        <el-table-column prop="title" label="显示名"/>
        <el-table-column prop="status_txt" label="状态"/>
        <el-table-column show-overflow-tooltip prop="expired_time" label="过期时间" width="180"/>
        <el-table-column show-overflow-tooltip prop="created_date" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" @click="tableEdit(scope.row)" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="剩余点数">{{dialogDetailData.quantity}}</el-descriptions-item>
        <el-descriptions-item label="点数总量">{{dialogDetailData.total}}</el-descriptions-item>
        <el-descriptions-item label="产品名">{{dialogDetailData.product_txt}}</el-descriptions-item>
        <el-descriptions-item label="显示名">{{dialogDetailData.title}}</el-descriptions-item>
        <el-descriptions-item label="产品编号">{{dialogDetailData.product_id}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.status_txt}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.user_id}}</el-descriptions-item>
        <el-descriptions-item label="支付ID">{{dialogDetailData.payment_id}}</el-descriptions-item>
        <el-descriptions-item label="过期时间">{{dialogDetailData.expired_time}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.created_time}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{dialogDetailData.notes}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogAddVisible" title="新增">
      <el-form :model="dialogAddData" ref="ruleFormAdd" :rules="addRules" label-position="right" label-width="80px">
        <el-form-item label="显示名" prop="title">
          <el-input v-model="dialogAddData.title" autocomplete="off" />
        </el-form-item>
        <el-form-item label="用户ID" prop="userId">
          <el-input v-model="dialogAddData.userId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="支付ID" prop="paymentId">
          <el-input v-model="dialogAddData.paymentId" autocomplete="off" />
        </el-form-item>
        <el-form-item label="产品" prop="productId">
          <el-select v-model="dialogAddData.productId" class="m-2" placeholder="Select" size="small">
            <el-option label="一年期" value="1"/>
            <el-option label="付两年享三年" value="2"/>
            <el-option label="付三年享终身" value="3"/>
          </el-select>
        </el-form-item>
        <el-form-item label="点数总量" prop="total">
          <el-input type="number" min=1 v-model="dialogAddData.total" autocomplete="off" />
        </el-form-item>
        <el-form-item label="过期时间" prop="expiredTime">
          <el-date-picker
            v-model="dialogAddData.expiredTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dialogAddData.notes" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :loading="dialogAddSubmitBtnLoading" @click="actionAddSave('ruleFormAdd')">提交</el-button>
          <el-button @click="dialogAddVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle">
      <el-form :model="dialogEditData" ref="ruleFormEdit" :rules="editRules" label-position="right" label-width="80px">
        <el-form-item label="显示名" prop="title">
          <el-input v-model="dialogEditData.title" autocomplete="off" />
        </el-form-item>
        <el-form-item label="剩余点数" prop="quantity">
          <el-input type="number" min=0 v-model="dialogEditData.quantity" autocomplete="off" />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="dialogEditData.status" class="m-2" placeholder="Select" size="small">
            <el-option
              v-for="item in editStatusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="过期时间" prop="expiredTime">
          <el-date-picker
            v-model="dialogEditData.expiredTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            placeholder="选择日期时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="dialogEditData.notes" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave('ruleFormEdit')">提交</el-button>
          <el-button @click="dialogEditVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'DevicetokenView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'runout',
          label: '已用完',
        },
        {
          value: 'invalid',
          label: '已失效',
        },
        {
          value: 'deleted',
          label: '已删除',
        }
      ],
      editStatusOptions: [
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'runout',
          label: '已用完',
        },
        {
          value: 'invalid',
          label: '已失效',
        },
        {
          value: 'deleted',
          label: '已删除',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'payment_id',
          label: '支付ID',
        },
        {
          value: 'product_id',
          label: '产品编号',
        }
      ],
      filterData: {status: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      addBtnLoading: false,
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogAddVisible: false,
      dialogAddSubmitBtnLoading: false,
      dialogAddData: {},
      dialogAddDataDefault: {
        title: '', userId: '', total: '1', productId: '1',
        expiredTime: '', notes: ''
      },
      addRules: {
        title: [
          { required: true, message: '请输入显示名', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '请输入用户ID', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '用户ID有误', trigger: 'blur' }
        ],
        paymentId: [
          { required: true, message: '请输入支付ID', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '支付ID有误', trigger: 'blur' }
        ],
        productId: [
          { required: true, message: '请输入产品编号', trigger: 'blur' }
        ],
        total: [
          { required: true, message: '请输入点数总量', trigger: 'blur' },
          { pattern: /^[1-9]\d*$/, message: '点数总量有误', trigger: 'blur' }
        ],
        expiredTime: [
          { required: true, message: '请选择过期时间', trigger: 'change' }
        ]
      },
      dialogEditVisible: false,
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogEditData: {},
      editRules: {
        title: [
          { required: true, message: '请输显示名', trigger: 'blur' }
        ],
        quantity: [
          { required: true, message: '请输入剩余点数', trigger: 'blur' },
          { pattern: /^\d+$/, message: '点数总量有误', trigger: 'blur' }
        ],
        expiredTime: [
          { required: true, message: '请选择过期时间', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    list() {
      const url = this.$apis.Devicetoken.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'user_id', conditionValue: ''}
    },
    // 新增
    toolsAdd() {
      this.dialogAddData = Object.assign({}, this.dialogAddDataDefault)
      this.dialogAddVisible = true
    },
    tableEdit(row) {
      this.dialogEditTitle = `编辑ID:${row.id}`
      this.dialogEditVisible = true
      row.expiredTime = row.expired_time
      this.dialogEditData = row;
    },
    tableDetail(row) {
      const url = this.$apis.Devicetoken.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    actionEditSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Devicetoken.edit
          const {dialogEditData} = this
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, dialogEditData).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogEditVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    actionAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Devicetoken.add
          const {dialogAddData} = this
          this.dialogAddSubmitBtnLoading = true
          this.$httpPost(url, dialogAddData).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogAddVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogAddSubmitBtnLoading = false
          })
        }
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>