<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="类型">
            <el-select v-model="filterData.type" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="转换状态">
            <el-select v-model="filterData.transcodeStatus" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in transcodeStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData" @sort-change="handleTableSort"
      sortable="custom" :default-sort="tableDefaultSort"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" sortable :sort-orders="['ascending', 'descending']"/>
        <el-table-column label="图标" width="60">
          <template #default="scope">
            <img :src="scope.row.icon" width="25" />
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="title" label="标题"/>
        <el-table-column prop="typeTxt" label="类型">
          <template #default="scope">
            <span v-if="scope.row.type=='picture' || scope.row.type=='image'" class="f_color_blue">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='video'" class="f_color_violet">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='audio'" class="f_color_orange">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='html'" class="f_color_green">{{scope.row.typeTxt}}</span>
            <span v-else-if="scope.row.type=='flash'" class="f_color_red">{{scope.row.typeTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.typeTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="size" label="大小(M)" sortable :sort-orders="['ascending', 'descending']"/>
        <el-table-column label="分辨率">
          <template #default="scope">
            {{scope.row.widthInPixel}} x {{scope.row.heightInPixel}}
          </template>
        </el-table-column>
        <el-table-column prop="durationTxt" label="播放时长(分:秒)"/>
        <el-table-column prop="adminId" label="用户ID"/>">
        <el-table-column prop="clientTypeTxt" label="来源"/>
        <el-table-column prop="transcodeStatusTxt" label="转换状态">
          <template #default="scope">
            <span v-if="scope.row.transcodeStatus=='transcoding'" class="f_color_orange">{{scope.row.transcodeStatusTxt}}</span>
            <span v-else-if="scope.row.transcodeStatus=='transcoded'" class="f_color_green">{{scope.row.transcodeStatusTxt}}</span>
            <span v-else>{{scope.row.transcodeStatusTxt}}</span>
            <br v-if="scope.row.transcodeStatus=='transcoding'" />
            <el-button size="small" type="warning" v-if="scope.row.transcodeStatus=='transcoding'" :loading="!!scope.row.tableQueryBtnLoading" @click="tableQuery(scope.row)" plain>查询</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="statusTxt" label="状态">
          <template #default="scope">
            <span v-if="scope.row.status=='created'" class="f_color_blue">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='uploaded'" class="f_color_cyan">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='abandoned'" class="f_color_orange">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='hidden'" class="f_color_violet">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deleted" width="60" label="是否删除"/>
        <el-table-column prop="createdDate" width="100" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="170">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning"
              v-if="scope.row.transcodeStatus!=='transcoding' && scope.row.status!=='filegone'"
              :loading="!!scope.row.tablePreviewBtnLoading"
              @click="tablePreview(scope.row)" plain>预览</el-button>
            <el-popover trigger="hover" hide-after="80">
              <template #reference>
                <el-button size="small" type="primary" :loading="!!scope.row.tableParseBtnLoading" plain>更多..</el-button>
              </template>
              <template #default>
                <div style="text-align: center">
                  <el-button size="small" type="warning"
                    v-if="scope.row.transcodeStatus==='unnecessary' && ['audio', 'video'].includes(scope.row.type)"
                    @click="tableTranscode(scope.row)" plain>转换</el-button>
                  <el-button size="small" type="danger" v-if="scope.row.status!=='abandoned'" @click="tableDel(scope.row)" plain>删除</el-button>
                  <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableParseBtnLoading" @click="tableParse(scope.row)" plain>解析</el-button>
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="标题">{{dialogDetailData.title}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{dialogDetailData.typeTxt}}</el-descriptions-item>
        <el-descriptions-item label="文件名">{{dialogDetailData.fileName}}</el-descriptions-item>
        <el-descriptions-item label="大小(M)">{{dialogDetailData.size}}</el-descriptions-item>
        <el-descriptions-item label="图标">
          <img :src="dialogDetailData.icon" />
        </el-descriptions-item>
        <el-descriptions-item label="缩略图">
          <img :src="dialogDetailData.thumbnail" />
        </el-descriptions-item>
        <el-descriptions-item label="分辨率">{{dialogDetailData.widthInPixel}} x {{dialogDetailData.heightInPixel}}</el-descriptions-item>
        <el-descriptions-item label="播放时长(分:秒)">{{dialogDetailData.durationTxt}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.adminId}}</el-descriptions-item>
        <el-descriptions-item label="来源">{{dialogDetailData.clientTypeTxt}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.statusTxt}}</el-descriptions-item>
        <el-descriptions-item label="转换状态">{{dialogDetailData.transcodeStatusTxt}}</el-descriptions-item>
        <el-descriptions-item label="是否删除">{{dialogDetailData.deleted}}</el-descriptions-item>
        <el-descriptions-item label="变更时间">{{dialogDetailData.time}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.createdTime}}</el-descriptions-item>
        <el-descriptions-item label="说明">{{dialogDetailData.description}}</el-descriptions-item>
        <el-descriptions-item label="桶ID">{{dialogDetailData.bucketId}}</el-descriptions-item>
        <el-descriptions-item label="Key">{{dialogDetailData.key}}</el-descriptions-item>
        <el-descriptions-item label="MD5码">{{dialogDetailData.md5}}</el-descriptions-item>
        <el-descriptions-item label="游戏配置ID">{{dialogDetailData.gameConfigId}}</el-descriptions-item>
        <el-descriptions-item label="网页ID">{{dialogDetailData.stuffHtmlId}}</el-descriptions-item>
        <el-descriptions-item label="CloudURL">{{dialogDetailData.cloudUrl}}</el-descriptions-item>
        <el-descriptions-item label="音视频类型">{{dialogDetailData.avType}}</el-descriptions-item>
        <el-descriptions-item label="音视频编码规格">{{dialogDetailData.codec}}</el-descriptions-item>
        <el-descriptions-item label="视频容器类型">{{dialogDetailData.container}}</el-descriptions-item>
        <el-descriptions-item label="画面比例">{{dialogDetailData.displayAspectRatio}}</el-descriptions-item>
        <el-descriptions-item label="音视频码率">{{dialogDetailData.bitRateInBps}}</el-descriptions-item>
        <el-descriptions-item label="视频帧率">{{dialogDetailData.frameRate}}</el-descriptions-item>
        <el-descriptions-item label="音频声道数">{{dialogDetailData.channels}}</el-descriptions-item>
        <el-descriptions-item label="音频采用率">{{dialogDetailData.sampleRateInHz}}</el-descriptions-item>
        <el-descriptions-item label="网页是否可编辑">{{dialogDetailData.editable}}</el-descriptions-item>
        <el-descriptions-item label="Landscape">{{dialogDetailData.landscape}}</el-descriptions-item>
        <el-descriptions-item label="缩略图Path">{{dialogDetailData.thumbnailPath}}</el-descriptions-item>
        <el-descriptions-item label="UserId">{{dialogDetailData.userId}}</el-descriptions-item>
        <el-descriptions-item label="OemID">{{dialogDetailData.oemId}}</el-descriptions-item>
        <el-descriptions-item label="GroupID">{{dialogDetailData.groupId}}</el-descriptions-item>
        <el-descriptions-item label="有效期开始">{{dialogDetailData.lifeTimeOn}}</el-descriptions-item>
        <el-descriptions-item label="有效期结束">{{dialogDetailData.lifeTimeOff}}</el-descriptions-item>
        <el-descriptions-item label="文件链接">
          <el-button size="small" type="info" @click="handleCopy(dialogDetailData.mediaUrl)" plain>获取url</el-button>
          <el-button size="small" type="info" :loading="dialogDetailDownloadBtnLoading" @click="handleDownload(dialogDetailData.mediaUrl, dialogDetailData.fileName)" plain>下载</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog width="320px" v-model="dialogTranscodeVisible" :title="dialogTranscodeTitle">
      <el-form label-position="right" label-width="120px">
        <el-form-item v-if="dialogEditData.type=='video'" label="转换格式">
          <el-select v-model="dialogTranscodeData.videoType" class="m-2" placeholder="Select" size="small">
            <el-option label="无" value="none" />
            <el-option label="MP4" value="mp4" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dialogEditData.type=='video'" label="分辨率">
          <el-select v-model="dialogTranscodeData.resize" class="m-2" placeholder="Select" size="small">
            <el-option label="无" value="" />
            <el-option label="720" value="720" />
            <el-option label="2K" value="2K" />
            <el-option label="4K" value="4K" />
            <el-option label="8K" value="8K" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dialogEditData.type=='video'" label="旋转角度(顺时钟)">
          <el-select v-model="dialogTranscodeData.rotate" class="m-2" placeholder="Select" size="small">
            <el-option label="0" value="0" />
            <el-option label="90" value="90" />
            <el-option label="180" value="180" />
            <el-option label="270" value="270" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dialogEditData.type=='audio'" label="转换格式">
          <el-select v-model="dialogTranscodeData.audioType" class="m-2" placeholder="Select" size="small">
            <el-option label="无" value="none" />
            <el-option label="MP3" value="mp3" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogTranscodeSubmitBtnLoading" @click="actionTranscode()">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogPreviewVisible" :title="dialogPreviewTitle" @closed="dialogPreviewClose">
      <audio v-if="dialogPreviewData.type==='audio'"
        id="audioPlayer" controls="controls"
        :src="dialogPreviewData.url"
        autoplay="autoplay" style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Audio player not available.
      </audio>
      <video v-else-if="dialogPreviewData.type==='video'"
        id="player" controls="controls"
        autoplay="autoplay"
        :src="dialogPreviewData.url"
        style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Video player not available.
      </video>
      <el-image v-else-if="dialogPreviewData.type==='picture'"
        :src="dialogPreviewData.url" fit="fill" 
        :width="dialogPreviewData.widhtInPixel"
        style="max-width:100%"
        alt="无法加载" />
      <iframe v-else-if="dialogPreviewData.type==='html'"
        :src="dialogPreviewData.url" style="width: 100%;height: 500px"
        frameborder="0"></iframe>
      <embed v-else id="flash" :src="dialogPreviewData.url" />
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogPreviewVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
  <a style="display:none" ref="downloadAlabel" :href="downloadFileLink" :download="downloadFileName" target="_blank" />
</template>

<script>
import FileSaver from 'file-saver'
export default {
  name: 'MediaView',
  data: function () {
    return {
      transcodeStatusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'unnecessary',
          label: '-',
        },
        {
          value: 'transcoding',
          label: '转换中',
        },
        {
          value: 'transcoded',
          label: '已转换',
        }
      ],
      typeOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'unknown',
          label: '未知',
        },
        {
          value: 'picture',
          label: '图片',
        },
        {
          value: 'video',
          label: '视频',
        },
        {
          value: 'audio',
          label: '音频',
        },
        {
          value: 'html',
          label: 'HTML',
        },
        {
          value: 'flash',
          label: 'Flash',
        }
      ],
      conditionTypes: [
        {
          value: 'Id',
          label: 'ID',
        },
        {
          value: 'AdminID',
          label: '用户ID',
        },
        {
          value: 'Title',
          label: '标题',
        },
        {
          value: 'Size',
          label: '大小(M)',
        },
        {
          value: 'phone',
          label: '手机号',
        }
      ],
      filterData: {type: '', transcodeStatus: '', conditionType: 'AdminID', conditionValue: ''},
      tableData: [],
      tableDefaultSort: {prop:'id',order: 'descending'},
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogDetailDownloadBtnLoading: false,
      downloadFileLink: '',
      downloadFileName: '',
      dialogEditVisible: false,
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogEditData: {},
      editRules: {
        title: [
          { required: true, message: '请输显示名', trigger: 'blur' }
        ]
      },
      dialogPreviewVisible: false,
      dialogPreviewTitle: '',
      dialogPreviewData: {},
      dialogTranscodeVisible: false,
      dialogTranscodeTitle: '',
      dialogTranscodeSubmitBtnLoading: false,
      dialogTranscodeData: {},
      dialogTranscodeDefaultData: {videoType: 'none', resize: 'none', rotate: 0, audioType: 'none'},
    }
  },
  methods: {
    list() {
      const url = this.$apis.Media.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'user_id', conditionValue: ''}
    },
    tablePreview(row) {
      this.dialogPreviewTitle = `预览ID:${row.id}`
      const url = this.$apis.Media.preview
      const params = {id: row.id}
      row.tablePreviewBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogPreviewData = res
        this.dialogPreviewVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tablePreviewBtnLoading = false
      })
    },
    dialogPreviewClose() {
      const {url, type} = this.dialogPreviewData
      if (url) {
        if ('audio' === type) {
          document.getElementById('audioPlayer').pause()
        }
        if ('video' === type) {
          document.getElementById('player').pause()
        }
      }
    },
    tableDel(row) {
      this.dialogEditData = row;
      this.$confirm('确认将状态改为删除？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '放弃'
      }).then(() => {
        this.actionDelSave()
      }).catch((e) => {console.log(e)})
    },
    tableParse(row) {
      row.tableParseBtnLoading = true
      const url = this.$apis.Media.mediaParse
      const params = {id: row.id}
      this.$httpPost(url, params).then(() => {
        this.list()
        this.$message.success('操作成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '解析失败~')
      }).finally(() => {
        row.tableParseBtnLoading = false
      })
    },
    tableDetail(row) {
      const url = this.$apis.Media.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableQuery(row) {
      const url = this.$apis.Media.queryStatus;
      const {id} = row
      const params = {id};
      row.tableQueryBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('查询成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '查询失败~')
      }).finally(() => {
        row.tableQueryBtnLoading = false
      })
    },
    tableTranscode(row) {
      this.dialogEditData = row
      this.dialogTranscodeTitle = `转换：${row.id}`
      Object.assign(this.dialogTranscodeData, this.dialogTranscodeDefaultData)
      this.dialogTranscodeVisible = true
    },
    actionTranscode() {
      this.dialogTranscodeSubmitBtnLoading = true
      const url = this.$apis.Media.startTranscode
      const {id, type} = this.dialogEditData
      const {videoType, resize, rotate, audioType} = this.dialogTranscodeData
      if (type == 'video') {
        if (videoType == 'none' && resize == 'none' && rotate == 0) {
          this.dialogTranscodeSubmitBtnLoading = false
          return this.$message.error('至少配置一个转换项')
        }
      } else if (type == 'audio') {
        if (audioType == 'none') {
          this.dialogTranscodeSubmitBtnLoading = false
          return this.$message.error('请选择“转换格式”')
        }
      } else {
        this.dialogTranscodeSubmitBtnLoading = false
        return this.$message.error('媒体类型错误')
      }
      this.$httpPost(url, {id, ...this.dialogTranscodeData}).then(() => {
        this.list()
        this.$message.success('提交成功')
        this.dialogTranscodeVisible = false
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogTranscodeSubmitBtnLoading = false
      })
    },
    actionDelSave() {
      this.$showLoading()
      const url = this.$apis.Media.edit
      const {id} = this.dialogEditData
      this.$httpPost(url, {id, oper: 'del'}).then(() => {
        this.list()
        this.$message.success('删除成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.$hideLoading()
      })
    },
    actionTranscodeSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Media.edit
          const {dialogEditData} = this
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, {oper: 'edit', ...dialogEditData}).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogEditVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    handleTableSort(obj) {
      const {prop, order} = obj
      Object.assign(this.filterData, {prop, order})
      this.list()
    },
    handleCopy(text) {
      const _this = this
      this.$copyText(text).then(
        function () {
          _this.$notify({
            title: "链接复制成功",
            type: "success",
          })
        },
        function () {
          _this.$notify({
            title: "链接复制失败",
            type: "error",
          })
        }
      );
    },
    handleDownload(link, fileName) {
      this.dialogDetailDownloadBtnLoading = true
      FileSaver.saveAs(link, fileName)
      // this.downloadFileName = fileName
      // this.downloadFileLink = link
      // this.$refs.downloadAlabel.dispatchEvent(new MouseEvent('click'));   
      this.dialogDetailDownloadBtnLoading = false
      // const image = new Image()
      // image.setAttribute("crossOringin", "anonymous")
      // image.onload = function() {
      //   var canvas = document.createElement("canvas")
      //   canvas.width = image.width
      //   canvas.height = image.height
      //   var context = canvas.getContext("2d")
      //   context.drawImage(image, 0, 0, image.width, image.height)
      //   var url = canvas.toDataURL()
      //   var a = document.createElement("a")
      //   var event = new MouseEvent("click")
      //   a.download = fileName
      //   a.href = url
      //   a.dispatchEvent(event)
      // }
      // image.src = link
    }
  },
  created() {
    Object.assign(this.filterData, this.tableDefaultSort)
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
.el-button--small {
  padding-left: 5px;
  padding-right: 5px;
}
.el-button+.el-button {
  margin-left: 5px;
}
</style>