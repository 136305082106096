<template>
  <div>
    <el-container>
      <el-aside width="110px">
        <el-scrollbar>
          <div class="logo-box">
            <div class="logo">Operator</div>
          </div>
          <el-menu active-text-color="#ffd04b" background-color="#545c64"
          :default-active="defaultActive" text-color="#fff" router
          @select="jumpView">
            <el-menu-item index="/home">
              <span>首页</span>
            </el-menu-item>
            <el-menu-item index="/device">
              <span>设备管理</span>
            </el-menu-item>
            <el-menu-item index="/user">
              <span>用户管理</span>
            </el-menu-item>
            <el-menu-item index="/media">
              <span>用户媒体</span>
            </el-menu-item>
            <el-sub-menu index="paymentGroup">
              <template #title>
                <span>费用相关</span>
              </template>
              <el-menu-item index="/invoice">
                <span>发票管理</span>
              </el-menu-item>
              <el-menu-item index="/payment">
                <span>支付管理</span>
              </el-menu-item>
              <el-menu-item index="/wxpayment">
                <span>微信支付</span>
              </el-menu-item>
              <el-menu-item index="/devicetoken">
                <span>点数管理</span>
              </el-menu-item>
              <el-menu-item index="/devicepaid">
                <span>续期记录</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="windowsGroup">
              <template #title>
                <span>分屏相关</span>
              </template>
              <el-menu-item index="/userwindows">
                <span>用户分屏</span>
              </el-menu-item>
              <el-menu-item index="/platformwindows">
                <span>平台分屏</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="mediaGroup">
              <template #title>
                <span>媒体相关</span>
              </template>
              <el-menu-item index="/file">
                <span>文件管理</span>
              </el-menu-item>
              <el-menu-item index="/transcode">
                <span>转码任务</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="deviceGroup">
              <template #title>
                <span>设备相关</span>
              </template>
              <el-menu-item index="/devmedia">
                <span>设备媒体</span>
              </el-menu-item>
              <el-menu-item index="/deviceplaylistmedia">
                <span>播放列表</span>
              </el-menu-item>
              <el-menu-item index="/deviceplaylistaudio">
                <span>音频列表</span>
              </el-menu-item>
              <el-menu-item index="/deviceplaylistsubtitle">
                <span>字幕列表</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="userGroup">
              <template #title>
                <span>用户相关</span>
              </template>
              <el-menu-item index="/userdevice">
                <span>用户设备</span>
              </el-menu-item>
              <el-menu-item index="/feedback">
                <span>用户反馈</span>
              </el-menu-item>
              <el-menu-item index="/wechat">
                <span>微信留言</span>
              </el-menu-item>
              <el-menu-item index="/userlog">
                <span>用户日志</span>
              </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="platformGroup">
              <template #title>
                <span>平台相关</span>
              </template>
              <el-menu-item index="/platformmedia">
                <span>官方媒体</span>
              </el-menu-item>
              <el-menu-item index="/manager">
                <span>后台用户</span>
              </el-menu-item>
              <el-menu-item index="/task">
                <span>任务管理</span>
              </el-menu-item>
              <el-menu-item index="/logs">
                <span>日志查看</span>
              </el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-scrollbar>
      </el-aside>
      <el-container>
        <el-header>
          <div class="header">
            <div class="nav-title">{{navTitle}}</div>
            <div class="header-userinfo"><span>您好，</span>
              <el-dropdown size="small">
              <span class="el-dropdown-link">
                {{ userInfo.nickName }}
                <el-icon class="el-icon--right">
                  <arrow-down/>
                </el-icon>
              </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="logout">注销</el-dropdown-item>
                    <el-dropdown-item @click="handleResetPassword">修改密码</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>

  </div>
  <el-dialog v-model="dialogResetPasswordVisible" title="修改密码" width="400px">
    <el-form :model="dialogResetPasswordData" ref="ruleFormReset" :rules="changeRules" label-position="right" label-width="120px">
      <el-form-item label="原密码" prop="oldPassword">
        <el-input type="password" v-model="dialogResetPasswordData.oldPassword" autocomplete="off" />
      </el-form-item>
      <el-form-item label="新密码" prop="newPassword">
        <el-input type="password" v-model="dialogResetPasswordData.newPassword" autocomplete="off" />
      </el-form-item>
      <el-form-item label="重复新密码" prop="newPasswordConfirmation">
        <el-input type="password" v-model="dialogResetPasswordData.newPasswordConfirmation" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" :loading="dialogResetSubmitBtnLoading" @click="changePasswordSave('ruleFormReset')">提交</el-button>
        <el-button @click="dialogResetPasswordVisible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
const md5 = require('md5')
export default {
  name: "BaseView",
  data: function () {
    return {
      userInfo: {
        real_name: '',
      },
      defaultActive: '/home',
      navTitle: '首页',
      navTitleMap: {
        home: '首页',
        invoice: '发票管理',
        payment: '支付管理',
        wxpayment: '微信支付',
        devicetoken: '点数管理',
        devicepaid: '续期记录',
        media: '用户媒体',
        platformmedia: '官方媒体',
        file: '文件管理',
        transcode: '转码任务',
        devmedia: '设备媒体',
        logs: '日志查看',
        user: '用户管理',
        manager: '后台用户',
        task: '任务管理',
        device: '设备管理',
        userdevice: '用户设备',
        deviceplaylistmedia: '播放列表',
        deviceplaylistaudio: '音频列表',
        deviceplaylistsubtitle: '字幕列表',
        userwindows: '用户分屏',
        platformwindows: '平台分屏',
        feedback: '用户反馈',
        wechat: '微信留言',
        userlog: '用户日志',
      },
      dialogResetPasswordVisible: false,
      dialogResetSubmitBtnLoading: false,
      dialogResetPasswordData: {oldPassword: '', newPassword: '', newPasswordConfirmation: ''},
      changeRules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        newPasswordConfirmation: [
          { required: true, message: '请输入重复新密码', trigger: 'blur' },
          { validator: this.validatePass, trigger: 'blur' }
        ]
      },
    }
  },
  created() {
    this.defaultActive = this.$route.meta.activeMenu
    this.jumpView(this.$route.path)
    this.userInfo = this.$getJson('userInfo')
  },
  methods: {
    logout() {
      this.$removeItem('token')
      this.$router.push({name: 'login'})
    },
    jumpView(k) {
      this.navTitle = this.navTitleMap[k.substr(1)]
    },
    validatePass(rule, value, callback) {
      if (value !== this.dialogResetPasswordData.newPassword) {
        callback(new Error('新密码两次输入密码不一致!'))
      } else {
        callback()
      }
    },
    handleResetPassword() {
      const {dialogResetPasswordData} = this
      Object.assign(dialogResetPasswordData, {oldPassword: '', newPassword: '', newPasswordConfirmation: ''})
      this.dialogResetPasswordVisible = true
    },
    changePasswordSave(formName) {
      const url = this.$apis.Manager.changePassword
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const {newPassword, oldPassword} = this.dialogResetPasswordData
          const params = {oldPassword: md5(oldPassword), newPassword: md5(newPassword)}
          this.dialogResetSubmitBtnLoading = true
          this.$httpPost(url, params).then(() => {
            this.$message.success('操作成功')
            this.dialogAddVisible = false
            this.logout()
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogResetSubmitBtnLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.el-container {
  height: 100vh;
  min-height: 600px;
  /*background-color: #484848;*/
}

.el-header {
  background-color: #484848;
  /*color: var(--el-text-color-primary)*/
  /*text-align: center;*/
  line-height: 60px;
}

.el-menu {
  height: calc(100% - 60px)
}

.el-aside {
  background-color: #484848;
}

.logo {
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
  align-content: center;
  font-size: 25px;
  font-weight: bold;

  background: linear-gradient(to right, red, #ccfd48, #1dfd04);
  -webkit-background-clip: text;
  color: transparent;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-userinfo {
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-userinfo .el-dropdown {
  font-size: 10px;
  color: white;
  font-weight: bold;
}

.nav-title {
  color: #fff;
}
.logo-box {
  position: sticky;
  top: 0;
  background-color: #484848;
  z-index: 100;
}

</style>