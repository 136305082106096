<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="行为">
            <el-select v-model="filterData.action" @change="handleFilterChange" class="m-2" placeholder="Select" size="small">
              <el-option value="" label="全部"/>
              <el-option value="1" label="登录"/>
              <el-option value="10" label="修改密码"/>
              <el-option value="11" label="找回密码"/>
              <el-option value="100" label="添加管理员"/>
              <el-option value="101" label="删除管理员"/>
              <el-option value="102" label="转移管理"/>
              <el-option value="103" label="解绑管理员"/>
              <el-option value="600" label="重置设备"/>
              <el-option value="610" label="激活设备"/>
              <el-option value="700" label="转移服务期"/>
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="90px"/>
        <el-table-column prop="user_id" label="用户ID" width="100px"/>
        <el-table-column prop="actionTxt" label="行为" width="120px"/>
        <el-table-column prop="details" label="内容"/>
        <el-table-column prop="time" label="日期" width="180px"/>
        <el-table-column fixed="right" label="操作" width="70">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>查看</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" :title="dialogDetailTitle">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="用户ID">{{dialogDetailData.user_id}}</el-descriptions-item>
        <el-descriptions-item label="时间">{{dialogDetailData.time}}</el-descriptions-item>
        <el-descriptions-item label="Action">{{dialogDetailData.action}}</el-descriptions-item>
        <el-descriptions-item label="行为">{{dialogDetailData.actionTxt}}</el-descriptions-item>
        <el-descriptions-item label="内容">{{dialogDetailData.details}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'UserlogView',
  data: function () {
    return {
      conditionTypes: [
        {
          value: 'user_id',
          label: '用户ID',
        }
      ],
      filterData: {action: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailTitle: '',
      dialogDetailData: {
        id: '0'
      },
    }
  },
  methods: {
    list() {
      const url = this.$apis.Userlog.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {action: '', conditionType: 'user_id', conditionValue: ''}
    },
    tableDetail(row) {
      this.dialogDetailTitle = `详情[${row.id}]`
      this.dialogDetailData = {...row}
      this.dialogDetailVisible = true
    },
  },
  created() {
    this.list()
  }
}
</script>