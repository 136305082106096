<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="日志类型">
            <el-select v-model="filterData.type" @change="handleFilterChange" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="filterData.type==6" label="用户行为">
            <el-select v-model="filterData.action" @change="handleFilterChange" class="m-2" placeholder="Select" size="small">
              <el-option value="" label="全部"/>
              <el-option value="1" label="登录"/>
              <el-option value="10" label="修改密码"/>
              <el-option value="11" label="找回密码"/>
              <el-option value="100" label="添加管理员"/>
              <el-option value="101" label="删除管理员"/>
              <el-option value="102" label="转移管理"/>
              <el-option value="103" label="解绑管理员"/>
              <el-option value="600" label="重置设备"/>
              <el-option value="610" label="激活设备"/>
              <el-option value="700" label="转移服务期"/>
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column
          v-for="item in tableColumnOptions"
          :key="item.value"
          :label="item.label"
          :prop="item.column"
          :width="item.width"
          show-overflow-tooltip
        />
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item v-for="(value, key) in dialogDetailData"
          :key="key"
          :label="detailLabelColumnMap[key] ? detailLabelColumnMap[key] : key">{{value}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'LogsView',
  data: function () {
    return {
      tableColumnOptions: {},
      conditionTypeOptions: {},
      typeOptions: [
        {
          value: '1',
          label: '设备故障',
        },
        {
          value: '8',
          label: '设备公共错误',
        },
        {
          value: '2',
          label: '服务错误',
        },
        {
          value: '3',
          label: 'Ajax错误',
        },
        {
          value: '4',
          label: '媒体错误',
        },
        {
          value: '5',
          label: '用户错误',
        },
        {
          value: '6',
          label: '用户行为',
        },
        {
          value: '7',
          label: '回调错误',
        },
        {
          value: '9',
          label: '微信错误',
        },
        {
          value: '10',
          label: '公共错误',
        }
      ],
      conditionTypes: {
        1: [
          {
            value: 'device_id',
            label: '设备ID',
          },
          {
            value: 'error_code',
            label: '错误码',
          },
        ],
        2: [
          {
            value: 'device_id',
            label: '设备ID',
          },
          {
            value: 'error_code',
            label: '错误码',
          },
          {
            value: 'file',
            label: '文件路径',
          },
        ],
        3: [
          {
            value: 'user_id',
            label: '用户ID',
          },
          {
            value: 'error_code',
            label: '错误码',
          },
        ],
        4: [
          {
            value: 'media_id',
            label: '媒体ID',
          },
          {
            value: 'error_code',
            label: '错误码',
          },
        ],
        5: [
          {
            value: 'user_id',
            label: '用户ID',
          },
          {
            value: 'error_code',
            label: '错误码',
          },
        ],
        6: [
          {
            value: 'user_id',
            label: '用户ID',
          },
        ],
        7: [
          {
            value: 'id',
            label: 'ID',
          },
          {
            value: 'code',
            label: '错误码',
          },
          {
            value: '_file',
            label: 'File',
          },
        ],
        8: [
          {
            value: 'id',
            label: 'ID',
          },
          {
            value: 'code',
            label: '错误码',
          },
          {
            value: 'device_id',
            label: '设备ID',
          },
          {
            value: '_file',
            label: 'File',
          },
        ],
        9: [
          {
            value: 'id',
            label: 'ID',
          },
          {
            value: 'code',
            label: '错误码',
          },
          {
            value: 'wechat_id',
            label: 'wechatID',
          },
          {
            value: '_file',
            label: 'File',
          },
        ],
        10: [
          {
            value: 'id',
            label: 'ID',
          },
          {
            value: 'code',
            label: '错误码',
          },
          {
            value: '_file',
            label: 'File',
          },
        ],
      },
      tableColumns: {
        1: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '设备ID',
            column: 'device_id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'error',
            width: '80px',
          },
          {
            label: '媒体ID',
            column: 'media_id',
            width: '100px',
          },
          {
            label: '版本',
            column: 'version',
            width: '70px',
          },
          {
            label: '错误',
            column: 'words',
          },
          {
            label: '细节',
            column: 'error_info',
          },
          {
            label: '日期',
            column: 'time',
          }
        ],
        2: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '设备ID',
            column: 'device_id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '100px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        3: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '用户ID',
            column: 'user_id',
            width: '100px',
          },
          {
            label: '路径',
            column: 'path',
            width: '160px',
          },
          {
            label: '细节',
            column: 'result',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        4: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '媒体ID',
            column: 'media_id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        5: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '用户ID',
            column: 'user_id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        6: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '用户ID',
            column: 'user_id',
            width: '100px',
          },
          {
            label: '行为ID',
            column: 'action',
            width: '100px',
          },
          {
            label: '行为',
            column: 'actionTxt',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        7: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        8: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '设备ID',
            column: 'device_id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        9: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: 'wechatID',
            column: 'wechat_id',
            width: '160px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
        10: [
          {
            label: 'ID',
            column: 'id',
            width: '100px',
          },
          {
            label: '错误码',
            column: 'code',
            width: '80px',
          },
          {
            label: '文件路径',
            column: '_file',
          },
          {
            label: '行号',
            column: '_line',
            width: '80px',
          },
          {
            label: '细节',
            column: 'details',
          },
          {
            label: '日期',
            column: 'time',
          },
        ],
      },
      filterData: {type: '1', action: '', conditionType: 'device_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      }
    }
  },
  methods: {
    list() {
      const url = this.$apis.Logs.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.tableColumnOptions = this.tableColumns[this.filterData.type]
      this.list()
    },
    reset() {
      Object.assign(this.filterData, {conditionValue: '', action: ''})
    },
    tableDetail(row) {
      this.detailLabelColumnMap = {}
      this.tableColumnOptions.forEach((e) => {
        this.detailLabelColumnMap[e.column] = e.label
      })
      this.dialogDetailData = row
      this.dialogDetailVisible = true
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    handleFilterChange() {
      const {filterData, conditionTypes} = this
      this.conditionTypeOptions = conditionTypes[filterData.type]
      this.filterData.conditionType = conditionTypes[filterData.type][0].value
      this.filterData.conditionValue = ''
    },
  },
  created() {
    const {tableColumns, filterData, conditionTypes} = this
    this.tableColumnOptions = tableColumns[filterData.type]
    this.conditionTypeOptions = conditionTypes[filterData.type]
    this.list()
  }
}
</script>
