<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

String.prototype.format = function (dict) {
  let s = this
  for (let k in dict) {
    s = s.replace(new RegExp("\\{" + k + "\\}", "g"), dict[k])
  }
  return s
}


export default {
  name: 'App',

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

body, html {
  background: aliceblue;
}

#app {
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.f_color_red {
  color: red;
}
.f_color_orange {
  color: orange;
}
.f_color_yellow {
  color: yellow;
}
.f_color_green {
  color: green;
}
.f_color_cyan {
  color: cyan;
}
.f_color_blue {
  color: blue;
}
.f_color_purple {
  color: purple;
}
.f_color_darkgray {
  color: darkgray;
}
.f_color_violet {
  color: violet;
}
.f_color_coral {
  color: coral;
}
.f_color_white {
  color: white;
}
.el-button--small {
  padding-left: 5px;
  padding-right: 5px;
}
.el-button+.el-button {
  margin-left: 5px;
}
</style>
