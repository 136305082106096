<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData" @sort-change="handleTableSort"
      sortable="custom"
      :default-sort="tableDefaultSort"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" sortable :sort-orders="['ascending', 'descending']"/>
        <el-table-column prop="userId" label="用户ID" /> 
        <el-table-column prop="mediaId" label="媒体ID" />
        <el-table-column prop="mimeType" label="类型" />
        <el-table-column prop="size" label="大小(M)" sortable :sort-orders="['ascending', 'descending']"/>
        <el-table-column label="分辨率">
          <template #default="scope">
            {{scope.row.widthInPixel}} x {{scope.row.heightInPixel}}
          </template>
        </el-table-column>
        <el-table-column prop="sourceTxt" label="来源">
          <template #default="scope">
            <span v-if="scope.row.source=='transformed'" class="f_color_blue">{{scope.row.sourceTxt}}</span>
            <span v-else-if="scope.row.source=='mirror'" class="f_color_cyan">{{scope.row.sourceTxt}}</span>
            <span v-else-if="scope.row.source=='original'" class="f_color_orange">{{scope.row.sourceTxt}}</span>
            <span v-else-if="scope.row.source=='transcoded'" class="f_color_green">{{scope.row.sourceTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.sourceTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="statusTxt" label="状态">
          <template #default="scope">
            <span v-if="scope.row.status=='created'" class="f_color_orange">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="resize" label="尺寸"/>
        <el-table-column prop="transcodeTxt" label="转码"/>
        <el-table-column show-overflow-tooltip prop="createdDate" label="创建日期"/>
        <el-table-column fixed="right" label="操作" width="170">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" v-if="scope.row.source!='original'" :loading="!!scope.row.tableQueryBtnLoading" @click="tableQuery(scope.row)" plain>查询</el-button>
            <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableParseBtnLoading" @click="tableParse(scope.row)" plain>解析</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.userId}}</el-descriptions-item>
        <el-descriptions-item label="媒体ID">{{dialogDetailData.mediaId}}</el-descriptions-item>
        <el-descriptions-item label="Key">{{dialogDetailData.key}}</el-descriptions-item>
        <el-descriptions-item label="大小(M)">{{dialogDetailData.size}}</el-descriptions-item>
        <el-descriptions-item label="分辨率">{{dialogDetailData.widthInPixel}} x {{dialogDetailData.heightInPixel}}</el-descriptions-item>
        <el-descriptions-item label="来源">{{dialogDetailData.sourceTxt}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.statusTxt}}</el-descriptions-item>
        <el-descriptions-item label="尺寸">{{dialogDetailData.resize}}</el-descriptions-item>
        <el-descriptions-item label="转码">{{dialogDetailData.transcodeTxt}}</el-descriptions-item>
        <el-descriptions-item label="桶ID">{{dialogDetailData.bucketId}}</el-descriptions-item>
        <el-descriptions-item label="MD5">{{dialogDetailData.md5}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{dialogDetailData.mimeType}}</el-descriptions-item>
        <el-descriptions-item label="音视频类型">{{dialogDetailData.avType}}</el-descriptions-item>
        <el-descriptions-item label="音视频编码规格">{{dialogDetailData.codec}}</el-descriptions-item>
        <el-descriptions-item label="视频容器类型">{{dialogDetailData.container}}</el-descriptions-item>
        <el-descriptions-item label="音视频码率">{{dialogDetailData.bitRateInBps}}</el-descriptions-item>
        <el-descriptions-item label="视频帧率">{{dialogDetailData.frameRate}}</el-descriptions-item>
        <el-descriptions-item label="音频声道数">{{dialogDetailData.channels}}</el-descriptions-item>
        <el-descriptions-item label="音频采用率">{{dialogDetailData.sampleRateInHz}}</el-descriptions-item>
        <el-descriptions-item label="旋转">{{dialogDetailData.rotate}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.createdTime}}</el-descriptions-item>
        <el-descriptions-item label="文件链接">
          <el-button size="small" type="info" @click="handleCopy(dialogDetailData.fileUrl)" plain>获取url</el-button>
          <el-button size="small" type="info" :loading="dialogDetailDownloadBtnLoading" @click="handleDownload(dialogDetailData.fileUrl, dialogDetailData.key)" plain>下载</el-button>
        </el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import FileSaver from 'file-saver'
export default {
  name: 'FileView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'abandoned',
          label: '删除',
        },
        {
          value: 'created',
          label: '创建',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'MediaID ',
          label: '媒体ID',
        },
        {
          value: 'resize',
          label: '尺寸',
        },
        {
          value: 'Size',
          label: '大小(M)',
        }
      ],
      tableDefaultSort: {prop:'id',order: 'descending'},
      filterData: {status: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogDetailDownloadBtnLoading: false
    }
  },
  methods: {
    list() {
      const url = this.$apis.File.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'user_id', conditionValue: ''}
    },
    tableDetail(row) {
      const url = this.$apis.File.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableQuery(row) {
      const url = this.$apis.File.queryStatus;
      const {id} = row
      const params = {id};
      row.tableQueryBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('查询成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '查询失败~')
      }).finally(() => {
        row.tableQueryBtnLoading = false
      })
    },
    tableParse(row) {
      row.tableParseBtnLoading = true
      const url = this.$apis.Media.fileParse
      const params = {id: row.id}
      this.$httpPost(url, params).then(() => {
        this.list()
        this.$message.success('操作成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '解析失败~')
      }).finally(() => {
        row.tableParseBtnLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    },
    handleTableSort(obj) {
      const {prop, order} = obj
      Object.assign(this.filterData, {prop, order})
      this.list()
    },
    handleCopy(text) {
      const _this = this
      this.$copyText(text).then(
        function () {
          _this.$notify({
            title: "链接复制成功",
            type: "success",
          })
        },
        function () {
          _this.$notify({
            title: "链接复制失败",
            type: "error",
          })
        }
      );
    },
    handleDownload(link, fileName) {
      this.dialogDetailDownloadBtnLoading = true
      FileSaver.saveAs(link, fileName)
      this.dialogDetailDownloadBtnLoading = false
    }
  },
  created() {
    Object.assign(this.filterData, this.tableDefaultSort)
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>