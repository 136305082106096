function getItem(key) {
    return window.localStorage.getItem(key)
}

function setItem(key, value) {
    window.localStorage.setItem(key, value)
}

function removeItem(key) {
    window.localStorage.removeItem(key)
}

function getJson(key) {
    return JSON.parse(window.localStorage.getItem(key))
}

function setJson(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}

export default {
    getItem,
    setItem,
    removeItem,
    getJson,
    setJson
}