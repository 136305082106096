import {createApp} from 'vue'

import ElementPlus from 'element-plus'
import VueClipboard from 'vue-clipboard2'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'
import * as Icons from '@element-plus/icons-vue'

import App from './App'
import router from './router'
import store from "@/services/store";
import apis from "@/services/apis";
import http from "@/services/http";
import tools from "@/services/tools";
import config from "@/config/config";

import '@/assets/custom.css'

const app = createApp(App)
app.use(router)
app.use(ElementPlus, {
    locale: zhCn,
})
app.use(VueClipboard)

// icons
Object.keys(Icons).forEach(key => {
    app.component(key, Icons[key])
})

// store
app.config.globalProperties.$getItem = store.getItem;
app.config.globalProperties.$setItem = store.setItem;
app.config.globalProperties.$removeItem = store.removeItem;
app.config.globalProperties.$getJson = store.getJson;
app.config.globalProperties.$setJson = store.setJson;
// api
app.config.globalProperties.$apis = apis;
// http
app.config.globalProperties.$host = config.host;
app.config.globalProperties.$httpGet = http.get;
app.config.globalProperties.$httpPost = http.post;
app.config.globalProperties.$httpPatch = http.patch;
app.config.globalProperties.$httpDelete = http.del;
// tools
app.config.globalProperties.$showLoading = tools.showLoading;
app.config.globalProperties.$hideLoading = tools.hideLoading;
app.config.globalProperties.$showTargetLoading = tools.showTargetLoading;
app.config.globalProperties.$hideTargetLoading = tools.hideTargetLoading;
app.config.globalProperties.$uuid = tools.uuid;

app.mount('#app')
