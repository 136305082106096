import { ElLoading } from 'element-plus'
const loadingKey = 'LOADING_VIEW'
const targetLoadingKey = 'LOADING_TARGET_VIEW'

function showLoading(text = 'Loading...') {
  window[loadingKey] = ElLoading.service({
    lock: true,
    text,
    background: 'rgba(0, 0, 0, 0.7)',
  })
}

function hideLoading() {
  const loadingView = window[loadingKey]
  if (loadingView) {
    loadingView.close()
  }
}

function showTargetLoading(targetdq, text = 'Loading...') {
  window[targetLoadingKey] = ElLoading.service({
    lock: true,
    text,
    background: 'rgba(0, 0, 0, 0.7)',
    target: document.querySelector(targetdq)
  })
}

function hideTargetLoading() {
  const loadingView = window[targetLoadingKey]
  if (loadingView) {
    loadingView.close()
  }
}

function uuid(len, radix) {
  var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  var uuid = [], i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
  } else {
    var r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
    uuid[14] = '4';
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | Math.random() * 16;
        uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join('');
}

export default {
  showLoading,
  hideLoading,
  uuid,
  showTargetLoading,
  hideTargetLoading
}
