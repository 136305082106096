<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="user_id" label="用户ID" />
        <el-table-column prop="target_device_id" label="目标设备" />
        <el-table-column prop="product_id" label="产品编号"/>
        <el-table-column prop="product" label="产品名"/>
        <el-table-column prop="amount" label="支付金额"/>
        <el-table-column prop="fee_paid" label="实际支付金额"/>
        <el-table-column show-overflow-tooltip prop="wx_result" label="微信返回"/>
        <el-table-column show-overflow-tooltip prop="err_code" label="错误码"/>
        <el-table-column prop="invoice_status_txt" label="发票状态">
          <template #default="scope">
            <span v-if="scope.row.invoiced_status=='none'" class="f_color_red">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='partial'" class="f_color_orange">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='invoicing'" class="f_color_orange">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='invoiced'" class="f_color_green">{{scope.row.invoiced_status_txt}}</span>
            <span v-else-if="scope.row.invoiced_status=='overdue'" class="f_color_violet">{{scope.row.invoiced_status_txt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.invoiced_status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="status_txt" label="状态">
          <template #default="scope">
            <span v-if="scope.row.status=='created'">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='submitted'">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='paid'" class="f_color_green">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='failed'">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.status=='refunded'" class="f_color_violet">{{scope.row.status_txt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="paid_time" label="支付时间" width="180"/>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" v-if="scope.row.status!='created'" :loading="!!scope.row.tableRecheckBtnLoading" @click="tableEdit(scope.row)" plain>查询</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.user_id}}</el-descriptions-item>
        <el-descriptions-item label="微信OpenID">{{dialogDetailData.wx_openid}}</el-descriptions-item>
        <el-descriptions-item label="目标设备ID">{{dialogDetailData.target_device_id}}</el-descriptions-item>
        <el-descriptions-item label="支付交易ID">{{dialogDetailData.transaction_id}}</el-descriptions-item>
        <el-descriptions-item label="交易号">{{dialogDetailData.trade_no}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.created_time}}</el-descriptions-item>
        <el-descriptions-item label="提交时间">{{dialogDetailData.submit_time}}</el-descriptions-item>
        <el-descriptions-item label="支付金额">{{dialogDetailData.amount}}</el-descriptions-item>
        <el-descriptions-item label="实际支付金额">{{dialogDetailData.fee_paid}}</el-descriptions-item>
        <el-descriptions-item label="支付成功时间">{{dialogDetailData.paid_time}}</el-descriptions-item>
        <el-descriptions-item label="产品名">{{dialogDetailData.product}}</el-descriptions-item>
        <el-descriptions-item label="产品编号">{{dialogDetailData.product_id}}</el-descriptions-item>
        <el-descriptions-item label="优惠金额">{{dialogDetailData.discount}}</el-descriptions-item>
        <el-descriptions-item label="优惠说明">{{dialogDetailData.discount_info}}</el-descriptions-item>
        <el-descriptions-item label="支付回调信息">{{dialogDetailData.passback_param}}</el-descriptions-item>
        <el-descriptions-item label="js端调用返回">{{dialogDetailData.wx_result}}</el-descriptions-item>
        <el-descriptions-item label="交易状态描述">{{dialogDetailData.trade_state_desc}}</el-descriptions-item>
        <el-descriptions-item label="返回错误码">{{dialogDetailData.err_code}}</el-descriptions-item>
        <el-descriptions-item label="返回错误描述">{{dialogDetailData.err_des}}</el-descriptions-item>
        <el-descriptions-item label="微信支付交易结果">{{dialogDetailData.trade_state}}</el-descriptions-item>
        <el-descriptions-item label="交易类型">{{dialogDetailData.trade_type}}</el-descriptions-item>
        <el-descriptions-item label="付款银行">{{dialogDetailData.bank_type}}</el-descriptions-item>
        <el-descriptions-item label="标价币种">{{dialogDetailData.fee_type}}</el-descriptions-item>
        <el-descriptions-item label="随机字符串">{{dialogDetailData.nonce_str}}</el-descriptions-item>
        <el-descriptions-item label="自定义数据">{{dialogDetailData.attach}}</el-descriptions-item>
        <el-descriptions-item label="状态">{{dialogDetailData.status_txt}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{dialogDetailData.marks}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'WxpaymentView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'created',
          label: '已创建',
        },
        {
          value: 'submitted',
          label: '已提交',
        },
        {
          value: 'paid',
          label: '已支付',
        },
        {
          value: 'failed',
          label: '已失败',
        },
        {
          value: 'refunded',
          label: '已退款',
        },
        {
          value: 'cancelled',
          label: '已取消',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'phone',
          label: '手机号',
        },
        {
          value: 'wx_openid',
          label: 'OpenID',
        },
        {
          value: 'product_id',
          label: '产品编号',
        },
        {
          value: 'target_device_id',
          label: '目标设备',
        }
      ],
      filterData: {
        page: 1, pageSize: 10,
        status: '', conditionType: 'user_id', conditionValue: ''
      },
      tableData: [],
      tableLoading: false,
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
    }
  },
  methods: {
    list() {
      const url = this.$apis.Wxpayment.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'user_id', conditionValue: ''}
    },
    tableDetail(row) {
      const url = this.$apis.Wxpayment.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableEdit(row) {
      const url = this.$apis.Wxpayment.recheck
      const {id} = row
      const params = {id};
      row.tableRecheckBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.$message.success('查询成功')
        console.log(res)
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '查询失败~')
      }).finally(() => {
        row.tableRecheckBtnLoading = false
      })
    },
    actionEditSave() {
      const url = this.$apis.Wxpayment.recheck
      const {id, marks} = this.dialogEditData
      const params = {id, marks};
      this.dialogEditSubmitBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogEditVisible = false
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditSubmitBtnLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>