<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe
      :data="tableData"
      style="width: 100%" empty-text="暂无数据~"
      v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="deviceId" label="设备ID">
          <template #default="scope">
            <span v-if="scope.row.deviceStatus=='online'" class="f_color_green">{{scope.row.deviceId}}</span>
            <span v-else>{{scope.row.deviceId}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mediaId" label="媒体ID"/>
        <el-table-column prop="fileId" label="文件ID"/>
        <el-table-column prop="sourceTxt" label="来源"/>
        <el-table-column prop="size" label="大小(M)"/>
        <el-table-column prop="typeTxt" label="类型"/>
        <el-table-column prop="times" label="下载次数"/>
        <el-table-column prop="pendCode" label="错误码"/>
        <el-table-column prop="statusTxt" label="下载状态">
          <template #default="scope">
            <span v-if="scope.row.status=='predownload'" class="f_color_blue">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='downloading'" class="f_color_orange">{{scope.row.statusTxt}}</span>
            <span v-else-if="scope.row.status=='downloaded'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else>{{scope.row.status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="progress" label="进度(%)"/>
        <el-table-column prop="del" label="等待删除"/>
        <el-table-column prop="override" label="下载时强制覆盖"/>
        <el-table-column show-overflow-tooltip prop="beginTime" label="开始时间"/>
        <el-table-column show-overflow-tooltip prop="finishTime" label="结束时间"/>
        <el-table-column show-overflow-tooltip prop="updateTime" label="变更时间"/>
        <el-table-column show-overflow-tooltip prop="weight" label="权重"/>
        <el-table-column fixed="right" label="操作" width="250">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>            
            <el-button size="small" type="warning" @click="tablePreview(scope.row)" plain>预览</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableRedownloadBtnLoading" @click="tableRedownload(scope.row)" plain>重下</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>编辑</el-button>
            <el-button size="small" type="danger" :loading="!!scope.row.tableRemoveBtnLoading" @click="tableRemove(scope.row)" plain>删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="设备ID">{{dialogDetailData.deviceId}}</el-descriptions-item>
        <el-descriptions-item label="媒体ID">{{dialogDetailData.mediaId}}</el-descriptions-item>
        <el-descriptions-item label="文件ID">{{dialogDetailData.fileId}}</el-descriptions-item>
        <el-descriptions-item label="下载状态">{{dialogDetailData.statusTxt}}</el-descriptions-item>
        <el-descriptions-item label="等待删除">{{dialogDetailData.del}}</el-descriptions-item>
        <el-descriptions-item label="下载时强制覆盖">{{dialogDetailData.override}}</el-descriptions-item>
        <el-descriptions-item label="媒体分辨率">{{dialogDetailData.resMode}}</el-descriptions-item>
        <el-descriptions-item label="媒体旋转角度">{{dialogDetailData.rotation}}</el-descriptions-item>
        <el-descriptions-item label="媒体转码格式">{{dialogDetailData.transcode}}</el-descriptions-item>
        <el-descriptions-item label="开始时间">{{dialogDetailData.beginTime}}</el-descriptions-item>
        <el-descriptions-item label="结束时间">{{dialogDetailData.finishTime}}</el-descriptions-item>
        <el-descriptions-item label="变更时间">{{dialogDetailData.updateTime}}</el-descriptions-item>
        <el-descriptions-item label="文件在设备上路径">{{dialogDetailData.pathOnDevice}}</el-descriptions-item>
        <el-descriptions-item label="进度(%)">{{dialogDetailData.progress}}</el-descriptions-item>
        <el-descriptions-item label="未下载错误码">{{dialogDetailData.pendCode}}</el-descriptions-item>
        <el-descriptions-item label="权重">{{dialogDetailData.weight}}</el-descriptions-item>
        <el-descriptions-item label="近期下载次数">{{dialogDetailData.times}}</el-descriptions-item>
        <el-descriptions-item label="媒体标题">{{dialogDetailData.title}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{dialogDetailData.typeTxt}}</el-descriptions-item>
        <el-descriptions-item label="大小(M)">{{dialogDetailData.size}}</el-descriptions-item>
        <el-descriptions-item label="播放时长(分:秒)">{{dialogDetailData.durationTxt}}</el-descriptions-item>
        <el-descriptions-item label="分辨率">{{dialogDetailData.widthInPixel}} x {{dialogDetailData.heightInPixel}}</el-descriptions-item>
        <el-descriptions-item label="来源">{{dialogDetailData.sourceTxt}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle">
      <el-form label-width="160px">
        <el-form-item label="下载时强制覆盖">
          <div class="my-4 flex items-center text-sm">
            <el-radio-group v-model="dialogEditData.override" class="ml-4">
              <el-radio label="1" size="small">是</el-radio>
              <el-radio label="0" size="small">否</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="dialogEditData.weight" type="number" min="0" />
        </el-form-item>
        <el-form-item label="近期下载次数">
          <el-input v-model="dialogEditData.times" type="number" min="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave">提交</el-button>
          <el-button size="small" @click="dialogEditVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogPreviewVisible" title="预览" @closed="dialogPreviewClose">
      <audio v-if="dialogPreviewData.type==='audio'"
        id="audioPlayer" controls="controls"
        :src="dialogPreviewData.url"
        autoplay="autoplay" style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Audio player not available.
      </audio>
      <video v-else-if="dialogPreviewData.type==='video'"
        id="player" controls="controls"
        autoplay="autoplay"
        :src="dialogPreviewData.url"
        style="max-width:100%"
        :width="dialogPreviewData.widhtInPixel">
        Video player not available.
      </video>
      <el-image v-else-if="dialogPreviewData.type==='picture'"
        :src="dialogPreviewData.url" fit="fill" 
        :width="dialogPreviewData.widhtInPixel"
        style="max-width:100%"
        alt="无法加载" />
      <iframe v-else-if="dialogPreviewData.type==='html'"
        :src="dialogPreviewData.url" style="width: 100%;height: 500px"
        frameborder="0"></iframe>
      <embed v-else id="flash" :src="dialogPreviewData.url" />
      <template #footer>
        <span class="dialog-footer">
          <el-button size="small" @click="dialogPreviewVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'DevmediaView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'predownload',
          label: '待下载',
        },
        {
          value: 'downloading',
          label: '下载中',
        },
        {
          value: 'downloaded',
          label: '已下载',
        }
      ],
      conditionTypes: [
        {
          value: 'device_id',
          label: '设备ID',
        },
        {
          value: 'media_id',
          label: '媒体ID',
        },
        {
          value: 'file_id',
          label: '文件ID',
        },
        {
          value: 'id',
          label: 'ID',
        }
      ],
      filterData: {status: '', conditionType: 'device_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogPreviewVisible: false,
      dialogPreviewData: {id: '0'},
      dialogEditVisible: false,
      dialogEditData: {},
      dialogEditTitle: '',
      dialogEditSubmitBtnLoading: false,
    }
  },
  methods: {
    list() {
      const url = this.$apis.Devmedia.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      Object.assign(this.filterData, {status: '', conditionType: 'device_id', conditionValue: ''})
    },
    tableDetail(row) {
      this.dialogDetailData = row
      this.dialogDetailVisible = true
    },
    tableEdit(row) {
      const {times, override, weight, id} = row
      this.dialogEditData = {times, override, weight, id}
      this.dialogEditTitle = `编辑ID：${id}`
      this.dialogEditVisible = true
    },
    actionEditSave() {
      this.dialogEditSubmitBtnLoading = true
      const url = this.$apis.Devmedia.edit
      const {times, override, weight, id} = this.dialogEditData
      const params = {cacheId: id, times, override, weight}
      this.$httpPost(url, params).then(() => {
          this.$message.success('保存成功~')
          this.dialogEditVisible = false
          this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '请求失败~')
      }).finally(() => {
        this.dialogEditSubmitBtnLoading = false
      })
    },
    tableRedownload(row) {
      row.tableRedownloadBtnLoading = true
      const url = this.$apis.Devmedia.reload
      const params = {cacheId: row.id, deviceId: row.deviceId}
      this.$httpPost(url, params).then(() => {
          this.$message.success('操作成功~')
          this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '请求失败~')
      }).finally(() => {
        row.tableRedownloadBtnLoading = false
      })
    },
    tableRemove(row) {
      this.$confirm('确认删除？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '放弃'
      }).then(() => {
        this.tableRemoveSave(row)
      }).catch((e) => {console.log(e)})
    },
    tableRemoveSave(row) {
      row.tableRemoveBtnLoading = true
      const url = this.$apis.Devmedia.remove
      const params = {cacheId: row.id}
      this.$httpPost(url, params).then(() => {
          this.$message.success('操作成功~')
          this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '请求失败~')
      }).finally(() => {
        row.tableRemoveBtnLoading = false
      })
    },
    tablePreview(row) {
      this.dialogPreviewTitle = `预览ID:${row.id}`
      if (!row.url) {
        this.$message.error('文件ID不存在~')
        return false
      }
      this.dialogPreviewData = row
      this.dialogPreviewVisible = true
    },
    dialogPreviewClose() {
      const {url, type} = this.dialogPreviewData
      if (url) {
        if ('audio' === type) {
          document.getElementById('audioPlayer').pause()
        }
        if ('video' === type) {
          document.getElementById('player').pause()
        }
      }
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>
