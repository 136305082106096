<template>
  欢迎访问.
</template>

<script>
export default {
  name: "Home-View"
}
</script>

<style scoped>

</style>