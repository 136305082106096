<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="开启状态">
            <el-select v-model="filterData.switch" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in switchOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="tools-btns">
        <el-button type="primary" @click="toolsAdd" size="small">新增</el-button>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" width="80px"/>
        <el-table-column prop="name" label="名称" width="120px"/>
        <el-table-column prop="route" label="路由" width="120px"/>
        <el-table-column prop="crontab_str" label="运行时间规则" width="120px"/>
        <el-table-column prop="switch" label="开关状态" width="120px">
          <template #default="scope">
            <span v-if="scope.row.switch=='1'" class="f_color_green">{{scope.row.switchTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.switchTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="statusTxt" label="状态" width="60px">
          <template #default="scope">
            <span v-if="scope.row.status=='0'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_red">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="execmemory" label="消耗内存(byte)"/>
        <el-table-column prop="exectime" label="消耗时间"/>
        <el-table-column prop="lastRundate" label="最后运行时间"/>
        <el-table-column prop="nextRundate" label="下次运行时间"/>
        <el-table-column fixed="right" label="操作" width="170">
          <template #default="scope">
            <el-button size="small" type="warning" @click="tableEdit(scope.row)" plain>编辑</el-button>
            <el-popconfirm title="确定执行该任务吗？" @confirm="tableRuntask(scope.row)">
              <template #reference>
                <el-button size="small" type="warning" :loading="scope.row.tableRuntaskBtnLoading" plain>执行</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm v-if="scope.row.switch==0" title="确定开启该任务吗？" @confirm="tableSwithOpen(scope.row)">
              <template #reference>
                <el-button size="small" type="warning" :loading="scope.row.tableSwitchBtnLoading" plain>开启</el-button>
              </template>
            </el-popconfirm>
            <el-popconfirm v-else-if="scope.row.switch==1" title="确定关闭该任务吗？" @confirm="tableSwithOpen(scope.row)">
              <template #reference>
                <el-button size="small" type="warning" :loading="scope.row.tableSwitchBtnLoading" plain>关闭</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogAddVisible" title="新增" width="400px">
      <el-form :model="dialogAddData" ref="ruleFormAdd" :rules="addRules" label-position="right" label-width="120px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="dialogAddData.name" autocomplete="off" />
        </el-form-item>
        <el-form-item label="路由" prop="route">
          <el-input v-model="dialogAddData.route" autocomplete="off" />
        </el-form-item>
        <el-form-item label="运行时间规则" prop="crontabStr">
          <el-input v-model="dialogAddData.crontabStr" autocomplete="off" />
          <el-link target="_blank" type="primary" underline="false" href="https://tool.lu/crontab">时间规则说明</el-link>
        </el-form-item>
        <el-form-item label="JSON参数" prop="params">
          <el-input v-model="dialogAddData.params" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogAddVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogAddSubmitBtnLoading" @click="handleAddSave('ruleFormAdd')">提交</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="400px">
      <el-form :model="dialogEditData" ref="ruleFormEdit" :rules="addRules" label-position="right" label-width="120px">
        <el-form-item label="账号" prop="account">
          <el-input v-model="dialogEditData.account" autocomplete="off" />
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="dialogEditData.phone" autocomplete="off" />
        </el-form-item>
        <el-form-item label="昵称" prop="nickName">
          <el-input v-model="dialogEditData.nickName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="fanID" prop="fanId">
          <el-input v-model="dialogEditData.fanId" autocomplete="off" type="number" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="handleEditSave('ruleFormEdit')">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'TaskView',
  data: function () {
    return {
      switchOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '开启',
        },
        {
          value: '0',
          label: '关闭',
        }
      ],
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '正常',
        },
        {
          value: '1',
          label: '报错',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'name',
          label: '名称',
        },
        {
          value: 'route',
          label: '路由',
        },
        {
          value: '运行时间规则',
          label: 'crontabStr',
        }
      ],
      actionsTypes: [
        {
          value: '1',
          label: '删除',
        },
        {
          value: '3',
          label: '重置密码',
        }
      ],
      filterData: {switch: '', status: '', conditionType: 'name', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogAddVisible: false,
      dialogAddSubmitBtnLoading: false,
      dialogAddData: {},
      dialogAddDataDefault: {
        name: '', route: '', params: '', crontabStr: '* * * * *',
      },
      addRules: {
        name: [
          { required: true, message: '请输入名称', trigger: 'blur' }
        ],
        route: [
          { required: true, message: '请输入路由', trigger: 'blur' },
        ],
        params: [
          { validator: this.validatorParams, message: 'JSON参数格式有误', trigger: 'blur' }
        ],
        crontabStr: [
          { required: true, message: '请输入运行时间规则', trigger: 'blur' },
          { validator: this.validatorCrontabStr, message: '运行时间规则有误', trigger: 'blur' }
        ]
      },
      dialogEditVisible: false,
      dialogEditId: 1,
      dialogEditTitle: '',
      dialogEditData: {},
      dialogEditSubmitBtnLoading: false,
      dialogAtionsTitle: '',
      actionData: {type: '1'},
      dialogActionsVisible: false,
      dialogActionSubmitBtnLoading: false,
    }
  },
  methods: {
    list() {
      const url = this.$apis.Task.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {switch: '', status: '', conditionType: 'name', conditionValue: ''}
    },
    toolsAdd() {
      this.dialogAddData = Object.assign({}, this.dialogAddDataDefault)
      this.dialogAddVisible = true
    },
    handleAddSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Task.add
          const {dialogAddData} = this
          this.dialogAddSubmitBtnLoading = true
          this.$httpPost(url, dialogAddData).then(() => {
            this.list()
            this.$message.success('操作成功')
            this.dialogAddVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogAddSubmitBtnLoading = false
          })
        }
      })
    },
    validatorParams(rule, value, callback) {
      if (value) {
        try {
          const json = JSON.parse(value)
          if (json) {
            callback()
          }
        } catch(e) {
          callback(new Error('JSON参数格式有误'))
        }
      } else {
        callback()
      }
    },
    validatorCrontabStr(rule, value, callback) {
      const msg = cronValidate(value)
      if (msg !== true) {
        callback(new Error(msg))
      } else {
        callback()
      }
    },
    tableEdit(row) {
      this.dialogEditId = row.id
      this.dialogEditTitle = `编辑ID:${row.id}`
      Object.assign(this.dialogEditData, row)
      this.dialogEditVisible = true
    },
    tableSwithOpen(row) {
      this.tableSwith(row, 1)
    },
    tableSwithClose(row) {
      this.tableSwith(row, 0)
    },
    tableSwith(row, state) {
      const url = this.$apis.Task.setSwitch
      const params = { id: row.id, switch: state}
      row.tableSwitchBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableSwitchBtnLoading = false
      })
    },
    tableRuntask(row) {
      const url = this.$apis.Task.runTask
      const params = { id: row.id}
      row.tableRuntaskBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('执行成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableRuntaskBtnLoading = false
      })
    },
    handleEditSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Task.edit
          let params = { id: this.dialogEditId }
          const {dialogEditData} = this
          Object.assign(params, dialogEditData)
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, params).then(() => {
            this.$message.success('操作成功')
            this.dialogEditVisible = false
            this.list()
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    tableDisabled(row) {
      const url = this.$apis.Task.setStatus
      const params = { id: row.id, status: 'disabled' }
      row.tableDisabledBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableDisabledBtnLoading = false
      })
    },
    tableEnabled(row) {
      const url = this.$apis.Task.setStatus
      const params = { id: row.id, status: 'normal' }
      row.tableEnabledBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.list()
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        row.tableEnabledBtnLoading = false
      })
    },
    tableActions(row) {
      this.dialogEditId = row.id
      this.dialogAtionsTitle = `操作ID:${row.id}`
      this.dialogActionsVisible = true
    },
    handleActionSave() {
      const {type} = this.actionData
      const params = { id: this.dialogEditId }
      let url = ''
      if (type == 3) { // 重置密码
        url = this.$apis.Task.resetPassword
      } else if (type == 1) { // 删除
        url = this.$apis.Task.setStatus
        Object.assign(params, {status: 'deleted'})
      }
      this.dialogActionSubmitBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogActionsVisible = false
        if (type == 1) {this.list()}
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogActionSubmitBtnLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
/*
cron表达式为秒，分，时，日，月，周，年
判断正误方法：错误的话返回错误信息，正确的话返回true
*/
function cronValidate(cronExpression) {
  //返回错误信息用
  let message = '';
  //先将cron表达式进行分割
  let cronParams = cronExpression.split(' ');
  //判断cron表达式是否具有该具有的属性长度，没有年份的长度为5，带年份的长度为6，其他情况都是错误的
  if (cronParams.length !== 6 && cronParams.length !== 5) {
      return 'cron表达式需要输入6或5位参数，请重新输入';
  } else {
    //日和周必须有一个为?，或者全为*
    if ((cronParams[2] == '?' && cronParams[4] != '?') ||
    (cronParams[4] == '?' && cronParams[2] != '?') ||
    (cronParams[2] == '*' && cronParams[4] == '*')) {
      message = checkMinutesField(cronParams[0]);
      if (message != true) {
          return message;
      }
      message = checkHoursField(cronParams[1]);
      if (message != true) {
          return message;
      }
      message = checkDayOfMonthField(cronParams[2]);
      if (message != true) {
          return message;
      }
      message = checkMonthsField(cronParams[3]);
      if (message != true) {
          return message;
      }
      message = checkDayOfWeekField(cronParams[4]);
      if (message != true) {
          return message;
      }
      if (cronParams[5]) {
        message = checkYearField(cronParams[5]);
        if (message != true) {
            return message;
        }
      }
      return true;
    }else{
      return '指定日时周必须设为不指定(?),指定周时日必须设为不指定(?)'
    }
  }
}
 
//检查秒的函数方法
// function checkSecondsField(secondsField) {
//   return checkField(secondsField, 0, 59, '秒');
// }
 
//检查分的函数方法
function checkMinutesField(minutesField) {
  return checkField(minutesField, 0, 59, '分');
}
 
//检查小时的函数方法
function checkHoursField(hoursField) {
  return checkField(hoursField, 0, 23, '时');
}
 
//检查日期的函数方法
function checkDayOfMonthField(dayOfMonthField) {
  if (dayOfMonthField == '?') {
      return true;
  }
  if (dayOfMonthField.indexOf('L') >= 0) {
      return checkFieldWithLetter(dayOfMonthField, 'L', 1, 7, '日');
  } else if ( dayOfMonthField.indexOf('W') >= 0) {
      return checkFieldWithLetter(dayOfMonthField, 'W', 1, 31, '日');
  } else if (dayOfMonthField.indexOf('C') >= 0) {
      return checkFieldWithLetter(dayOfMonthField, 'C', 1, 31, '日');
  }
  return checkField( dayOfMonthField, 1, 31, '日');
}
 
//检查月份的函数方法
function checkMonthsField(monthsField) {
  //月份简写处理
  if (monthsField != '*') {
    monthsField=monthsField.replace('JAN', '1');
    monthsField=monthsField.replace('FEB', '2');
    monthsField=monthsField.replace('MAR', '3');
    monthsField=monthsField.replace('APR', '4');
    monthsField=monthsField.replace('MAY', '5');
    monthsField=monthsField.replace('JUN', '6');
    monthsField=monthsField.replace('JUL', '7');
    monthsField=monthsField.replace('AUG', '8');
    monthsField=monthsField.replace('SEP', '9');
    monthsField=monthsField.replace('OCT', '10');
    monthsField=monthsField.replace('NOV', '11');
    monthsField=monthsField.replace('DEC', '12');
    return checkField(monthsField, 1, 12, '月份');
  }else{
    return true;
  }
}
 
//星期验证
function checkDayOfWeekField(dayOfWeekField) {
  dayOfWeekField=dayOfWeekField.replace('SUN', '1' );
  dayOfWeekField=dayOfWeekField.replace('MON', '2' );
  dayOfWeekField=dayOfWeekField.replace('TUE', '3' );
  dayOfWeekField=dayOfWeekField.replace('WED', '4' );
  dayOfWeekField=dayOfWeekField.replace('THU', '5' );
  dayOfWeekField=dayOfWeekField.replace('FRI', '6' );
  dayOfWeekField=dayOfWeekField.replace('SAT', '7' );
  if (dayOfWeekField == '?') {
    return true;
  }
  if (dayOfWeekField.indexOf('L') >= 0) {
      return checkFieldWithLetterWeek(dayOfWeekField, 'L', 1, 7, '星期');
  } else if (dayOfWeekField.indexOf('C') >= 0) {
      return checkFieldWithLetterWeek(dayOfWeekField, 'C', 1, 7, '星期');
  } else if (dayOfWeekField.indexOf('#') >= 0) {
      return checkFieldWithLetterWeek(dayOfWeekField, '#', 1, 7, '星期');
  } else {
      return checkField(dayOfWeekField, 1, 7, '星期');
  }
}
//检查年份的函数方法
function checkYearField(yearField) {
  return checkField(yearField, 1970, 2099, '年的');
}
//通用的检查值的大小范围的方法( - , / *)
function checkField(value, minimal, maximal, attribute) {
  //校验值中是否有“-”，如果有“-”的话，下标会>0
  if (value.indexOf('-') > -1 ) {
    return checkRangeAndCycle(value, minimal, maximal,attribute);
  }
  //校验值中是否有“，”，如果有“,”的话，下标会>0
  else if (value.indexOf(',') > -1) {
    return checkListField(value, minimal, maximal,attribute);
  }
  //校验值中是否有“/”，如果有“/”的话，下标会>0
  else if (value.indexOf( '/' ) > -1) {
    return checkIncrementField( value, minimal, maximal ,attribute);
  }
  //校验值是否为“*”
  else if (value == '*') {
    return true;
  }
  //校验单独的数字，英文字母，以及各种神奇的符号等...
  else {
    return checkIntValue(value, minimal, maximal,true, attribute);
  }
}
//检测是否是整数以及是否在范围内,参数：检测的值，下限，上限，是否检查端点，检查的属性
function checkIntValue(value, minimal, maximal, checkExtremity,attribute) {
  try {
      //用10进制犯法来进行整数转换
      let val = parseInt(value, 10);
      if (value == val) {
          if (checkExtremity) {
              if (val < minimal || val > maximal) {
                  return (attribute+'的参数取值范围必须在'+ minimal + '-' + maximal +'之间');
              }
              return true;
          }
          return true;
      }
      return (attribute + '的参数存在非法字符，必须为整数或允许的大写英文');
  } catch (e) {
      return (attribute + '的参数有非法字符，必须是整数~')
  }
}
//检验枚举类型的参数是否正确
function checkListField(value, minimal, maximal,attribute) {
  let message = '';
  let st = value.split(',');
  let values = new Array(st.length);
  //计算枚举的数字在数组中中出现的次数，出现一次为没有重复的。
  let count=0;
  for(let j = 0; j < st.length; j++) {
      values[j] = st[j];
  }
  //判断枚举类型的值是否重复
  for(let i = 0; i < values.length; i++) {
    //判断枚举的值是否在范围内
    message = checkIntValue(values[i], minimal, maximal, true, attribute);
    if (message!=true) {
      return message;
    }
    count = 0;
    for(let j = 0; j < values.length; j++) {
      if (values[i]==values[j])
      {
        count++;
      }
      if (count>1) {
        return (attribute + '中的参数重复');
      }
    }
  }
  let previousValue = -1;
  //判断枚举的值是否排序正确
  for (let i= 0; i < values.length; i++) {
      let currentValue = values[i];
      try {
          let val = parseInt(currentValue, 10);
          if (val < previousValue) {
              return (attribute + '的参数应该从小到大');
          } else {
              previousValue = val;
          }
      } catch (e) {
        //前面验证过了，这边的代码不可能跑到
        return ('这段提示用不到')
      }
  }
  return true;
}
//检验循环
function checkIncrementField(value, minimal, maximal, attribute) {
  if (value.split('/').length>2) {
    return (attribute + '中的参数只能有一个'/'');
  }
  let message = '';
  let start = value.substring(0, value.indexOf('/'));
  let increment = value.substring(value.indexOf('/') + 1);
  if (start != '*') {
    //检验前值是否正确
    message = checkIntValue(start, minimal, maximal, true, attribute);
    if (message != true) {
      return message;
    }
    //检验后值是否正确
    message = checkIntValue(increment, minimal, maximal, true, attribute);
    if (message != true) {
      return message;
    }
    return true;
  } else {
    //检验后值是否正确
    return checkIntValue(increment, minimal, maximal, false, attribute);
  }
}
//检验范围
function checkRangeAndCycle(params, minimal, maximal, attribute) {
  //校验“-”符号是否只有一个
  if (params.split('-').length>2) {
    return (attribute + '中的参数只能有一个'-'');
  }
  let message = '';
  let value = null;
  let cycle = null;
  //检验范围内是否有嵌套周期
  if (params.indexOf('/') > -1) {
    //校验“/”符号是否只有一个
    if (params.split('/').length>2) {
      return (attribute + '中的参数只能有一个'/'');
    }
    value = params.split('/')[0];
    cycle = params.split('/')[1];
    //判断循环的参数是否正确
    message = checkIntValue(cycle, minimal, maximal, true, attribute);
    if (message!=true) {
      return message;
    }
  }else{
    value = params;
  }
  let startValue = value.substring(0, value.indexOf( '-' ));
  let endValue = value.substring(value.indexOf( '-' ) + 1);
  //判断参数范围的第一个值是否正确
  message =checkIntValue(startValue, minimal, maximal, true, attribute);
  if (message!=true) {
    return message;
  }
  //判断参数范围的第二个值是否正确
  message = checkIntValue(endValue, minimal, maximal, true, attribute);
  if (message != true) {
    return message;
  }
  //判断参数的范围前值是否小于后值
  try {
    let startVal = parseInt(startValue, 10);
    let endVal = parseInt(endValue, 10);
    if (endVal < startVal) {
      return (attribute+'的取值范围错误，前值必须小于后值');
    }
    if ((endVal-startVal)<parseInt(cycle,10)) {
      return (attribute+'的取值范围内的循环无意义');
    }
    return true;
  } catch (e) {
    //用不到这行代码的
    return (attribute+'的参数有非法字符，必须是整数');
  }
}
//检查日中的特殊字符
function checkFieldWithLetter(value, letter, minimalBefore, maximalBefore,attribute) {
  //判断是否只有一个字母
  for(let i=0;i<value.length;i++) {
    let count = 0;
    if (value.charAt(i)==letter) {
      count++;
    }
    if (count>1) {
      return (attribute+'的值的'+letter+'字母只能有一个')
    }
  }
  //校验L
  if (letter == 'L') {
    if (value == 'LW') {
      return true;
    }
    if (value=='L') {
      return true;
    }
    if (value.endsWith('LW')&&value.length>2)
    {
      return (attribute + '中的参数，最后的LW前面不能有任何字母参数')
    }
    if (!value.endsWith('L'))
    {
      return (attribute + '中的参数，L字母后面不能有W以外的字符、数字等')
    }else{
      let num = value.substring(0,value.indexOf(letter));
      return checkIntValue(num, minimalBefore, maximalBefore, true, attribute);
    }
  }
 
  //校验W
  if (letter == 'W') {
    if (!value.endsWith('W')) {
      return (attribute + '中的参数的W必须作为结尾')
    }else{
      if (value=='W') {
        return (attribute + '中的参数的W前面必须有数字')
      }
      let num = value.substring(0,value.indexOf(letter));
      return checkIntValue(num, minimalBefore, maximalBefore, true, attribute);
    }
  }
 
  if (letter == 'C') {
    if (!value.endsWith('C')) {
      return (attribute + '中的参数的C必须作为结尾')
    }else{
      if (value=='C') {
        return (attribute + '中的参数的C前面必须有数字')
      }
      let num = value.substring(0,value.indexOf(letter));
      return checkIntValue(num, minimalBefore, maximalBefore, true, attribute);
    }
  }
}
//检查星期中的特殊字符
function checkFieldWithLetterWeek(value, letter, minimalBefore, maximalBefore,attribute) {
  //判断是否只有一个字母
  for(let i=0;i<value.length;i++) {
    let count = 0;
    if (value.charAt(i)==letter) {
      count++;
    }
    if (count>1) {
      return (attribute+'的值的'+letter+'字母只能有一个')
    }
  }
  //校验L
  if (letter == 'L') {
    if (value=='L') {
      return true;
    }
    if (!value.endsWith('L'))
    {
      return (attribute + '中的参数，L字母必须是最后一位')
    }else{
      let num = value.substring(0,value.indexOf(letter));
      return checkIntValue(num, minimalBefore, maximalBefore, true, attribute);
    }
  }
 
  if (letter == 'C') {
    if (!value.endsWith('C')) {
      return (attribute + '中的参数的C必须作为结尾')
    }else{
      if (value=='C') {
        return (attribute + '中的参数的C前面必须有数字')
      }
      let num = value.substring(0,value.indexOf(letter));
      return checkIntValue(num, minimalBefore, maximalBefore, true, attribute);
    }
  }
 
  if (letter == '#') {
    if (value=='#') {
      return (attribute + '中的#前后必须有整数');
    }
    if (value.charAt(0)==letter) {
      return (attribute + '中的#前面必须有整数')
    }
    if (value.endsWith('#')) {
      return (attribute + '中的#后面必须有整数')
    }
    let message = '';
    let num1 = value.substring(0,value.indexOf(letter));
    let num2 = value.substring(value.indexOf(letter)+1,value.length)
    message = checkIntValue(num1, 1, 4, true, (attribute+'的#前面'));
    if (message!=true) {
      return message;
    }
    message = checkIntValue(num2, minimalBefore, maximalBefore, true, (attribute+'的#后面'));
    if (message!=true) {
      return message;
    }
    return true;
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>