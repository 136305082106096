<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="状态">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="Id" label="ID" width="80px"/>
        <el-table-column prop="PhoneNum" label="手机号" width="120px"/>
        <el-table-column prop="Account" label="账号" width="120px"/>
        <el-table-column prop="statusTxt" label="状态" width="60px">
          <template #default="scope">
            <span v-if="scope.row.status=='normal'" class="f_color_green">{{scope.row.statusTxt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.statusTxt}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="devices" label="设备数" width="80px">
          <template #default="scope">
            <el-button size="small" type="warning" :loading="!!scope.row.tableDevicesDetailBtnLoading" @click="tableDevicesDetail(scope.row)" v-if="scope.row.devices!=0" class="f_color_white">{{scope.row.devices}}</el-button>
            <span v-else>{{scope.row.devices}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="medias" label="媒体数" width="80px"/>
        <el-table-column prop="maxCloudSize" label="空间用量(MB)" width="120px"/>
        <el-table-column prop="latestLogin" label="登录日期" width="100px"/>
        <el-table-column prop="payTotal" label="付款总额" width="100px"/>
        <el-table-column prop="payLevel" label="欠费级别" width="90px"/>
        <el-table-column prop="createdDate" label="注册日期"/>
        <el-table-column fixed="right" label="操作" width="170">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" :loading="!!scope.row.tableEditBtnLoading" @click="tableEdit(scope.row)" plain>编辑</el-button>
            <el-popover trigger="hover" hide-after="80">
              <template #reference>
                <el-button size="small" type="primary" :loading="!!scope.row.tableParseBtnLoading" plain>更多..</el-button>
              </template>
              <template #default>
                <div style="text-align: center">
                  <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableClearCacheBtnLoading" @click="handleActionSave(scope.row, 1)" plain>刷新缓存</el-button>
                  <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableSumCloudSpaceBtnLoading" @click="handleActionSave(scope.row, 2)" plain>计算云空间</el-button>
                  <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableResetPasswordBtnLoading" @click="handleActionSave(scope.row, 3)" plain>重置密码</el-button>
                  <el-button size="small" type="primary" v-if="scope.row.status=='normal'" :loading="!!scope.row.tableClearUserDefaultDeviceBtnLoading" @click="handleActionSave(scope.row, 4)" plain>清空缺省设备</el-button>
                </div>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle" width="700px">
      <el-tabs v-model="detailEditTabs" @tab-change="handleEditTabChange">
        <el-tab-pane label="用户配置" name="tabs1">
          <el-form label-width="310px">
            <el-form-item label="微信绑定Fan的ID">
              <el-input v-model="dialogEditData.wxBoundId" autocomplete="off" />
            </el-form-item>
            <el-form-item label="微信号直接登录">
              <el-input v-model="dialogEditData.wxSignin" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="用户的单个文件上传最大限制(MB)">
              <el-input v-model="dialogEditData.maxUploadSize" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户自定义playlist的最大个数">
              <el-input v-model="dialogEditData.maxPlaylistCount" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户的默认bucket">
              <el-input v-model="dialogEditData.bucketId" autocomplete="off" />
            </el-form-item>
            <el-form-item label="限制自己的openid才能登录">
              <el-input v-model="dialogEditData.onlyMyOpenid" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="用户最大的云存储空间MB">
              <el-input v-model="dialogEditData.maxCloudSize" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户最大TTS条数限制">
              <el-input v-model="dialogEditData.maxTtsCount" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户最大的subtitle条数限制">
              <el-input v-model="dialogEditData.maxSubtitleCount" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户名下最大的device数">
              <el-input v-model="dialogEditData.maxDeviceCount" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户最大的TV播放分钟数">
              <el-input v-model="dialogEditData.maxTvDuration" autocomplete="off" />
            </el-form-item>
            <el-form-item label="用户欠费等级(-1:不允许欠费,0:普通,1:允许欠费)">
              <el-input v-model="dialogEditData.payLevel" type="number" min="-1" max="1" step="1" />
            </el-form-item>
            <el-form-item label="设备分组使能">
              <el-input v-model="dialogEditData.groupEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="媒体发布受信任登记">
              <el-input v-model="dialogEditData.creditLevel" type="number" min="0" step="1" />
            </el-form-item>
            <el-form-item label="媒体生命周期使能">
              <el-input v-model="dialogEditData.lifeTimeEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="媒体定时播放使能">
              <el-input v-model="dialogEditData.schedulePlayEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="多屏同步使能">
              <el-input v-model="dialogEditData.syncPlayEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="伪竖屏使能">
              <el-input v-model="dialogEditData.fakePortraitEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-form-item label="设备copy使能">
              <el-input v-model="dialogEditData.deviceCopyEn" type="number" min="0" max="1" step="1" />
            </el-form-item>
            <el-row class="mb-4 dialogEditBtns">
              <el-button size="small" type="primary" :loading="dialogEditSubmitBtnLoading" @click="handleEditConfigSave">提交</el-button>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="Redis数据" name="tabs2">
          <el-form>
            <el-form-item>
              <el-select v-model="dialogEditRedisDataType" class="m-2" placeholder="Select" size="small">
                <el-option
                  v-for="item in dialogEditRedisDataOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
              <el-button size="small" type="primary" :loading="dialogEditGetRedisDataBtnLoading" @click="handleActionGetRedisData">获取</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-input rows="15" v-model="dialogEditRedisDataRes" type="textarea" />
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDetailVisible" :title="dialogDetailTitle">
      <el-tabs v-model="detailTabs" @tab-click="handleClick">
        <el-tab-pane label="实时数据" name="tabs1">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="DeviceID">{{dialogDetailData.runtimeStatus.id}}</el-descriptions-item>
            <el-descriptions-item label="WxOpenID">{{dialogDetailData.runtimeStatus.wx_open_id}}</el-descriptions-item>
            <el-descriptions-item label="APP活跃时间戳">{{dialogDetailData.runtimeStatus.activeTime}}</el-descriptions-item>
            <el-descriptions-item label="APP登录时间戳">{{dialogDetailData.runtimeStatus.loginTime}}</el-descriptions-item>
            <el-descriptions-item label="PC活跃时间戳">{{dialogDetailData.runtimeStatus.pcActiveTime}}</el-descriptions-item>
            <el-descriptions-item label="PC登录时间戳">{{dialogDetailData.runtimeStatus.pcLoginTime}}</el-descriptions-item>
            <el-descriptions-item label="WebappIP">{{dialogDetailData.runtimeStatus.webapp_ip}}</el-descriptions-item>
            <el-descriptions-item label="PcWebIP">{{dialogDetailData.runtimeStatus.pc_web_ip}}</el-descriptions-item>
            <el-descriptions-item label="最后的字幕">{{dialogDetailData.runtimeStatus.last_subtitle}}</el-descriptions-item>
            <el-descriptions-item label="最后的TTS">{{dialogDetailData.runtimeStatus.last_tts}}</el-descriptions-item>
            <el-descriptions-item label="TotalStorageSize(KB)">{{dialogDetailData.runtimeStatus.total_storage_size}}</el-descriptions-item>
            <el-descriptions-item label="_time">{{dialogDetailData.runtimeStatus._time}}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="用户画像" name="tabs2">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="是否手机注册过">{{dialogDetailData.experience.registered}}</el-descriptions-item>
            <el-descriptions-item label="拥有device">{{dialogDetailData.experience.has_device}}</el-descriptions-item>
            <el-descriptions-item label="支付过">{{dialogDetailData.experience.has_paid}}</el-descriptions-item>
            <el-descriptions-item label="有过windows">{{dialogDetailData.experience.has_windows}}</el-descriptions-item>
            <el-descriptions-item label="使用过backend客户端">{{dialogDetailData.experience.has_backend}}</el-descriptions-item>
            <el-descriptions-item label="制作过game">{{dialogDetailData.experience.has_game}}</el-descriptions-item>
            <el-descriptions-item label="有过试用期">{{dialogDetailData.experience.has_trail}}</el-descriptions-item>
            <el-descriptions-item label="有过feedback">{{dialogDetailData.experience.has_feedback}}</el-descriptions-item>
            <el-descriptions-item label="开过发票">{{dialogDetailData.experience.has_invoice}}</el-descriptions-item>
            <el-descriptions-item label="有过子管理员">{{dialogDetailData.experience.has_admin}}</el-descriptions-item>
            <el-descriptions-item label="更新时间">{{dialogDetailData.experience.update_time}}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
        <el-tab-pane label="配置数据" name="tabs3">
          <el-descriptions title="" column=2 colon="true" border>
            <el-descriptions-item label="微信绑定Fan的ID">{{dialogDetailData.conf.wxBoundId}}</el-descriptions-item>
            <el-descriptions-item label="微信号直接登录">{{dialogDetailData.conf.wxSignin}}</el-descriptions-item>
            <el-descriptions-item label="用户的单个文件上传最大限制(MB)">{{dialogDetailData.conf.maxUploadSize}}</el-descriptions-item>
            <el-descriptions-item label="用户自定义playlist的最大个数">{{dialogDetailData.conf.maxPlaylistCount}}</el-descriptions-item>
            <el-descriptions-item label="用户的默认bucket">{{dialogDetailData.conf.bucketId}}</el-descriptions-item>
            <el-descriptions-item label="限制自己的openid才能登录">{{dialogDetailData.conf.onlyMyOpenid}}</el-descriptions-item>
            <el-descriptions-item label="用户最大的云存储空间MB">{{dialogDetailData.conf.maxCloudSize}}</el-descriptions-item>
            <el-descriptions-item label="用户最大TTS条数限制">{{dialogDetailData.conf.maxTtsCount}}</el-descriptions-item>
            <el-descriptions-item label="用户最大的subtitle条数限制">{{dialogDetailData.conf.maxSubtitleCount}}</el-descriptions-item>
            <el-descriptions-item label="用户名下最大的device数">{{dialogDetailData.conf.maxDeviceCount}}</el-descriptions-item>
            <el-descriptions-item label="用户最大的TV播放分钟数">{{dialogDetailData.conf.maxTvDuration}}</el-descriptions-item>
            <el-descriptions-item label="用户欠费等级(-1:不允许欠费,0:普通,1:允许欠费)">{{dialogDetailData.conf.payLevel}}</el-descriptions-item>
            <el-descriptions-item label="设备分组使能">{{dialogDetailData.conf.groupEn}}</el-descriptions-item>
            <el-descriptions-item label="媒体发布受信任登记">{{dialogEditData.creditLevel}}</el-descriptions-item>
            <el-descriptions-item label="媒体生命周期使能">{{dialogEditData.lifeTimeEn}}</el-descriptions-item>
            <el-descriptions-item label="媒体定时播放使能">{{dialogEditData.schedulePlayEn}}</el-descriptions-item>
            <el-descriptions-item label="多屏同步使能">{{dialogEditData.syncPlayEn}}</el-descriptions-item>
            <el-descriptions-item label="伪竖屏使能">{{dialogEditData.fakePortraitEn}}</el-descriptions-item>
            <el-descriptions-item label="设备copy使能">{{dialogEditData.deviceCopyEn}}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDevicesDetailVisible" title="用户名用设备列表" width="700px">
      <el-table ref="multipleTableRef" border stripe :data="tableDevicesData" style="width: 100%" empty-text="暂无数据~" element-loading-text="努力加载中">
        <el-table-column prop="device_id" label="设备ID" width="100px"/>
        <el-table-column prop="name" label="名称" show-overflow-tooltip/>
        <el-table-column prop="version" label="版本" width="90px"/>
        <el-table-column prop="super" label="超管" width="80px"/>
        <el-table-column prop="createdDate" label="创建日期" width="120px"/>
        <el-table-column prop="device_status" label="状态" width="90px"/>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDevicesDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'UserView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'normal',
          label: '正常',
        },
        {
          value: 'forbidden',
          label: '禁止',
        }
      ],
      conditionTypes: [
        {
          value: 'Id',
          label: 'ID',
        },
        {
          value: 'PhoneNum',
          label: '手机号',
        },
        {
          value: 'Account',
          label: '账号',
        }
      ],
      filterData: {status: '', conditionType: 'Id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDevicesDetailVisible: false,
      tableDevicesData: [],
      dialogEditVisible: false,
      dialogEditId: 0,
      dialogEditData: {},
      dialogEditTitle: '编辑ID:0',
      dialogEditSubmitBtnLoading: false,
      dialogDetailVisible: false,
      dialogDetailTitle: '',
      detailEditTabs: 'tabs1',
      dialogEditGetRedisDataBtnLoading: false,
      dialogEditRedisDataRes: '--',
      dialogEditRedisDataType: 'RedisUserRuntimeStatus',
      dialogEditRedisDataOptions: [
        {
          value: 'RedisUserRuntimeStatus',
          label: 'RedisUserRuntimeStatus',
        },
      ],
      dialogDetailData: {
        id: '0'
      },
      detailTabs: 'tabs1',
    }
  },
  methods: {
    list() {
      const url = this.$apis.User.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', conditionType: 'Id', conditionValue: ''}
    },
    tableDetail(row) {
      this.dialogDetailTitle = `详情 ID：${row.Id}`
      const url = this.$apis.User.detail
      const params = {id: row.Id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    tableDevicesDetail(row) {
      const url = this.$apis.User.deviceList
      const params = {id: row.Id}
      row.tableDevicesDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.tableDevicesData = res
        this.dialogDevicesDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDevicesDetailBtnLoading = false
      })
    },
    tableEdit(row) {
      this.dialogEditId = row.Id
      this.dialogEditTitle = `编辑[${row.Id}]`
      const url = this.$apis.User.configDetail
      const params = {id: row.Id}
      row.tableEditBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogEditData = res
        this.dialogEditVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableEditBtnLoading = false
      })
    },
    handleEditConfigSave() {
      const url = this.$apis.User.editConfig
      let params = { id: this.dialogEditId }
      const {dialogEditData} = this
      Object.assign(params, dialogEditData)
      this.dialogEditSubmitBtnLoading = true
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
        this.dialogEditVisible = false
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.dialogEditSubmitBtnLoading = false
      })
    },
    handleActionGetRedisData() {
      this.dialogEditRedisDataRes = '加载中...'
      const url = this.$apis.User.getRedisData
      const data = {id: this.dialogEditId, type: this.dialogEditRedisDataType}
      this.dialogEditGetRedisDataBtnLoading = true
      this.$httpPost(url, data).then((res) => {
        this.$message.success('获取成功~')
        if (res) {
          try {
            this.dialogEditRedisDataRes = JSON.stringify(res, null, 4)
          } catch(e) {
            this.dialogEditRedisDataRes = res 
          }
        } else {
          this.dialogEditRedisDataRes = '无数据'
        }
      }).catch((e) => {
        this.dialogEditRedisDataRes = '获取失败~'
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        this.dialogEditGetRedisDataBtnLoading = false
      })
    },
    handleActionSave(row, type) {
      let url = ''
      const _this = this;
      if (type == 4) { // 清空缺省设备
        url = this.$apis.User.clearUserDefaultDevice
        row.tableClearUserDefaultDeviceBtnLoading = true
        this.queryActionSave(url, row)
      } else if (type == 3) { // 重置密码
        url = this.$apis.User.resetPassword
        this.$confirm('确认重置密码?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.tableResetPasswordBtnLoading = true
          _this.queryActionSave(url, row)
        })
      } else if (type == 2) { // 计算云空间
        url = this.$apis.User.clearCache
        row.tableSumCloudSpaceBtnLoading = true
        this.queryActionSave(url, row)
      } else { // 刷新缓存
        url = this.$apis.User.sumCloudSpace
        row.tableClearCacheBtnLoading = true
        this.queryActionSave(url, row)
      }
    },
    queryActionSave(url, row) {
      const params = { id: row.Id }
      this.$showLoading('请求中...')
      this.$httpPost(url, params).then(() => {
        this.$message.success('操作成功')
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '提交失败~')
      }).finally(() => {
        this.$hideLoading()
        row.tableResetPasswordBtnLoading = false
        row.tableSumCloudSpaceBtnLoading = false
        row.tableClearCacheBtnLoading = false
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
.dialogEditBtns {
  float: right;
}
</style>