<template>
  <div class="login">
    <h1>Signin</h1>
    <el-form class="loginForm" :rules="loginFormRules" label-position="top" ref="formRef" :model="loginForm" label-width="70px" size="default">
      <el-form-item label="手机号：" prop="phone">
        <el-input v-model="loginForm.phone" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input v-model="loginForm.password" type="password" placeholder="请输入密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="验证码：" prop="captche">
        <el-input v-model="loginForm.captche" type="text" placeholder="请输入验证码">
          <template #append>
            <el-image class="captche-img"  @click="refreshCaptche()" :src="imgUrl" fit="fill" />
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <el-button size="default" type="primary" @click="submitForm('formRef')">登录</el-button>
  </div>
</template>

<script>
const md5 = require('md5')
export default {
  name: "Login-View",
  data: function () {
    return {
      imgUrl: '',
      host: this.$host,
      loginForm: {
        phone: '', password: ''
      },
      loginFormRules: {
        phone: [
          {
            required: true,
            message: '手机号不可为空',
            trigger: 'blur',
          }],
        password: [
          {
            required: true,
            message: '密码不可为空',
            trigger: 'blur',
          }],
        captche: [
          {
            required: true,
            message: '验证码不可为空',
            trigger: 'blur',
          }]
      }
    }
  },
  methods: {
    submitForm(ref) {
      this.$refs[ref].validate(valid => {
        if (valid) {
          let loginUrl = this.$apis.Auth.Login;
          let infoUrl = this.$apis.Auth.Info;

          this.$showLoading();
          this.$httpPost(loginUrl, {
                "phone": this.loginForm.phone,
                "password": md5(this.loginForm.password),
                "captche": this.loginForm.captche,
                "captcheKey": this.captcheKey,
              }
          ).then(resp => {
            this.$setItem('token', resp.token)

            this.$httpGet(infoUrl).then(resp => {
              this.$hideLoading()
              this.$setJson('userInfo', resp)
              this.$router.push({name: 'home'})
            }).catch((e) => {
              this.$hideLoading()
              console.log(e)
              this.$message.error(e.msg)
            })
          }).catch((e) => {
            this.$hideLoading()
            console.log(e);
            this.$message.error(e.msg)
          })
        }
      })
    },
    refreshCaptche() {
      this.captcheKey = this.$uuid(19) + Date.now();
      this.imgUrl = '/api/auth/captcha?key=' + this.captcheKey;
    },
  },
  created() {
    this.refreshCaptche();
  }
}
</script>

<style scoped>
.login {
  padding: 30px 30px;
  padding-bottom: 50px;
  box-sizing: border-box;

  width: 400px;
  background: #ffffff;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
}
.login h1 {
  margin: 40px auto;
}
.loginForm {
  margin-bottom: 15px;
}
.captche-img {
  width: 80px;
  height: 26px;
}
</style>