export default {
    Auth: {
        Login: '/auth/getToken',
        Info: '/auth/getUser',
    },
    Invoice: {
        list: '/invoice/getList',
        getXmls: '/invoice/getXmls',
        uploadXmls: '/invoice/uploadXmls',
        cancel: '/invoice/cancel',
        detail: '/invoice/getDetail',
    },
    Payment: {
        list: '/payment/getList',
        detail: '/payment/getDetail',
        edit: '/payment/edit',
        add: '/payment/add',
    },
    Wxpayment: {
        list: '/wxpayment/getList',
        detail: '/wxpayment/getDetail',
        recheck: '/wxpayment/recheck',
    },
    Devicetoken: {
        list: '/devicetoken/getList',
        detail: '/devicetoken/getDetail',
        edit: '/devicetoken/edit',
        add: '/devicetoken/add',
    },
    Media: {
        list: '/media/getList',
        detail: '/media/getDetail',
        edit: '/media/edit',
        startTranscode: '/media/startTranscode',
        preview: '/media/preview',
        queryStatus: '/media/queryStatus',
        fileParse: '/media/fileParse',
        mediaParse: '/media/mediaParse',
    },
    Platformmedia: {
        list: '/platformmedia/getList',
        detail: '/platformmedia/getDetail',
        add: '/platformmedia/add',
        editFile: '/platformmedia/editFile',
        edit: '/platformmedia/edit',
        setStatus: '/platformmedia/setStatus',
        preview: '/platformmedia/preview',
    },
    Devicepaid: {
        list: '/devicepaid/getList',
        detail: '/devicepaid/getDetail',
        edit: '/devicepaid/edit',
    },
    File: {
        list: '/file/getList',
        detail: '/file/getDetail',
        queryStatus: '/file/queryStatus',
    },
    Transcode: {
        list: '/transcode/getList',
        detail: '/transcode/getDetail',
        queryStatus: '/transcode/queryStatus',
    },
    Devmedia: {
        list: '/devmedia/getList',
        detail: '/devmedia/getDetail',
        reload: '/devmedia/reload',
        edit: '/devmedia/edit',
        remove: '/devmedia/uncache',
    },
    Logs: {
        list: '/logs/getList',
    },
    User: {
        list: '/user/getList',
        detail: '/user/getDetail',
        configDetail: '/user/getConfig',
        editConfig: '/user/editConfig',
        deviceList: '/user/getDeviceList',
        resetPassword: '/user/resetPassword',
        clearCache: '/user/clearCache',
        sumCloudSpace: '/user/sumCloudSpace',
        getRedisData: '/user/getRedisData',
        clearUserDefaultDevice: '/user/clearUserDefaultDevice',
    },
    Manager: {
        list: '/manager/getList',
        add: '/manager/add',
        edit: '/manager/edit',
        setStatus: '/manager/setStatus',
        resetPassword: '/manager/resetPassword',
        changePassword: '/manager/changePassword',
    },
    Task: {
        list: '/task/getList',
        add: '/task/add',
        edit: '/task/edit',
        setSwitch: '/task/setSwitch',
        runTask: '/task/runTask',
    },
    Device: {
        list: '/device/getList',
        getDeviceInfo: '/device/getInfo',
        getConfig: '/device/getConfig',
        getmediacache: '/device/getmediacache',
        recachemedia: '/device/recachemedia',
        uncachemedia: '/device/uncachemedia',
        getAdmins: '/device/getAdmins',
        addAdmin: '/device/addAdmin',
        setSuper: '/device/setSuper',
        removeAdmin: '/device/removeAdmin',
        getDeviceStatus: '/device/getDeviceStatus',
        jobStatus: '/device/jobStatus',
        setConfig: '/device/setConfig',
        setTouchConfig: '/device/setTouchConfig',
        setWindowsConfig: '/device/setWindowsConfig',
        setSubtitleConfig: '/device/setSubtitleConfig',
        deviceCommand: '/device/deviceCommand',
        extendServiceDate: '/device/extendServiceDate',
        transferService: '/device/transferService',
        getDebugInfo: '/device/getDebugInfo',
        getRuntimeConfig: '/device/getRuntimeConfig',
        getRedisData: '/device/getRedisData',
        getPlayListMedia: '/device/getPlayListMedia',
        storageList: '/device/storageList',
    },
    Userdevice: {
        list: '/userdevice/getList',
    },
    Deviceplaylistmedia: {
        list: '/deviceplaylistmedia/getList',
    },
    Deviceplaylistaudio: {
        list: '/deviceplaylistaudio/getList',
    },
    Deviceplaylistsubtitle: {
        list: '/deviceplaylistsubtitle/getList',
    },
    Userwindow: {
        list: '/userwindow/getList',
        detail: '/userwindow/getDetail',
        getDevices: '/userwindow/getDevices',
        edit: '/userwindow/edit',
    },
    Platformwindow: {
        list: '/platformwindow/getList',
        detail: '/platformwindow/getDetail',
    },
    Feedback: {
        list: '/feedback/getList',
        edit: '/feedback/edit',
    },
    Wechat: {
        list: '/wechat/getList',
    },
    Userlog: {
        list: '/userlog/getList',
    },
}