<template>
  <div>
    <div class="tools">
      <div class="tools-filters">
        <el-form :inline="true" :model="filterData" size="small">
          <el-form-item label="结果">
            <el-select v-model="filterData.status" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="filterData.type" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in typeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选类型">
            <el-select v-model="filterData.conditionType" class="m-2" placeholder="Select" size="small">
              <el-option
                v-for="item in conditionTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="筛选内容">
            <el-input v-model="filterData.conditionValue" placeholder="请输入筛选内容"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="search" plain>搜索</el-button>
            <el-button type="primary" @click="reset" plain>重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="tableData">
      <el-table ref="multipleTableRef" border stripe :data="tableData" @selection-change="handleSelectionChange" style="width: 100%" empty-text="暂无数据~" v-loading="tableLoading" element-loading-text="努力加载中">
        <el-table-column prop="id" label="ID" />
        <el-table-column prop="device_id" label="设备ID" />
        <el-table-column prop="service_token_id" label="点数表ID" />
        <el-table-column prop="type" label="类型" />
        <el-table-column prop="product_txt" label="产品名"/>
        <el-table-column prop="user_id" label="用户ID" /> 
        <el-table-column prop="payment_id" label="支付ID" />
        <el-table-column prop="wx_payment_id" label="微信支付ID" />
        <el-table-column prop="service_start" label="开始日"/>
        <el-table-column prop="service_end" label="结束日"/>
        <el-table-column prop="created_time" label="创建时间" width="160px" />
        <el-table-column prop="status_txt" label="结果">
          <template #default="scope">
            <span v-if="scope.row.result=='none'">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.result=='done'" class="f_color_green">{{scope.row.status_txt}}</span>
            <span v-else-if="scope.row.result=='failed'" class="f_color_darkgray">{{scope.row.status_txt}}</span>
            <span v-else class="f_color_darkgray">{{scope.row.status_txt}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template #default="scope">
            <el-button size="small" type="info" :loading="!!scope.row.tableDetailBtnLoading" @click="tableDetail(scope.row)" plain>详情</el-button>
            <el-button size="small" type="warning" @click="tableEdit(scope.row)" plain>编辑</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        v-if="total>0"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="filterData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="filterData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog v-model="dialogDetailVisible" title="详情">
      <el-descriptions title="" column=2 colon="true" border>
        <el-descriptions-item label="点数表ID">{{dialogDetailData.service_token_id}}</el-descriptions-item>
        <el-descriptions-item label="产品名">{{dialogDetailData.product_txt}}</el-descriptions-item>
        <el-descriptions-item label="ID">{{dialogDetailData.id}}</el-descriptions-item>
        <el-descriptions-item label="产品编号">{{dialogDetailData.product}}</el-descriptions-item>
        <el-descriptions-item label="设备ID">{{dialogDetailData.device_id}}</el-descriptions-item>
        <el-descriptions-item label="类型">{{dialogDetailData.type}}</el-descriptions-item>
        <el-descriptions-item label="用户ID">{{dialogDetailData.user_id}}</el-descriptions-item>
        <el-descriptions-item label="支付ID">{{dialogDetailData.payment_id}}</el-descriptions-item>
        <el-descriptions-item label="微信支付ID">{{dialogDetailData.wx_payment_id}}</el-descriptions-item>
        <el-descriptions-item label="开始日">{{dialogDetailData.service_start}}</el-descriptions-item>
        <el-descriptions-item label="结束日">{{dialogDetailData.service_end}}</el-descriptions-item>
        <el-descriptions-item label="结果">{{dialogDetailData.status_txt}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{dialogDetailData.created_time}}</el-descriptions-item>
        <el-descriptions-item label="金额">{{dialogDetailData.amount}}</el-descriptions-item>
        <el-descriptions-item label="产品信息">{{dialogDetailData.product_info}}</el-descriptions-item>
        <el-descriptions-item label="操作时间">{{dialogDetailData.done_time}}</el-descriptions-item>
        <el-descriptions-item label="年限">{{dialogDetailData.years}}</el-descriptions-item>
        <el-descriptions-item label="备注">{{dialogDetailData.notes}}</el-descriptions-item>
      </el-descriptions>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogEditVisible" :title="dialogEditTitle">
      <el-form :model="dialogEditData" ref="ruleFormEdit" label-position="right" label-width="80px">
        
        <el-form-item label="备注">
          <el-input v-model="dialogEditData.notes" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogEditVisible = false">关闭</el-button>
          <el-button type="primary" :loading="dialogEditSubmitBtnLoading" @click="actionEditSave('ruleFormEdit')">提交</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
export default {
  name: 'DevicepaidView',
  data: function () {
    return {
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 'none',
          label: '创建',
        },
        {
          value: 'done',
          label: '完成',
        },
        {
          value: 'failed',
          label: '失败',
        },
        {
          value: 'rollback',
          label: '回滚',
        }
      ],
      typeOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '1',
          label: '微信支付',
        },
        {
          value: '2',
          label: '续期点数',
        },
        {
          value: '10000',
          label: '手工延期(官方)',
        },
        {
          value: '10030',
          label: '服务期迁移(用户)',
        },
        {
          value: '10020',
          label: '服务期迁移(官方)',
        }
      ],
      conditionTypes: [
        {
          value: 'id',
          label: 'ID',
        },
        {
          value: 'user_id',
          label: '用户ID',
        },
        {
          value: 'payment_id',
          label: '支付ID',
        },
        {
          value: 'wx_payment_id',
          label: '微信支付ID',
        },
        {
          value: 'service_token_id',
          label: '点数表ID',
        },
        {
          value: 'product',
          label: '产品编号',
        },
        {
          value: 'device_id',
          label: '设备ID',
        },
        {
          value: 'phone',
          label: '手机号',
        }
      ],
      filterData: {status: '', type: '', conditionType: 'user_id', conditionValue: ''},
      tableData: [],
      tableLoading: false,
      tableDataFile: [],
      total: 0,
      dialogDetailVisible: false,
      dialogDetailData: {
        id: '0'
      },
      dialogEditVisible: false,
      dialogEditTitle: '',
      dialogEditSubmitBtnLoading: false,
      dialogEditData: {}
    }
  },
  methods: {
    list() {
      const url = this.$apis.Devicepaid.list
      this.tableLoading = true
      this.$httpPost(url, this.filterData).then(resp => {
        const {rows, count} = resp
        this.tableData = rows
        this.total = count
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取列表失败~')
        this.tableData = []
      }).finally(() => {
        this.tableLoading = false
      })
    },
    search() {
      this.filterData.page = 1
      this.list()
    },
    reset() {
      this.filterData = {status: '', type: '', conditionType: 'user_id', conditionValue: ''}
    },
    tableEdit(row) {
      this.dialogEditTitle = `编辑ID:${row.id}`
      this.dialogEditVisible = true
      this.dialogEditData = row;
    },
    tableDetail(row) {
      const url = this.$apis.Devicepaid.detail
      const params = {id: row.id}
      row.tableDetailBtnLoading = true
      this.$httpPost(url, params).then((res) => {
        this.dialogDetailData = res
        this.dialogDetailVisible = true
      }).catch((e) => {
        this.$message.error(e.msg ? e.msg : '获取失败~')
      }).finally(() => {
        row.tableDetailBtnLoading = false
      })
    },
    actionEditSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          const url = this.$apis.Devicepaid.edit
          const {dialogEditData} = this
          this.dialogEditSubmitBtnLoading = true
          this.$httpPost(url, dialogEditData).then(() => {
            this.$message.success('操作成功')
            this.dialogEditVisible = false
          }).catch((e) => {
            this.$message.error(e.msg ? e.msg : '提交失败~')
          }).finally(() => {
            this.dialogEditSubmitBtnLoading = false
          })
        }
      })
    },
    handlePageChange(val) {
      this.filterData.page = val
      this.list()
    },
    handleSizeChange(val) {
      this.filterData.page = 1
      this.filterData.pageSize = val
      this.list()
    }
  },
  created() {
    this.list()
  }
}
</script>

<style scoped>
.x {
  text-align: center
}
</style>